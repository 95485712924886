<template>
  <div class="amp-select">
    <!-- Optional Label Above the Select Element -->
    <label
      v-if="label !== ''"
      class="label"
    >
      {{ $t(label) }}
    </label>

    <div
      :style="cssProps"
      class="select"
      tabindex="0"
      @focusout="closeSelect"
    >
      <!-- Displays the selected item or default label, clicking anywhere will show dropdown -->
      <button
        class="selectToggle"
        :class="{
          active: selectActive,
          'disable-border': disableBorder,
          'disable-background': disableBackground
        }"
        @click="selectActive = !selectActive"
      >
        <span
          class="selectToggle__text"
        >
          {{ $t(activeDisplayLabel) }}
        </span>
        <svg
          class="arrow"
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        ><path
          d="M0 0h24v24H0z"
          fill="none"
        /><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" /></svg>
      </button>
      <!-- Dropdown menu to display options -->
      <div
        class="select__menu"
        :class="{active: selectActive}"
      >
        <!-- Optional Search bar -->
        <div
          v-if="searchEnabled"
          class="select__menu__input"
        >
          <div class="input-container">
            <input
              v-model="searchValue"
              type="text"
              :placeholder="$t('Search')"
              class="input"
            >
            <a
              href="#"
              class="input__clear"
              :class="{active: searchValue !== ''}"
              @click="clearSearch"
            />
          </div>
          <hr>
        </div>
        <!-- List of options -->
        <div
          class="select__menu__content"
        >
          <ul>
            <!-- Display a no matches found message when there are no options -->
            <li
              v-if="sortedOptions.length === 0"
              class="no-results"
            >
              {{ $t("No results found") }}
            </li>
            <!-- Display default label if available and there are results -->
            <li
              v-if="!hideDefault && sortedOptions.length !== 0"
            >
              <a
                href="#"
                :class="{selected: model === '' && enableDefault, disabled:!enableDefault}"
                @click.prevent="changeSelectValue('', defaultLabel)"
              >
                {{ $t(defaultLabel) }}
              </a>
            </li>
            <!-- Main list of options: -->
            <li
              v-for="(o,i) of sortedOptions"
              :key="`${i}${o.key}`"
            >
              <a
                href="#"
                :class="{selected: o.value === model}"
                @click.prevent="changeSelectValue(`${o.value}`, `${o.label}`)"
              >
                {{ $t(o.label) }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/** A drop in replacement for the HTML element 'select'. This element is built from scratch html to
 * get around limitations in the native html select.
 *
 * Features of this component:
 * - Unified appearance for all select components in line with existing amp styling.
 * - Automatically sorts values by label
 * - Accepts simple string lists, or object lists
 * - Most props are optional. Minimally only 'options' is needed as an array of strings.
 *
 * UI & Sizing Behavior has been modeled after the native html select component:
 * - The dropdown component will be the larger of:
 * 		- The width of the largest text in the dropdown to avoid text wrapping/overflow
 * 		- The width of the main select label/button that triggers the dropdown
 * - The main label/button component will expand to fit the size given by the parent component. It is
 *   recommended to provide a max-width styling limit, because the button will naturally expand to fit
 *   whatever text is being displayed. If a size is not defined by the parent, it will grow/shrink to fit
 *   the text being displayed
 *     - If the text in the button is overflowing, an ellipses will be used
 * - Additionally, the prop staticWidth allows fixing the width of both the button and dropdown component
 *   to a fixed size. This can help provide the user a constant sized component regardless of content
 *     - Note that overflow text in the dropdown component will wrap, not ellipses
 * - Options in the dropdown are background shaded every-other and highlighted as they are moused over
 *
 * If search functionality is enabled, a search bar will appear in the dropdown above all of the scrollable
 * options. Typing in this search bar will automatically filter the dropdown options based on label text only
 *
 * If a default option is included, it will appear at the top of the dropdown list.
 *
 * If the dropdown component has no options to display, it will give a "No Results Found" message where
 * the options would normally be
 *
 * If the value input by the parent does not match an option in the dropdown, the default label will be displayed
 */
export default {
	props: {
		/** Label to display for the default 'select' row, this will be used when no value is select
		 * The default value attached to this label (if selectable), is an empty string
		*/
		defaultLabel: { type: String, default: '' },

		/** Allows the default select option to be user selectable, will emit an empty string when selected */
		enableDefault: { type: Boolean, default: false },

		/** Hide the default select option */
		hideDefault: {
			type: Boolean,
			default: false
		},

		/** Enable a search bar that will sort through options as a user enters input, search
		 * bar is hidden if prop is set to false
		 */
		enableSearch: {
			type: Boolean,
			default: false
		},

		/** Maximum amount of options to display without automatically turning on the search function */
		minimumSearchAmount: {
			type: Number,
			default: 9
		},

		/** Data used to generate select option rows, supports simple lists or list of objects
		 * When String[]: The value, key and label part of option are all assumed to be the simple string
		 * When Object[]: You must use props 'optionKey', 'optionValue' and 'optionLabel' to designate what parts of the object should be used
		 * @type {import('vue').PropOptions<{key:string, label: string}[]| Set<string>>} */
		options: {
			type: [Array, Object, Set],
			default: () => []
		},

		/** Label to be displayed above the select element */
		label: {
			type: String,
			default: ''
		},

		/** Can be treated like a 'value' of select. Gets directly bound to the selects 'value' attribute */
		value: {
			type: [String, Number, Boolean],
			default: ''
		},

		/** Controls label generation for each select option.
		 * Expects 3 different types
		 * - Empty String. (default) means the passed in options is a simple array and the Label is the same as the value
		 * - String. Expected to be the key to be used to fetch the label from the passed in array of objects
		 * - Function. Expected to return a string, and the object from the array of passed in objects is the parameter.
		 */
		optionLabel: {
			type: [String, Function],
			default: ''
		},

		/** Preset label settings are provided for some types
		 * Overrides `optionLabel`
		 * Supports: `device` and `machine` as viable options
		 */
		optionLabelPreset: {
			type: String,
			default: ''
		},

		/** Controls value used as key.
		 * Leave blank when using a string array for 'options'
		 * Otherwise if 'options' is array of objects this should be the key to dictate the rows key
		 */
		optionKey: {
			type: String,
			default: ''
		},

		/** Controls value used as value.
		 * Leave blank when using a string array for 'options'
		 * Otherwise if 'options' is array of objects this should be the key to dictate the rows value
		 */
		optionValue: {
			type: String,
			default: ''
		},

		/** Disables all border based CSS */
		disableBorder: {
			type: Boolean,
			default: false
		},

		/** Disabled background color */
		disableBackground: {
			type: Boolean,
			default: false
		},

		/** Hard sets the width of the select element, will not change in size in regards to the content inside
		 * Default is 0 which sets width to 100% which will fill whatever space the parent gives it, but will grow to fit
		 * content if the parent allows for it (for example, using a flex-basis)
		 */
		staticWidth: {
			type: Number,
			default: 0
		}
	},

	data() {
		return {
			/** Flag to toggle display of dropdown content
			 * @type {Boolean}
			 */
			selectActive: false,
			/** Search text as input by user, empty string if nothing is being searched
			 * @type {String}
			 */
			searchValue: '',
			/** Keep track of the number of characters of the longest label to be displayed
			 * in the content dropdown. This is used to calculate the content dropdowns minimum width
			 * @type {Number}
			 */
			longestLabel: 0
		}
	},

	computed: {

		/** Indicates if search functionality should be used
		 * @returns {boolean}
		*/
		searchEnabled() {
			return this.enableSearch || Object.keys(this.options).length >= this.minimumSearchAmount
		},

		/** Sorted array of 'select' 'option' rows ready for displaying.
		 * Output is controlled by 'options', 'optionKey', 'optionValue' and 'optionLabel'. See prop section for use details
		 * @returns {{key:string,label:string,value:string|number}[]} */
		sortedOptions() {
			let list = []
			// Simple array of strings
			if (this.optionKey === '' && this.optionValue === '') {
				for (const o of this.options) {
					const label = this.makeLabel(o)
					if (this.searchEnabled) {
						if (label.toLowerCase().includes(this.searchValue) || this.searchValue === '' || label.toLowerCase() === this.model.toLowerCase()) {
							list.push({
								key: o,
								label: label,
								value: o
							})
						}
					} else {
						list.push({
							key: o,
							label: label,
							value: o
						})
					}
				}
			} else {
				if (this.optionValue === '') console.warn('Option-Value prop not set, drop down may not work')
				if (this.optionKey === '') console.warn('Option-Key prop not set, drop down may not work')
				// Array of objects
				for (const o of Object.values(this.options)) {
					const label = this.makeLabel(o)
					if (this.searchEnabled) {
						if (label.toLowerCase().includes(this.searchValue) || this.searchValue === '' || label.toLowerCase() === this.model.toLowerCase()) {
							list.push({
								key: o[this.optionKey],
								label: label,
								value: o[this.optionValue]
							})
						}
					} else {
						list.push({
							key: o[this.optionKey],
							label: label,
							value: o[this.optionValue]
						})
					}
				}
			}
			const c = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare
			list = list.sort((a, b) => {
				return c(a.label, b.label)
			})
			return list
		},

		/** The label to be displayed on the main select display (when content dropdown is collapsed)
		 * Uses the selected elements label if exists, otherwise uses the default label prop
		 * @returns {String} label to be displayed
		 */
		activeDisplayLabel() {
			const element = this.sortedOptions.find(x => x.value === this.model)
			if (element !== undefined) {
				return element.label
			} else {
				return this.defaultLabel
			}
		},

		/** Active value selected */
		model: {
			/** @returns {String|number|boolean} */
			get() { return this.value },
			/** @param {string|number|boolean} value */
			set(value) { this.$emit('input', value) }
		},

		/** CSS properties generated by the data fed to the component
		 * @returns {Object<String,String>}
		 */
		cssProps() {
			return {
				// The minimum width of the dropdown is either calculated off of the longest label or set to 0
				// to allow for a static width to be applied without conflict
				'--dropdown-min-width': this.staticWidth === 0 ? (this.longestLabel * 7 + 50) + 'px' : '0px',
				// The static width of the button and dropdown if set by the prop, otherwise default to 100%
				'--width': this.staticWidth === 0 ? '100%' : this.staticWidth + 'px'
			}
		}

	},

	watch: {
		/** Watch for sorted options to change, see if there is a new largest label and adjust width
		 * of dropdown component if needed. Will not shrink if object are removed, accounts for only the
		 * global (historical) max
		 */
		sortedOptions: {
			handler() {
				for (const item of this.sortedOptions) {
					if (item.label.length > this.longestLabel) {
						this.longestLabel = item.label.length
					}
				}
			},

			immediate: true
		}
	},

	methods: {
		/** Creates a label based on the option data and user defined label
		 * @param {string|Object} o
		 * @returns {string}
		 */
		makeLabel(o) {
			if (this.optionLabelPreset === 'machine' || this.optionLabelPreset === 'device') {
				let n = o.name
				if (n === '') n = o.id.slice(0, 4)
				return `${o.model} - ${n}`
			}

			// No label key set use passed in value
			if (this.optionLabel === '') return o
			// Value is set and is an ID to be used
			if (typeof this.optionLabel === 'string') {
				return o[this.optionLabel]
			}
			// Parent passed in a generating function for label names
			return this.optionLabel(o)
		},

		/** Set value of search select and label for button text
		 * @param {String} value
		 */
		changeSelectValue(value) {
			this.model = value
			// remove active state from select
			this.selectActive = false
		},

		/** Closes select if user clicks off of it */
		closeSelect(e) {
			if (!e.currentTarget.contains(e.relatedTarget)) {
				this.selectActive = false
			}
		},

		/** Clear search input */
		clearSearch() {
			this.searchValue = ''
		}
	}
}
</script>
  <style lang="scss" scoped>
.label{
    font-family: Helvetica;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
    color: #141414;
    text-align: left;
}
.arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.select {
	position: relative;
	overflow: visible;
	display: flex;
	width: var(--width);
}
.selectToggle {
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 10px 30px 10px 12px;
	width: 100%;
	background: #f7f7f7;
    border: 1px solid #1B2A38;
    border-radius: 5px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

	&.disable-border{
		border: unset;
		border-radius:unset
	}

	&.disable-background{
		background: unset;
	}

	&__text {
		font-family: Helvetica;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 18px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

}
.select__menu {
	visibility: hidden;
	position: absolute;
	top: 100%; right: 0; left: 0;
	max-height: 300px;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 0px 2px rgba(162, 162, 162, 0.6), 0px 5px 15px rgba(0, 0, 0, 0.6);
	overflow: hidden;
	z-index: 100;
	min-width: var(--dropdown-min-width);
	width: 100%;
	max-width: var(--width);
	opacity: 0;
	transition: opacity .2s linear;
	pointer-events: none;

	&.active {
		visibility: visible;
		display: flex;
		flex-direction: column;
		opacity: 1;
		pointer-events: all;
	}

	&__input {
		padding: 8px;

		.input-container {
			position: relative;
			margin-bottom: 8px;
		}

		.input {
			width: 100%;
			background: #f7f7f7;
			border: 1px solid #1B2A38;
			border-radius: 5px;
			height: 40px;
			padding-left: 12px;
			padding-right: 24px;
			font-family: Helvetica;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 18px;
			color: #1B2A38;
		}

		.input__clear {
			pointer-events: none;
			position: absolute;
			top: 50%; right: 10px;
			width: 16px;
			height: 16px;
			transform: translateY(-50%);

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%; left: 50%;
				width: 16px;
				height: 2px;
				background-color: transparent;
			}

			&:before {
				transform: translate(-50%, -50%) rotate(45deg);
			}

			&:after {
				transform: translate(-50%, -50%) rotate(-45deg);
			}

			&.active {
				visibility: visible;
				pointer-events: all;

				&:before,
				&:after {
					background-color: #1B2A38;
				}
			}
		}

		hr {
			height: 1px;
			background-color: #4a5568;
		}
	}

	&__content {
		position: relative;
		overflow-y: auto;
		flex-grow: 1;
		width: 100%;

		ul {
			padding: 0;
		}

		li {
			&:nth-child(even) {
				background-color: #F4F5F7;
			}

			&.no-results {
				padding-left: 8px;
				padding-bottom: 4px;
				color: rgb(27,42,56,0.75);
			}

		}

		li:last-child a {
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
		}

		a {
			display: block;
			padding: 3px 20px;
			border: 1px solid transparent;
			border-radius: 2px;

			&:hover,
			&.selected {
				border-color: #1B2A38;
				background-color: #B1C7DC;
			}
			&.disabled {
				pointer-events: none;
			}
		}
	}
}
</style>
