
export default {
	/**
	 * This mixin is inside a plugin. Plugins are tied to the vue instance they are installed in. This plugin is installed on our base vue instance.
	 *
	 * This means that all the code inside this can be accessed by any vue code/component in the site.
	 * Vuex stores are the one exclusion to this
	 * 	To access this from a Vuex store, you need to:
	 * 		`import router from '@/router'`
	 * 		access via `router.app.functionName/computedValue`
	 * 		This takes advantage of the router component storing a copy of the application its tied to inside .app
	 * 		One caveat of this is that when testing a store that accesses these .app. values, you will need to add an if to navigate around it as it does not have a copy of the app
	 *
	 * 	caveat ex. `dashboard.store.js` -> `setActiveMachinePosition`
	 * 		if (router.app !== null) {	// Default used by the application while running
	 *			router.app.updateQueryParamsWithMap({ activePosition: position })
	 *		} else {	// Ran when inside a unit test, still achieves the desired outcome, but is done outside the routerHub. I am unsure about side effects of this as it is directly modifying router values
	 *			router.currentRoute.query.activePosition = position
	 *		}
	 *
	 * If another cleaner way is found to inject the Vue app into the router inside tests, please change to that as this was a work around that works, but is not 100% what we want with unit testing
	 */
	install(Vue) {
		Vue.mixin({
			/**
			 * @prop {Boolean} currentlyUpdating	- Used to keep track of the update progress and if something is currently being applied to the router query
			 * @prop {Object[]}	queryQueue			- Queue that holds on to all Objects that will be used in a router.push or router.replace function call
			 * 											ex. Object can be found in _navigate() comments
			 */
			data() {
				return {
					currentlyUpdating: false,
					queryQueue: []
				}
			},

			computed: {

				/** Router Quick Access Values
				 * Each of the following computed values are different parts of a url
				 * 	ex. URL for Example
				 * 		'localhost:8080/2d22e90c-c394-4f4a-8028-18d80b56ed73/locations/?expanded=088cd3a7-ac77-4794-ad1e-311bfe312f4e&state=MN'
				 *
				 * All of the following can be grabbed using the computed values below
				 * 	ex. Path:
				 * 		currentRoutePath returns -> String
				 * 			'/2d22e90c-c394-4f4a-8028-18d80b56ed73/locations/'
				 * 	ex. Params:
				 * 		currentRouteParams returns -> Object
				 * 			{
				 * 				customerId:'2d22e90c-c394-4f4a-8028-18d80b56ed73'
				 * 			}
				 * 	ex. Query:
				 * 		currentRouteQuery returns -> Object
				 * 			{
				 * 				expanded: ['088cd3a7-ac77-4794-ad1e-311bfe312f4e'],
				 * 				state: 'MN'
				 * 			}
				 */

				/** Gives an abstracted way to return the current route path. A middle man of sorts to abstract away $route and make the hub more commonly used, rather then a corner case
				 * @returns {String}	- Path ex. '/2d22e90c-c394-4f4a-8028-18d80b56ed73/locations'
				*/
				currentRoutePath: function() {
					return this.$route.path
				},

				/** Gives an abstracted way to return the current route Params. A middle man of sorts to abstract away $route and make the hub more commonly used, rather then a corner case
				 * @returns {Object}
				 */
				currentRouteParams: function() {
					return this.$route.params
				},

				/** Gives an abstracted way to return the current Query params. A middle man of sorts to abstract away $route and make the hub more commonly used, rather then a corner case
				 * @returns {Object}
				 */
				currentRouteQuery: function() {
					return this.$route.query
				}
			},

			created() {
				/**
				 * Gives human readable features to a flag of sorts
				 * Used to represent the two functions used for $router, push and replace
				 */
				this.updateEnum = {
					push: 1,
					replace: 2
				}
			},

			methods: {

				/** Nav Helper
				 *
				 * Example page from router.routes
				 * 	path: '/signup/:jwt',
				 *	name: 'signup',
				 *
				 * 'navToPageName' 		-> pageName = 'signup', params = {jwt=''}
				 * 'navToURL'			-> url = '/signup/abc123'
				 * 'navToURLSetQuery'	-> path = '/signup/abc123', query = {}
				 */

				/** Query Updater Helper
				 *
				 * When you want to manipulate the query that is held inside the url, pass a map object into updateQueryParamsWithMap
				 * 	ex. Map Object
				 * 		{
				 * 			activeDevice: 1,
				 * 			expandedDevices: ["uid1","uid3"],
				 * 			make: null
				 * 		}
				 * The above will set activeDevice to 1 in the query, expandedDevices to the array, and remove make if its found inside the query
				 *
				 * NOTE: When dealing with Numbers, they are converted to string since the value will end up as a string if you refresh the page
				 *
				 * More in updateQueryParamsWithMap comments
				 */

				/**
				 * Used to navigate to a route using the routes name, rather then a url
				 * @param {String} pageName 	- The name of the page as written out in the router.routes
				 * @param {Object} params 		- The params that will be applied to the route as laid out in router.routes
				 * 	ex. for route '/signup/:jwt'
				 * 	The route has 'name: "signup"' inside index.js
				 * 		pageName: 'signup'
				 * 		params: { jwt: ~~~ }
				 *
				 *		The name of the property inside params matters, it needs to match the :jwt
				 * 			~~~ is a stand in for the proper contents of a jwt object
				 *
				 * 	That ends up injecting jwt into the route, resulting in a complete and finished url
				 */
				navToPageName: function(pageName, params = {}) {
					// Built queryObject to allow the queue to work, holds on to the type of function call, as well as what object to insert into the router function
					const queryObject = {
						type: this.updateEnum.push,
						object: { name: pageName, params: params }
					}
					this._navigate(queryObject)
				},

				/**
				 * This allows navigation using a finished string url
				 * @param {String} url - Pass whatever the finished string url would be, ignoring the base
				 *  ex. for /:customerID/devices
				 * 		url: '/110c8462-bc1a-4892-b7f5-0f4ac5e94e28/devices'
				 */
				navToURL: function(url) {
					// Built queryObject to allow the queue to work, holds on to the type of function call, as well as what object to insert into the router function
					const queryObject = {
						type: this.updateEnum.push,
						object: { path: url }
					}
					this._navigate(queryObject)
				},

				/**
				 * This function allows you to navigate to a url with the passed in query applied. Overrides any queries that are currently in place with the passed in value
				 * @param {String} path 	- The url that you want to navigate to, make sure to include any params inside the url already. ex. '/:customerID/locations', pass the customerID already in the string
				 * @param {Object} query 	- The query object to use when navigating. Examples of what query objects look like can be found in updateQueryParamsWithMap below
				 *
				 * 	ex. for /:customerID/devices
				 * 		path: '/110c8462-bc1a-4892-b7f5-0f4ac5e94e28/devices'
				 * 		query: {expanded: ['uid1', 'uid4'], make: 'BVS'}
				 *
				 * 	results in:
				 * 		'/110c8462-bc1a-4892-b7f5-0f4ac5e94e28/devices?expanded=uid1&expanded=uid4&make=BVS
				 */
				navToURLSetQuery: function(path, query = {}) {
					// Built queryObject to allow the queue to work, holds on to the type of function call, as well as what object to insert into the router function
					const queryObject = {
						type: this.updateEnum.push,
						object: { path: path, query: query }
					}
					this._navigate(queryObject)
				},

				/**
				 * This function is used to update the query portion of the router. window.history only tracks
				 * actions done by .push, .replace is better used for things like filter changes
				 *
				 * 	Expected updateMap
				 * 	{
				 * 		key:value,
				 * 		key:value,
				 * 		etc
				 * 	}
				 * 	ex. updateMap from Dashboard.vue for 3 devices that are expanded
				 * 	{
				 * 		expanded: ["uid1","uid2","uid3"]
				 * 	}
				 *
				 *  ex. updateMap from entity_filter_helper.vue, 2 filters applied, and 1 that was removed
				 * 	{
				 * 		device_type: "pqv"
				 *		make: "BVS"
				 *		model: null
				 * 	}
				 *
				 * The update map stores passed in values inside the router.query
				 *
				 * 		To Remove a key, pass in the key with the value set to null:
				 * 			{
				 * 				key: null
				 * 			}
				 *
				 * This process builds a copy of the current query and modifies it using the map, storing it back when its done
				 * @param {Object} updateMap
				 */
				updateQueryParamsWithMap: function(updateMap = {}) {
					// Copy existing to prevent changes during processing
					const stampQuery = this.$route.query
					const newQuery = {}

					// Copy non-null values into newQuery from existing query
					for (const key in stampQuery) {
						// keys not in updateMap will show as undefined and still be added
						if (updateMap[key] !== null) {
							newQuery[key] = stampQuery[key]
						}
					}

					// Add keys from updateMap to the newQuery
					for (const key in updateMap) {
						// Skip adding nulls
						if (updateMap[key] !== null) {
							// Stringify numbers, add all others. On refresh, numbers will be strings. This keeps consistency
							if (typeof updateMap[key] === 'number') {
								newQuery[key] = updateMap[key].toString()
							} else {
								newQuery[key] = updateMap[key]
							}
							// Unhandled: Objects, Booleans, other
							// Arrays typeof is 'object', if you setup objects, exclude .isArray()
						}
					}

					// Create a query object to pass into _navigate. Doing this allows us to combine replace and push calls in the same queue to keep things consistent
					const queryObject = {
						type: this.updateEnum.replace,
						object: { query: newQuery }
					}
					this._navigate(queryObject)
				},

				/**
				 * Starting point to handle any and all router changes. Pass in the queryObject with type set to push/replace
				 * @param {Object} 		queryObject 		- contains information on what we want to apply to the router
				 * @param {updateEnum}	queryObject.type	- values allowed: updateEnum.push, updateEnum.replace
				 * @param {Object}		queryObject.object	- The actual contents that we want to apply to the router object
				 *	 											- Allowed properties for object are:
				 *	 												query, path, name, params
				 * 	 												Read up more on vue-router - push/replace
				 */
				_navigate: function(queryObject) {
					// We maintain a queue of updates to prevent loads from being canceled. If its loading, add queryObject to the queue
					if (this.currentlyUpdating) {
						this.queryQueue.push(queryObject)
					} else {
						this.currentlyUpdating = true
						// The query object controls if we are using .push or .replace
						if (queryObject.type === this.updateEnum.push) {
							// Push shows the changes in window.history
							this.$router.push(queryObject.object)
								.then(() => {
									this._navigationSuccess()
								})
								.catch(() => {
									this._navigationAbort()
								})
						} else if (queryObject.type === this.updateEnum.replace) {
							// Replace doesnt show changes in window.history, it just updates the current value
							this.$router.replace(queryObject.object)
								.then(() => {
									this._navigationSuccess()
								})
								.catch(() => {
									this._navigationAbort()
								})
						}
					}
				},

				/**
				 * Used to flip the flag and continue the queue if it currently has contents
				 * Can be extended if we want to add additional functionality later
				 */
				_navigationSuccess: function() {
					this.currentlyUpdating = false
					if (this.queryQueue.length > 0) {
						this._navigate(this.queryQueue.shift())
					}
				},

				/**
				 * Used to catch any errors that might come up in a failed navigation, as well as flipping the flag and continuing the queue if it has contents
				 * @param {Object} error	- The error generated by the failed navigation
				 */
				_navigationAbort: function(error) {
					/**
					 * This catches any navigation errors and normal errors that can be thrown during the process.
					 * There are 4 kinds of navigation failures that each have their own errors. We do not try to keep track of these at the moment,
					 *  but we could expand into them if we want to set that up.
					 *
					 * We currently ignore the navigation error for navigation duplicated due to it not having any effect on actual functionality. It was just
					 * clogging up the console
					 *
					 * 	Explanation of Navigation Failures:
					 * 		redirected - next(newLocation) called while inside navigation guard
					 * 		aborted - next(false) called while inside navigation guard
					 * 		cancelled - new navigation took place before current could finish, ex router.push called while inside navigation guard
					 * 		duplicated - currently at the location so the navigation was prevented
					 *
					 * 	If we want to setup logging/handling of each of these specifically:
					 * 		import the following const {isNavigationFailure, NavigationFailureType} = VueRouter
					 * 		We could then use isNavigationFailure to find navigation errors
					 */
					if (error !== undefined) {
						// Check if the error is due to routing, there is currently no reason to throw errors for those as we are throttling how many can go through, only one at a time
						const routingError = (error.name === ' NavigationDuplicated' || error.message.includes('redundant navigation'))
						if (!routingError) {
							console.log(error)
						}
					}
					// Set the flag to false as we are no longer navigating and kick off next query from the queue if there is one
					this.currentlyUpdating = false
					if (this.queryQueue.length > 0) {
						this._navigate(this.queryQueue.shift())
					}
				}
			}
		})
	}

}
