import Vue from 'vue'

/** Oee Tile state information
 * @typedef {Object} OeeTileStore
 * @prop {String} 								editingOeeTile 					- OeeTileID indicating what oee tile is being edited. Empty means nothing is being edited
 * @prop {OeeTileConfig} 						oeeTileConfig 				    - Full tile config
 */

/** Instantiates a new object consisting of the oee tile store state
 * @returns {OeeTileStore}
*/
export const getDefaultOeeTileState = () => {
	return {
		editingOeeTile: '',
		oeeTileConfig: {}
	}
}

export const storeState = getDefaultOeeTileState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {
	/** Updates config file for dashboard based on passed in settings
	 * @param {import('vuex').ActionContext<typeof storeState>} _
	 * @param {Object} 		p
	 * @param {String[]} 						p.telemKeys 		- List of telemetry keys to be used by oee tile
	*/
	setOeeTileConfig({ commit, getters }, { telemKeys }) {
		commit('_setOeeTileConfig', { oeeTileID: getters.getEditingOeeTileID, config: telemKeys })
	}
}

/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultOeeTileState())
	},
	/** Sets the ID of the oee tile currently being edited
	 * @param {String} oeeTileID 	- ID of oee tile currently being edited
	 */
	setEditingOeeTile(state, oeeTileID) {
		state.editingOeeTile = oeeTileID
	},

	/** Update the given oee tile's config
	 * @param state
	 * @param {Object} p
	 * @param {String} p.oeeTileID		- Oee Tile being modified
	 * @param {String[]} p.config	- Oee Tile telem keys to replace the current telemetry
	 */
	_setOeeTileConfig(state, { oeeTileID, config }) {
		state.oeeTileConfig[oeeTileID].telemKeys = config
	},

	/** Update oee tile data
	 *
	 * @param state
	 * @param {Object} p
	 * @param {String} p.oeeTileID
	 * @param {String[]} p.oeeTileData
	 */
	editOeeTile(state, { oeeTileID, oeeTileData }) {
		const oeeTelemData = { telemKeys: oeeTileData }
		// create tile if it doesn't exist
		if (!state.oeeTileConfig[oeeTileID]) {
			Vue.set(state.oeeTileConfig, oeeTileID, oeeTelemData)
		} else {
			state.oeeTileConfig[oeeTileID] = oeeTelemData
		}
	}
}
/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {
	/** Returns the oee Tile ID currently being edited
	 * @returns {String} - oee Tile ID being edited or blank string
	 */
	getEditingOeeTileID(state) {
		return state.editingOeeTile
	},
	/** Returns the tile object of the passed in ID */
	getOeeTile: (state) =>
	/**
	 * @param {String} oeeTileID - ID of the tile to return
	 * @returns {oeeTile}
	 */
		(oeeTileID) => {
			return state.oeeTileConfig[oeeTileID]
		}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
