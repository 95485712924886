/** Store for user generated device reports
 *
 * - Fetches report configurations
 * - Fetches data needed for reports
 */

 import axios from 'axios'
 import Vue from 'vue'
 import { bisector } from 'd3-array'
 
 /** Telemetry as pulled down from the API
  * @typedef {Object} RawTelemetry
  * @param {number} 										start_ts 	- Starting MS window for the telemetry
  * @param {Object<string,{sum:number,count:number}>} 	telemetry 	- Telemetry keys and there counts and sums
  */
 /** Context for reports
  * @typedef {Object} reportContext
  * @property {number} start - Starting time of context value
  * @property {number} end   - Ending time of context value
  */
 /** State information for the report store
  * @typedef {Object} ReportStore
  * @property {Object<string,Report>} 										reports 		- Hold report objects, object keys are report ids, values are the report entity objects
  * @property {Set<string>} 													queried 		- Set of customers that reports have been queried for currently in state
  * @property {Object<string,ReportTelemetry[]>} 							telemetry 		- Top level keys are telemetry names
  * @property {Object<string,Object<string,Object<string,reportContext[]>>>} context 		- Top level keys rID, then context names, with grandchild keys being the value
  * @property {Object<string,Promise>} 										loadedTelemetry	- Top level keys are rID each promise indicates if all API requests are finished
  * @property {RawTelemetry} 												rawTelemetry 	- Telemetry directly loaded from the API with no manipulations
  */
 
 /** Helper function that generates all percentage based data for a passed in telemetry message
  * The new data is added to the passed in telemetry object.
  *
  * @param {Object<string,ReportTelemetry>}	telem 		- Telemetry message to modify as needed
  * @param {ReportPercentKeys[]} 			percentData - Information about percent based data
  */
 export function calculatePercent(telem, percentData) {
	 for (const p of percentData) {
		 let valueTotal = 0
		 let sumTotal = 0
		 let invalid = false
		 for (const neededKey of p.keys) {
			 if (!(neededKey in telem)) {
				 invalid = true
				 break
			 }
			 valueTotal += telem[neededKey].value
			 sumTotal += telem[neededKey].sum
		 }
		 // All sum keys are needed in order to generate an accurate percentage
		 if (invalid || sumTotal === 0 || valueTotal === 0) break
		 telem[p.name] = {
			 value: telem[p.value].value / valueTotal,
			 sum: telem[p.value].sum / sumTotal,
			 count: -1,
			 isPercentage: true,
			 start: telem[p.value].start,
			 end: telem[p.value].end
		 }
	 }
 }
 
 /** @returns {ReportStore} */
 export const getDefaultReportState = () => {
	 return {
		 reports: {},
		 queried: new Set(),
		 loadedTelemetry: {},
		 rawTelemetry: {},
		 telemetry: {},
		 context: {}
	 }
 }
 
 export const storeState = getDefaultReportState()
 
 /** @type {import("vuex").ActionTree<typeof storeState>} */
 export const storeActions = {
	 /** Loads data and context for a given device between the two timestamps.
	  * Function will throttle API calls so only one per duplicate request will go through
	  *
	  * Optional arguments 'percentKeys', and 'accumulationKeys' change how the data set will be parsed
	  * Additional calls with the same ID, start, end times but different optional keys, will be combined with pervious keys
	  * When promise is returned data should exist within the report store
	  *
	  * @param {import('vuex').ActionContext<ReportStore>} _
	  * @param {Object} p
	  * @param {string} 				p.deviceID
	  * @param {number} 				p.start
	  * @param {number} 				p.end
	  * @param {ReportPercentKeys[]} p.percentKeys
	  * @param {string[]} 			p.accumulationKeys
	  *
	  * @returns {Promise}
	  */
	 async fetchReportData({ dispatch, commit, state, getters }, { deviceID, start, end, percentKeys = [], accumulationKeys = [] }) {
		 const rID = getters.getRequestID({ id: deviceID, start: start, end: end })
 
		 // If data is being fetched from the API modification keys will be set at that time
		 // This bool will stop us from rewriting it later
		 let unused = true
 
		 // If resource has not been loaded hit the API
		 if (!(rID in state.loadedTelemetry)) {
			 const loading = []
			 // Get raw telemetry for the request
			 loading.push(
				 dispatch('Telemetry/generateHistoricalTelemetry', { startTime: start + 1, endTime: end - 1, deviceID: deviceID }, { root: true }).then(async response => {
					 unused = false
					 // Telemetry can
					 commit('setRawTelemetry', { rID, data: response.newDataSet, percentKeys, accumulationKeys })
					 commit('setTelemetry', { rID, data: response.newDataSet })
				 }).catch(err => {
					 console.warn('Problems fetching telemetry:', err)
				 })
			 )
 
			 // Also get context information for the same timespan
			 loading.push(
				 dispatch('Context/generateHistoricalContext', { deviceID, start: start + 1, end: end - 1 }, { root: true }).then(response => {
					 commit('setContext', { rID: rID, rawContext: response })
				 }).catch(err => {
					 console.warn('Problem fetching context:', err)
				 }))
 
			 // Promise will indicate when all resource data is done loading
			 commit('setTelemetryLoading', {
				 rID: rID,
				 promise: Promise.all(loading)
			 })
		 }
		 // If needed wait for the resource to finish loading from API
		 await state.loadedTelemetry[rID]
		 // Only write modifiers for data that has not already been fetched
		 if (unused) {
		 // Add any new telemetry modifiers even if raw data didn't have to be fetched down again
			 commit('setTelemetryModifiers', { rID, percentKeys, accumulationKeys })
		 }
	 },
 
	 /** Updates a report's settings via API
	  * @param _
	  * @param {Object} p
	  * @param {string} 			p.customerID
	  * @param {string} 			p.description
	  * @param {string} 			p.deviceModel
	  * @param {ReportSettings} 	p.reportConfig
	  * @param {string} 			p.reportID
	  * @param {string} 			p.title
	 */
	 updateReport(_, { customerID, description, deviceModel, reportConfig, reportID, title }) {
		 const jwt = {
			 Authorization: localStorage.getItem('authToken')
		 }
		 const body = {
			 DeletedAt: null,
			 customer_id: customerID,
			 description: description,
			 device_model: deviceModel,
			 report_config: reportConfig,
			 report_id: reportID,
			 title: title
		 }
		 return axios
			 .put(`/api/v1/reports/${reportID}`, body, {
				 headers: jwt,
				 timeout: 5000
			 }).catch(error => {
				 console.error('Error updating report:', error)
			 })
	 },
 
	 /** Deletes a report on the API
	  *
	  * @param {import('vuex').ActionContext<ReportStore>} _
	  * @param {String} reportID
	 */
	 deleteReport({ commit }, reportID) {
		 const jwt = {
			 Authorization: localStorage.getItem('authToken')
		 }
		 return axios
			 .delete(`/api/v1/reports/${reportID}`, {
				 headers: jwt,
				 timeout: 5000
			 }).then(() => commit('_removeReport', reportID)).catch(error => {
				 console.error('Error updating report:', error)
			 })
	 },
 
	 /** Updates a report's settings via API
	  *
	  * @param {import('vuex').ActionContext<ReportStore>} _
	  * @param {Object} p
	  * @param {string} 			p.customerID
	  * @param {string} 			p.description
	  * @param {string} 			p.deviceModel
	  * @param {ReportSettings} 	p.reportConfig
	  * @param {string} 			p.reportID
	  * @param {string} 			p.title
	 */
	 createReport(_, { customerID, description, deviceModel, reportConfig, reportID, title }) {
		 const jwt = {
			 Authorization: localStorage.getItem('authToken')
		 }
		 const body = {
			 DeletedAt: null,
			 customer_id: customerID,
			 description: description,
			 device_model: deviceModel,
			 report_config: reportConfig,
			 report_id: reportID,
			 title: title
		 }
		 return axios
			 .post('/api/v1/reports', body, {
				 headers: jwt,
				 timeout: 5000
			 }).catch(error => {
				 console.error('Error updating report:', error)
			 })
	 },
 
	 /** Fetch all reports from remote API and load it into the store
	  *
	  * @param {import('vuex').ActionContext<typeof storeState, any>} _
	  * @param {String[]} customerIDs - List of customer ids to fetch reports for
	  *
	  * @returns {Promise} Axios promise all for fetching reports from api
	  */
	 fetchReports({ commit }, customerIDs) {
		 const jwt = {
			 Authorization: localStorage.getItem('authToken')
		 }
		 /** Create a promise factory to get reports for each customer
		 * @param {String} customerID customer IDs to fetch reports for
		 * @returns {Promise} Axios promise object with results of getting reports for customer
		 */
		 function getReportsForCustomer(customerID) {
			 return axios
				 .get(`/api/v1/customers/${customerID}/reports`, {
					 headers: jwt,
					 timeout: 5000
				 })
				 .then((response) => {
					 // Translate reports's parameter names from the API keys to javascript friendly keys
					 // These keys are documented in state declaration at the top of the page as well
					 const translatedReports = {}
					 for (const report of response.data.reports) {
						 translatedReports[report.report_id] = report // Leaving for later, not translating keys right now.
					 }
					 // Hard code in default report layout for now
					 translatedReports.demo_report_thing = {
						 report_id: 'demo_report_thing',
						 customer_id: '2d3fe589-aa93-4e41-9197-87f75493cc73',
						 title: 'PQV Demo Report',
						 description: 'Example AMP report based on PQV device',
						 report_config: {
							 accumulationKeys: ['failCount', 'passCount', 'warnCount'],
							 bucketSize: 60,
							 chartLines: [{ _rowID: '0', color: '#001eff', key: 'webspeed', label: 'Web Speed(m/sec)' }],
							 graphContextSplit: 'roll_number',
							 graphLabels: ['job_id', 'roll_number'],
							 graphText: [
								 //{ _rowID: '0', agg: 'duration', label: 'Runtime', rawString: false, unit: '', value: '' },
								 //{ _rowID: '1', agg: 'startTime', label: 'Started', rawString: false, unit: '', value: '' },
								 { _rowID: '2', agg: 'average', label: 'Avg. Web Speed', rawString: false, unit: '(m/min)', value: 'webspeed' },
								 { _rowID: '3', agg: 'accumulation', label: 'Pass Count', rawString: false, unit: '(imp)', value: 'passCount' }],
							 percentageKeys: [
								 { keys: ['failCount', 'warnCount', 'passCount'], name: 'fail', value: 'failCount' },
								 { keys: ['failCount', 'warnCount', 'passCount'], name: 'warn', value: 'warnCount' },
								 { keys: ['failCount', 'warnCount', 'passCount'], name: 'pass', value: 'passCount' }],
							 pieData: [
								 { _rowID: '0', color: '#00c20d', key: 'pass', label: 'Pass' },
								 { _rowID: '1', color: '#ffb22e', key: 'warn', label: 'Warn' },
								 { _rowID: '2', color: '#ee2020', key: 'fail', label: 'Fail' }],
							 reportText: [
								 { _rowID: 0, agg: 'duration', label: 'Job Length', rawString: false, unit: '', value: '' },
								 { _rowID: 1, agg: 'value', label: 'Rolls Used', rawString: false, unit: '', value: 'roll_number' }],
							 reportTitle: 'PQV Demo Report',
							 shadingTelemetry: [{ _rowID: '0', color: '#ee2020', key: 'fail', label: 'Fail (%)' },
								 { _rowID: '1', color: '#eaff4d', key: 'warn', label: 'Warn (%)' },
								 { _rowID: '2', color: '#1dff1a', key: 'pass', label: 'Pass (%)' }],
							 tableColumns: [{ _rowID: '0', agg: 'value', key: 'roll_number', label: 'Rolls' },
								 //{ _rowID: '1', agg: 'duration', key: 'duration', label: 'Duration' },
								 { _rowID: '2', agg: 'startTime', key: 'startTime', label: 'Start' },
								 { _rowID: '3', agg: 'endTime', key: 'endTime', label: 'End' },
								 { _rowID: '4', agg: 'timeWindow', key: 'timeWindow', label: 'Window' },
								 { _rowID: '5', agg: 'minimumValue', key: 'webspeed', label: 'Min. Web Speed' },
								 { _rowID: '6', agg: 'average', key: 'webspeed', label: 'Avg. Web Speed' },
								 { _rowID: '7', agg: 'maximumValue', key: 'webspeed', label: 'Max Web Speed' },
								 { _rowID: '8', agg: 'observationCount', key: 'webspeed', label: 'Web Speed Telem Count' },
								 { _rowID: '9', agg: 'accumulation', key: 'passCount', label: 'Pass Accumulation' }],
							 tableContextSplit: 'roll_number'
						 },
						 device_model: 'Guardian PQV',
						 DeletedAt: null
					 }
 
					 commit('_setReports', translatedReports)
				 })
				 .catch((error) => {
					 console.error('Error loading reports', error)
				 }).finally(_ => {
					 commit('_setQueried', customerID)
				 })
		 }
		 const getReportPromises = []
		 for (const customerID of customerIDs) {
			 getReportPromises.push(getReportsForCustomer(customerID))
		 }
		 return Promise.all(getReportPromises)
	 }
 }
 
 /** @type {import("vuex").MutationTree<ReportStore>} */
 export const storeMutations = {
	 /** Adds pending API calls under a request id
	  * @param {ReportStore} state
	  * @param {Object} p
	  * @param {string} 	p.rID 		- Request ID
	  * @param {Promise} p.promise 	- Will be resolved when all API hits associated with rID are finished
	  */
	 setTelemetryLoading(state, { rID, promise }) {
		 Vue.set(state.loadedTelemetry, rID, promise)
	 },
 
	 /** Saves API telemetry as is from the call
	  * @param state
	  * @param {Object} p
	  * @param {string} 				p.rID 				- Request ID
	  * @param {RawTelemetry[]} 		p.data 				- Telemetry returned from API
	  * @param {ReportPercentKeys[]} p.percentKeys 		- Dictates what percentile telemetry should be created see type for details
	  * @param {string[]} 			p.accumulationKeys 	- Array of telemetry keys that are to be treated as sums
	  */
	 setRawTelemetry(state, { rID, data, percentKeys, accumulationKeys }) {
		 data.sort((a, b) => a.start_ts - b.start_ts)
		 const obs = {
			 telemetry: data,
			 percentKeys: percentKeys,
			 accumulationKeys: accumulationKeys
		 }
		 Vue.set(state.rawTelemetry, rID, obs)
	 },
	 /** Sets in place modifier settings to be used when aggregating telemetry
		  * Currently elements cannot be removed or changed once they are set, however new ones can be added
		  *
		  * @param state
		  * @param {Object} p
		  * @param {string}				p.rID 				- Unique ID for a device and timespan
		  * @param {string[]}			p.accumulationKeys	- Array of telemetry keys that are to be treated as sums
		  * @param {ReportPercentKeys[]}	p.percentKeys 		- Dictates what percentile telemetry should be created see type for details
		  */
	 setTelemetryModifiers(state, { rID, percentKeys, accumulationKeys }) {
		 if (rID in state.rawTelemetry) {
			 // Manage percent information
			 // Add the new keys without duplicates and preserve old keys
			 for (const p of percentKeys) {
				 // Ensure needed data exists
				 if ('name' in p && 'value' in p && 'keys' in p) {
					 // Percent keys cannot be modified once they are set
					 if (!(p.name in state.rawTelemetry[rID].percentKeys)) {
						 Vue.set(state.rawTelemetry[rID].percentKeys, p.name, p)
					 }
				 } else {
					 console.warn('Percent telemetry keys missing required values')
				 }
			 }
			 // Manage accumulation information
			 // Remove duplicates and keep all entries
			 Vue.set(state.rawTelemetry[rID], 'accumulationKeys', Array.from(new Set([...state.rawTelemetry[rID].accumulationKeys, ...accumulationKeys])))
		 } else {
			 console.warn('Unable to set modifiers rID does not exist')
		 }
	 },
	 /** Parse API context into a nested structure expected to be
		  * rID: {
		  *   <Context Key>: {
		  *     <Context Value>: [
		  *       {start: <Timestamp of starting time value was active>, end: <Timestamp of end of window>}...
		  *     ]
		  *   }...
		  * }
		  *
		  * Each Context key has a single entry for values. Each value can have an array of start/stop timestamps.
		  * Context time ranges will always be in sorted order by their start time.
		  * @param state
		  * @param {Object} p
		  * @param {string}  	p.rID			- Request ID
		  * @param {Context[]}	p.rawContext	- Returned context data from remote API
		  */
	 setContext(state, { rID, rawContext }) {
		 /** @type {Object<string,Object<string,reportContext[]>>} */
		 const context = {}
		 for (const c of rawContext) {
			 // Add context type as needed
			 if (!(c.key in context)) {
				 context[c.key] = {}
			 }
			 // Add context value key as needed
			 if (!(c.value in context[c.key])) {
				 context[c.key][c.value] = []
			 }
			 context[c.key][c.value].push({
				 start: new Date(c.start_ts).getTime(),
				 end: c.end_ts.Valid ? new Date(c.end_ts.Time).getTime() : new Date().getTime()
			 })
		 }
		 // Sort ranges by start time
		 for (const contextKey in context) {
			 for (const value of Object.values(context[contextKey])) {
				 value.sort((a, b) => a.start - b.start)
			 }
		 }
		 Vue.set(state.context, rID, context)
	 },
	 /** Sorts raw telemetry into telemetry keys under the rID key
	  * Each key pointing to an array of telemetry data
	  * Each array is sorted by .start
	  *
	  * @param state
	  * @param {Object} p
	  * @param {string} 			p.rID 	- Request ID associated with telemetry
	  * @param {RawTelemetry[]} 	p.data  - Telemetry to be added
	  */
	 setTelemetry(state, { rID, data }) {
		 /** @type {Object<string,ReportTelemetry[]>} */
		 const telem = {}
		 for (const observation of data) {
			 for (const [k, t] of Object.entries(observation.telemetry)) {
				 // Add new keys as needed
				 if (!(k in telem)) {
					 telem[k] = []
				 }
				 telem[k].push({
					 start: observation.start_ts,
					 end: observation.end_ts,
					 value: t.sum / t.count,
					 sum: t.sum,
					 count: t.count,
					 isPercentage: false
				 })
			 }
		 }
		 // Ensure telemetry order
		 for (const t of Object.values(telem)) {
			 t.sort((a, b) => a.start - b.start)
		 }
		 Vue.set(state.telemetry, rID, telem)
	 },
 
	 /** Sets the state's reports
	  *
	  * @param {Object<string,Report>} reports - Keys are report IDs, values are all associated values of that report
	  */
	 _setReports(state, reports) {
		 for (const reportID in reports) {
			 Vue.set(state.reports, reportID, reports[reportID])
		 }
	 },
	 /** Add a queried id to queried set
	  *
	  * @param {String} customerID	ID of machine queried for
	  */
	 _setQueried(state, customerID) {
		 state.queried.add(customerID)
	 },
	 /** Resets the entire store to default values */
	 resetState(state) {
		 Object.assign(state, getDefaultReportState())
	 },
	 /** Removes a report from the store
	  *
	  * @param {string} reportID - Report to be removed
	  */
	 _removeReport(state, reportID) {
		 Vue.delete(state.reports, reportID)
	 }
 }
 
 /** @type {import("vuex").GetterTree<typeof storeState>} */
 export const storeGetters = {
	 /** Creates a request ID (rID) to be used all over for storing and accessing telemetry
	  * Telemetry, context and anything else derived from an API request is expected to use this key
	  */
	 getRequestID: (state) =>
	 /**
	  * @param {Object} p
	  * @param {string}  p.id - Device ID
	  * @param {number}  p.start - Starting timestamp of data
	  * @param {number}  p.end - Ending timestamp of data
	  *
	  * @returns {string} Unique ID to be used when requesting data for this device and times
	  */
		 ({ id, start, end }) => {
			 return `${id}_${start}_${end}`
		 },
	 /** Fetches telemetry within the requested time window and aggregating it by N seconds of bin
	  * All telemetry modifications within the rID request will be applied to the telemetry
	  *
	  * Returned data is an array of objects.
	  * Top level keys are telemetry keys pointing to an object with summary information
	  */
	 getBucketData: (state) =>
	 /**
	  * @param {Object} p
	  * @param {string} p.rID 	- Unique request ID
	  * @param {number} p.start 	- MS timestamp of the earliest wanted telemetry
	  * @param {number} p.stop} 	- MS timestamp of the last wanted telemetry
	  * @param {number} p.bin 	- Number of seconds each bin should be, cannot be zero
	  *
	  * @returns {Object<string, {sum:number,count:number,start:number,end:number}>[]}
  */
		 ({ rID, start, stop, bin }) => {
			 if (!(rID in state.rawTelemetry)) {
				 return []
			 }
 
			 const range = stop - start
			 // Convert passed in seconds to MS
			 const binMS = bin * 1000
			 const numBuckets = Math.ceil(range / (binMS))
 
			 /** Holds all telemetry grouped by time windows
			  * @type {Object<string, {sum:number,count:number,start:number,end:number}>[]} */
			 const buckets = Array(numBuckets)
 
			 /** Some telemetry are accumulating based and need to be reset each time window
			  * Currently unused but is the first part of tracking resetting sums mid bin, leaving for now. Will be pruned within a merge or two
		  */
			 const accumulation = Array(numBuckets)
 
			 // Initialize each spot
			 for (let i = 0; i < numBuckets; i++) {
				 buckets[i] = {}
				 accumulation[i] = {
					 highest: 0,
					 reset: false,
					 resetCount: 0
				 }
			 }
			 for (const d of state.rawTelemetry[rID].telemetry) {
				 // Omit data points outside of the requested window as the rID can represent a larger ranger then start & stop times
				 if (d.start_ts < start || d.start_ts > stop) continue
 
				 const offset = d.start_ts - start
				 let i = Math.floor(offset / (binMS))
				 // If a timestamped telemetry is the exact same as the ending time window it causes it to be one over
				 if (i >= numBuckets) i = numBuckets - 1
				 for (const [t, v] of Object.entries(d.telemetry)) {
					 if (!(t in buckets[i])) {
						 buckets[i][t] = {
							 sum: 0,
							 count: 0,
							 start: start + (i * binMS),
							 end: start + (binMS) + (i * binMS)
						 }
					 }
					 if (state.rawTelemetry[rID].accumulationKeys.includes(t)) {
						 buckets[i][t].sum = v.sum
					 } else {
						 buckets[i][t].sum += v.sum
					 }
					 buckets[i][t].count += v.count
					 // Ideally should keep summing values instead of reverting to the new lower number
				 }
			 }
 
			 // Some telemetry are accumulations, restart the running count for each bucket
			 try {
				 for (let i = numBuckets - 1; i > 0; i--) {
					 for (const accumKey of state.rawTelemetry[rID].accumulationKeys) {
						 if (accumKey in buckets[i] && accumKey in buckets[i - 1]) {
							 buckets[i][accumKey].sum -= buckets[i - 1][accumKey].sum
						 }
					 }
				 }
			 } catch (err) { console.log('Error bucketing data:', err) }
			 try {
				 // Some telemetry are meant to be percentages, rescale those as needed
				 for (const d of buckets) {
					 // @ts-ignore
					 calculatePercent(d, Object.values(state.rawTelemetry[rID].percentKeys))
				 }
			 } catch (err) {
				 console.log('Error is:', err)
			 }
			 return buckets
		 },
 
	 /** Return telemetry grouped by context keys and values and time ranges
	  * Top level keys are context names-
	  * Next level of keys are context values
	  * Each context value points to an array of objects holding the start, end and telemetry associated with that context duration
	  * Accumulation based keys are reset for the start of each time window.
	  *
	  * Example for context "status" with a value of "makeReady"
	  *
	  * Status: {
	  *   makeReady:[
	  *      {start: 0, stop: 20: telemetry:{telemetry keys pointing to array of ReportTelemetry objects},
	  *      {start: 50, stop 80: telemetry:{telemetry keys pointing to array of ReportTelemetry objects}}
	  * ]
	  * }
	  */
	 getGroupedTelemetry: (state) =>
		 /**
		  * @param {Object} p
		  * @param {string} p.rID - Request ID associated with telemetry
		  *
		  * @returns {Object<string,Object<string,{start:number,end:number,telemetry:Object<string,ReportTelemetry[]>}[]>>}
		  */
		 ({ rID }) => {
		 // If data has not been loaded
			 if (!(rID in state.context) || !(rID in state.rawTelemetry)) return {}
			 const rawTelem = state.rawTelemetry[rID].telemetry
			 /** @type {Object<string,Object<string,{start:number,end:number,telemetry:Object<string,ReportTelemetry[]>}[]>>} */
			 const telem = {}
 
			 /** Bisector returns the insertion point for x in array to maintain sorted order.
		  * The arguments lo and hi may be used to specify a subset of the array which should be considered; by default the entire array is used.
		  * If x is already present in array, the insertion point will be before (to the left of) any existing entries. */
			 const find = bisector(d => d.start_ts).left
 
			 // Rotate through all the context keys and their values to find ranges to slice
			 for (const [cK, cV] of Object.entries(state.context[rID])) {
				 telem[cK] = {}
				 for (const [value, range] of Object.entries(cV)) {
					 telem[cK][value] = []
 
					 for (const r of range) {
					 /** @type {Object<string, ReportTelemetry[]>} */
						 const parsedTelem = {}
 
						 // Find location within raw telemetry to be parsed
						 let left = find(rawTelem, r.start)
						 if (left === rawTelem.length) continue // Context range does not exist in the telemetry
						 if (rawTelem[left + 1].start_ts === r.start) left++
						 let right = find(rawTelem, r.end, left)
						 if (right === 0) continue // Context range does not exist in telemetry
						 if (rawTelem[right + 1]?.start_ts === r.end) right++
 
						 // All telemetry that appears within the needed time range
						 // From here on modifications will be made as telemetry is parsed
						 const telemSubset = JSON.parse(JSON.stringify(rawTelem.slice(left, right)))// structuredClone(rawTelem.slice(left, right))
						 // Minimum accumulation value is needed
						 /** @type {Object<string,{min:number,previous:number,runningTotal:number,notReset:boolean}>} */
						 const accumulations = {}
						 for (const t of telemSubset) {
						 // All keys are expected to have a 'value' assign it now to avoid issues later
							 for (const telemValues of Object.values(t.telemetry)) {
								 telemValues.value = telemValues.sum / telemValues.count
							 }
 
							 // Any accumulation keys need to be reset to the start of this scope
							 for (const accumKey of state.rawTelemetry[rID].accumulationKeys) {
								 if (accumKey in t.telemetry) {
								 // First instance of an accumulation value, take it as starting value
									 if (!(accumKey in accumulations)) {
										 accumulations[accumKey] = {
											 min: t.telemetry[accumKey].sum / t.telemetry[accumKey].count,
											 runningTotal: 0,
											 previous: 0,
											 notReset: true
										 }
									 }
									 // Scale accumulation value down, add a value to it
									 // If the telemetry value is less then the starting value, then accumulation was reset for other reasons device side
									 // And it does not need to be scaled back
 
									 // Current value of the telemetry in question
									 let curr = t.telemetry[accumKey].sum / t.telemetry[accumKey].count
									 if (curr < accumulations[accumKey].previous) {
										 accumulations[accumKey].notReset = false
										 accumulations[accumKey].runningTotal += curr
									 }
									 // Accumulation telemetry has not reset mid context
									 if (accumulations[accumKey].notReset) {
										 curr = curr - accumulations[accumKey].min
									 } else {
										 curr += accumulations[accumKey].runningTotal
									 }
									 accumulations[accumKey].previous = curr
									 t.telemetry[accumKey].value = curr
								 }
							 }
 
							 // Layer in percentage updates as needed
							 calculatePercent(t.telemetry, Object.values(state.rawTelemetry[rID].percentKeys))
 
							 // Parse into columns per key
							 for (const [telemKey, telemValues] of Object.entries(t.telemetry)) {
								 if (!(telemKey in parsedTelem)) {
									 parsedTelem[telemKey] = []
								 }
								 if (telemValues.value === undefined) telemValues.value = telemValues.sum / telemValues.count
								 parsedTelem[telemKey].push({
									 start: t.start_ts,
									 end: t.end_ts,
									 value: telemValues.value,
									 sum: telemValues.sum,
									 count: telemValues.count,
									 isPercentage: telemValues.isPercentage === undefined ? false : telemValues.isPercentage
								 })
							 }
						 }
						 telem[cK][value].push({
							 start: r.start,
							 end: r.end,
							 telemetry: parsedTelem
						 })
					 }
				 }
			 }
			 return telem
		 },
 
	 /** Returns the telemetry straight forward without modifications or grouping */
	 getTelemetry: (state) =>
	 /**
	  * @param {string} rID - Request ID that telemetry is to be fetched from
	  *
	  * @returns {{}|ReportTelemetry[]}
	  */ (rID) => {
			 if (rID in state.telemetry) return state.telemetry[rID]
			 return {}
		 },
 
	 /** Returns context information for the given request ID
	  * Top level returned keys will be a context key such as JOBID
	  * Each of those points to keys that are context values such as job_1
	  * Each of those pointing to an array of context information of start and stop time.
	  * These times indicate when the corresponding value was active
	  * {
	  *   JOBID: {
	  *     job_1: [
	  *       { start:<MS timestamp start time>, end: <MS timestamp end time>}
	  *       ...
	  *     ]
	  *   }
	  *   ...
	  *}
	 */
	 getContext: (state) =>
	 /** @param {string} rID - Request ID to fetch information from
	  *
	  * @param {string} rID
	  *
	  * @returns {Object<string,Object<string,reportContext[]>>}
	  */
		 (rID) => {
			 if (rID in state.context) {
				 return state.context[rID]
			 }
			 return {}
		 },
	 /** Gets all loaded reports
	  *
	  * @return {Object<string,Report>} Keys are report UID, values consist of report properties
	  */
	 getReports(state) {
		 return state.reports
	 },
	 /** Gets all parameters previously queried for
	  *
	  * @return {Set<string>}	Set of entity ids queried for
	  */
	 getQueried(state) {
		 return state.queried
	 }
 }
 
 export default {
	 namespaced: true,
	 state: storeState,
	 getters: storeGetters,
	 actions: storeActions,
	 mutations: storeMutations
 }
 