import { render, staticRenderFns } from "./global_side_bar.vue?vue&type=template&id=58d4f3e2&scoped=true&"
import script from "./global_side_bar.vue?vue&type=script&lang=js&"
export * from "./global_side_bar.vue?vue&type=script&lang=js&"
import style0 from "./global_side_bar.vue?vue&type=style&index=0&id=58d4f3e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d4f3e2",
  null
  
)

export default component.exports