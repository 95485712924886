import axios from 'axios'
import Vue from 'vue'

// Used in generating and evaluating the custom telemetry
import { compile } from 'mathjs'

/** Holds meta data breakdown about all telemetry and custom telemetry info
 * @typedef {Object} TelemetryMetaStore
 * @property {Object<string,Object<string,Object<string,Object<string,DefaultTelemetry>>>>} defaultTelemetry 	- CustomerID -> make -> model -> telem key -> default Telem
 * @property {Set<String>} 																	defaultQueried 		- All customer IDs that have been queried for default telemetry
 * @property {Null|Object<string,Object<string,TelemetryMeta>>}								telemetry			- Top level key are devices then telemetry key name. Holds telemetry metadata information
 * @property {Object<string,CustomTelemetry>} 												customTelemetry 	- Loaded custom telemetry
 * @property {Set<String>} 																	queried 			- All devices that have been queried for current state
 */

/** @returns {TelemetryMetaStore} */
export const getDefaultTelemetryState = () => {
	return {
		telemetry: null,
		customTelemetry: {},
		/** DefaultTelemetry is an nested object of structure:
		 * {
		 * 		<customer ID>: {
		 * 			<make>: {
		 * 				<model>: {
		 * 					<telem key>: {
		 * 						<default telemetry object>
		 * 					}
		 * 				}
		 * 			}
		 * 		}
		 * }
		 */
		defaultTelemetry: {},
		/** Set of devices that telemetry has been queried for currently in state */
		queried: new Set(),
		defaultQueried: new Set()
	}
}

export const storeState = getDefaultTelemetryState()

/** @type {import("vuex").ActionTree<TelemetryMetaStore>} */
export const storeActions = {
	/**
	 * Pulls down a devices custom telemetry if it does not already exist in the store.
	 * Pass in a deviceID to grab, optionally an API call can be forced by setting hardRefresh to true
	 *
	 * @param {import('vuex').ActionContext<TelemetryMetaStore, any>} _
	 * @param {Object} p
	 * @param {string} 	p.deviceID
	 * @param {boolean} [p.hardRefresh]
	 *
	 * @returns {Promise}
	 */
	fetchCustomTelemetry({ state, commit }, { deviceID, hardRefresh = false }) {
		// If the device exists we have already loaded this devices custom telemetry once
		if (!hardRefresh && deviceID in state.customTelemetry) return
		// Pull device info down from api
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.get(`/analytics/v1/devices/${deviceID}/customTelemetry`, {
				headers: jwt,
				timeout: 15000
			})
			.then((response) => {
				// Reset all custom telemetry for the device, since API is source of truth
				commit('_removeCustomTelemetry', deviceID)
				if (response.data.custom_telemetry === null) {
					// No data found
					return
				}
				// Load each of the recieved rules into the device
				// Updating keys to match javascript conventions
				for (const telem of response.data.custom_telemetry) {
					/** @type {CustomTelemetry} */
					const customTelem = {
						deviceID: telem.device_id,
						stringEquation: telem.string_equation,
						telemetryID: telem.telemetry_id,
						telemetryName: telem.telemetry_name,
						texEquation: telem.tex_equation,
						telemetryKeys: telem.telemetry_keys,
						unit: telem.unit,
						description: telem.description,
						function: compile(telem.string_equation).evaluate,
						timespan: telem.timespan
					}
					if (customTelem.description === null) customTelem.description = ''

					if (telem.telemetryKeys === null) telem.telemetryKeys = []

					commit('setCustomTelemetry', customTelem)
				}
			})
			.catch(error => {
				console.warn('Problem getting custom telem', error)
				// If telemetry does not exist on API (or some other failure) remove it
				commit('_removeCustomTelemetry', deviceID)
			})
	},
	/** Add a new custom telemetry to the database for the device
	 *
	 * @param _
	 * @param {CustomTelemetry} newCustom
	 *
	 * @returns {Promise}
	*/
	createCustomTelemetry(_, newCustom) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			telemetry_name: newCustom.telemetryName,
			device_id: newCustom.deviceID,
			unit: newCustom.unit,
			description: newCustom.description,
			string_equation: newCustom.stringEquation,
			tex_equation: newCustom.texEquation,
			telemetry_keys: newCustom.telemetryKeys,
			latest_value: newCustom.latestValue,
			timespan: newCustom.timespan
		}
		try {
			return axios
				.post('/analytics/v1/customTelemetry', body, {
					headers: jwt,
					timeout: 15000
				}
				)
		} catch (error) {
			console.error('Error creating single rule', error)
			return Promise.reject(error)
		}
	},

	/** Delete a custom telemetry from the database
	 *
	 * @param _
	 * @param {CustomTelemetry} telem - Object to be removed
	 *
	 * @returns {Promise}
	*/
	deleteCustomTelemetry({ commit }, telem) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		try {
			return axios
				.delete(`/analytics/v1/devices/${telem.deviceID}/customTelemetry/${telem.telemetryID}`, {
					headers: jwt,
					timeout: 15000
				}
				).then(() => {
					commit('_removeSingleCustomTelemetry', { deviceID: telem.deviceID, telemName: telem.telemetryName })
					commit('_removeTelemetry', { deviceID: telem.deviceID, telemKey: telem.telemetryName })
				})
		} catch (error) {
			console.error('Error deleting custom telemetry', error)
			return Promise.reject(error)
		}
	},

	/** Modify a custom telemetry on the database
	 *
	 * @param _
	 * @param {CustomTelemetry} custom - New settings to be used
	*/
	updateCustomTelemetry(_, custom) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			telemetry_name: custom.telemetryName,
			device_id: custom.deviceID,
			unit: custom.unit,
			description: custom.description,
			string_equation: custom.stringEquation,
			tex_equation: custom.texEquation,
			telemetry_keys: custom.telemetryKeys,
			telemetry_id: custom.telemetryID
		}
		try {
			return axios
				.put(`/analytics/v1/devices/${custom.deviceID}/customTelemetry/${custom.telemetryID}`, body, {
					headers: jwt,
					timeout: 15000
				}
				)
		} catch (error) {
			console.error('Error creating custom telemetry', error)
			return Promise.reject(error)
		}
	},

	/** Fetch all default telemetry for a customer group from remote API and load it into the store
	 * @param {import('vuex').ActionContext<TelemetryMetaStore>} _
	 * @param {String[]} customerIDs - Array of customer ID's to fetch default telemetry for
	 * @returns {Promise} Axios promise all for fetching telemetry from api
	 */
	generateDefaultTelemetry({ commit, getters }, customerIDs) {
		/** @type {Promise[]} */
		const getTelemetryPromises = []
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}

		/** Create a promise factory to get default telemetry for each customer
		* @param {String} customerID customer ID to fetch default telemetry for
		* @returns {Promise} Axios promise object with results of getting rules for device
		*/
		function getDefaultTelemetryForCustomer(customerID) {
			return axios
				.get('/analytics/v1/customers/' + customerID + '/defaultTelemetry', {
					headers: jwt,
					timeout: 15000
				})
				.then((response) => {
					// translate telemetry's parameter names to javascript friendly strings
					// read telemetry objects from api and build into structure like it will be stored:
					/** {
						* 	<make>: {
						* 		<model>: {
						* 			<telem key>: {
						* 				<default telemetry object>
						* 			}
						* 		}
						* 	}
						* }
					**/
					const translatedTelemetry = {}
					if (response.data.default_telemetry) {
						for (const dt of response.data.default_telemetry) {
							// telemetry is in a simple list from API call
							if (!(dt.device_make in translatedTelemetry)) {
								translatedTelemetry[dt.device_make] = {}
							}
							if (!(dt.device_model in translatedTelemetry[dt.device_make])) {
								translatedTelemetry[dt.device_make][dt.device_model] = {}
							}
							translatedTelemetry[dt.device_make][dt.device_model][dt.key_name] = {
								customerID: dt.customer_id,
								make: dt.device_make,
								model: dt.device_model,
								key: dt.key_name,
								originDevice: dt.origin_device,
								readableName: dt.readable_name,
								translationValues: dt.translation_values,
								useTranslationValues: dt.use_translation_values,
								unit: dt.unit,
								description: dt.description
							}
						}
					}
					commit('_setDefaultTelemetry', { customerID: customerID, telemetry: translatedTelemetry })
				})
				.catch((error) => {
					console.error('Error loading Default Telemetry', error)
				}).finally(_ => {
					commit('_setDefaultQueried', customerID)
				})
		}
		for (const customerID of customerIDs) {
			// Only query a default telem once
			if (!getters.getDefaultQueried.has(customerID)) {
				commit('_setDefaultQueried', customerID)
				getTelemetryPromises.push(getDefaultTelemetryForCustomer(customerID))
			}
		}
		return Promise.all(getTelemetryPromises)
	},

	/** Add a new default telemetry to the database for the device type
	 * @param {import('vuex').ActionContext<TelemetryMetaStore, any>} _ - Unused context
	 * @param {Object} p
	 * @param {TelemetryMeta} 	p.telemMeta		the telemetry object being set as default
	 * @param {String} 			p.make			the make of the device telemetry is for
	 * @param {String} 			p.model			the model of the device telemetry is for
	 * @param {String} 			p.customerID	the customer id of the device type telemetry is for
	 * @returns {Promise}
	*/
	createDefaultTelemetry({ dispatch }, { telemMeta, make, model, customerID }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			key_name: telemMeta.key,
			origin_device: telemMeta.deviceID,
			readable_name: telemMeta.readableName,
			unit: telemMeta.unit,
			description: telemMeta.description,
			device_make: make,
			device_model: model,
			customer_id: customerID,
			translation_values: telemMeta.translationValues,
			use_translation_values: telemMeta.useTranslationValues
		}
		return axios
			.post(
				'/analytics/v1/defaultTelemetry', body, {
					headers: jwt,
					timeout: 15000
				}
			).then(() => {
				return dispatch('generateDefaultTelemetry', [customerID])
			})
	},

	/** Updates Existing Default Telemetry
	 * @returns {Promise} Axios promise object with response from updating in db
	 */
	updateDefaultTelemetry({ dispatch }, { telemMeta, make, model, customerID }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			key_name: telemMeta.key,
			origin_device: telemMeta.deviceID,
			readable_name: telemMeta.readableName,
			unit: telemMeta.unit,
			description: telemMeta.description,
			device_make: make,
			device_model: model,
			customer_id: customerID,
			translation_values: telemMeta.translationValues,
			use_translation_values: telemMeta.useTranslationValues
		}
		return axios
			.put(
				'/analytics/v1/customers/' + customerID + '/defaultTelemetry/make/' + make + '/model/' + model + '/key/' + telemMeta.key,
				body, {
					headers: jwt,
					timeout: 15000
				}
			)
			.then(() => {
				return dispatch('generateDefaultTelemetry', [customerID])
			})
			.catch((error) => {
				console.error('Error updating telemetry', error)
				return Promise.reject(error)
			})
	},

	/** Delete default telemetry entity in the database
	 * @param {import('vuex').ActionContext<TelemetryMetaStore, any>} _ - Unused context
	 * @param {Object} p
	 * @param {String} 			p.make			the make of the device telemetry is for
	 * @param {String} 			p.model			the model of the device telemetry is for
	 * @param {String} 			p.customerID	the customer id of the device type telemetry is for
	 * @param {String}			p.key			the key of the telemetry being deleted
	 * @returns {Promise}
	 */
	deleteDefaultTelemetry({ commit }, { customerID, make, model, key }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.delete(
				'/analytics/v1/customers/' + customerID + '/defaultTelemetry/make/' + make + '/model/' + model + '/key/' + key,
				{
					headers: jwt,
					timeout: 15000
				}
			)
			.then(() => {
				return commit('_removeDefaultTelemetry', { customerID: customerID, make: make, model: model, key: key })
			})
			.catch((error) => {
				console.error('Error deleting telemetry', error)
				return Promise.reject(error)
			})
	},

	/** Fetch all telemetry from remote API and load it into the store
	 * @param _
	 * @param {String[]} deviceIDs - Array of device ID's to fetch telemetry meta info from
	 *
	 * @returns {Promise} Axios promise all for fetching telemetry from api
	 */
	fetchMetaTelemetry({ dispatch, commit }, deviceIDs) {
		/** @type {Promise[]} */
		const getTelemetryPromises = []
		for (const deviceID of deviceIDs) {
			getTelemetryPromises.push(dispatch('fetchMetaTelemetryForDevice', deviceID)
				.then((telemetry) => {
					commit('_setTelemetry', telemetry)
				})
				.catch(error => {
					console.error('Problems loading telemetry:', error)
					commit('_setTelemetry', { deviceID: deviceID, telemetry: {} })
				})
				.finally(_ => {
					commit('_setQueried', deviceID)
				}))
		}
		return Promise.all(getTelemetryPromises)
	},
	/** Hits the API and adds in custom telemetry observations, returning contents of API return
	 *
	 * @param _
	 * @param {String} deviceID - Device to fetch telemetry meta from
	 *
	 * @returns {Promise<{deviceID: string, telemetry: TelemetryMeta[]}>}
	 */
	async fetchMetaTelemetryForDevice({ dispatch, commit, getters, rootGetters }, deviceID) {
		// Ensure custom information for device is loaded
		await dispatch('fetchCustomTelemetry', { deviceID: deviceID })

		// Load default telemetry if needed, as we will merge with devices telem
		const deviceObj = rootGetters['Devices/getDevices'][deviceID]
		if (!(getters.getDefaultQueried.has(deviceObj.customerID))) {
			await dispatch('generateDefaultTelemetry', [deviceObj.customerID])
		}
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}

		return axios
			.get('/analytics/v1/devices/' + deviceID + '/telemetry', {
				headers: jwt,
				timeout: 15000
			})
			.then((response) => {
				// Translate telemetry's parameter names from the API keys to javascript friendly keys
				const translatedTelemetry = response.data.telemetry
				/**
				 * @type TelemetryMeta[]
				 */
				const returnTelemetry = []
				const telemetry = {}
				for (const telem of translatedTelemetry) {
					// key translations:
					/** @type {TelemetryMeta} */
					const augmentedTelem = {
						description: telem.description,
						deviceID: telem.device_id,
						firstRecieved: telem.first_recieved,
						key: telem.key_name,
						lastRecieved: telem.last_recieved,
						maxVal: telem.maximum_val,
						minVal: telem.minimum_val,
						lastVal: telem.last_val,
						readableName: telem.readable_name,
						translationValues: telem.translation_values,
						useTranslationValues: telem.use_translation_values,
						unit: telem.unit
					}

					telemetry[augmentedTelem.key] = augmentedTelem
					returnTelemetry.push(augmentedTelem)
				}
				const allCustomTelemetry = getters.getCustomTelemetry(deviceID)

				// Loop through all functions on the device
				for (const customTelemetry of Object.values(allCustomTelemetry)) {
					// Error check that expected telemetry exists for function
					if (!(customTelemetry.telemetryKeys.every(key => key in telemetry))) continue
					// Don't override already existing elements
					if (customTelemetry.telemetryName in translatedTelemetry) continue
					// Grab needed telemetry values for calculations
					const maxValues = {}
					const minValues = {}
					for (const key of customTelemetry.telemetryKeys) {
						maxValues[key] = telemetry[key].maxVal
						minValues[key] = telemetry[key].minVal
					}
					/** @type {TelemetryMeta} */
					let tempObservation
					try {
						tempObservation = {
							description: customTelemetry.name,
							deviceID: deviceID,
							firstRecieved: null,
							key: customTelemetry.telemetryName,
							lastRecieved: null,
							maxVal: customTelemetry.function(maxValues),
							minVal: customTelemetry.function(minValues),
							readableName: customTelemetry.telemetryName,
							unit: customTelemetry.unit
						}
					} catch (error) {
						console.log('Problem making custom:', error)
						continue
					}
					returnTelemetry.push(tempObservation)
				}
				return { deviceID: deviceID, telemetry: returnTelemetry }
			})
			.catch((error) => {
				if (error?.response?.status !== 404) {
					console.error('Error loading Telemetry Meta', error)
				}
				// 404 is expected if there is no telemetry found for device, so proceed
				return { deviceID: deviceID, telemetry: [] }
			})
	},
	/** Updates Existing Telemetry
	 *
	 * @param _
	 * @param {Object} p
	 * @param {string} 								p.deviceID
	 * @param {string} 								p.keyName
	 * @param {string} 								p.readableName
	 * @param {string} 								p.unit
	 * @param {string} 								p.description
	 * @param {boolean} 							p.useTranslationValues
	 * @param {{value:string,translation:string}[]} p.translationValues
	 *
	 * @returns {Promise} Axios promise object with response from updating device
	 */
	updateTelemetry({ dispatch }, { deviceID, keyName, readableName, unit, description, useTranslationValues, translationValues }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			readable_name: readableName,
			unit: unit,
			description: description,
			use_translation_values: useTranslationValues,
			translation_values: translationValues
		}
		return axios
			.put('/analytics/v1/devices/' + deviceID + '/telemetry/' + keyName, body, {
				headers: jwt,
				timeout: 15000
			}
			)
			.then(() => {
				return dispatch('fetchMetaTelemetry', [deviceID])
			})
			.catch((error) => {
				console.error('Error updating telemetry', error)
				return Promise.reject(error)
			})
	},

	/** Deletes a telemetry metadata
	 *
	 * @param {import('vuex').ActionContext<TelemetryMetaStore>} _
	 * @param {Object} p
	 * @param {string} p.deviceID		- ID of device associated with telemetry
	 * @param {string} p.telemetryKey	- Key to be deleted
	 */
	deleteTelemetry({ commit }, { deviceID, telemetryKey }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.delete('/analytics/v1/devices/' + deviceID + '/telemetry/' + telemetryKey, {
				headers: jwt,
				timeout: 15000
			}
			)
			.then(() => {
				commit('_removeTelemetry', { deviceID: deviceID, telemKey: telemetryKey })
			})
			.catch((error) => {
				console.error('Error deleting telemetry', error)
				return Promise.reject(error)
			})
	}
}

/** @type {import("vuex").MutationTree<TelemetryMetaStore>} */
export const storeMutations = {
	/** Removes all custom telemetry associated with given device ID
	 *
	 * @param {String} device - ID of device to remove custom telemetry
	*/
	_removeCustomTelemetry(state, device) {
		Vue.delete(state.customTelemetry, device)
	},
	/** Removes a single custom telemetry from the store
	 *
	 * @param state
	 * @param {Object} p
	 * @param {string} p.deviceID
	 * @param {string} p.telemName
	 */
	_removeSingleCustomTelemetry(state, { deviceID, telemName }) {
		Vue.delete(state.customTelemetry[deviceID], telemName)
	},

	/** Removes a default telemetry entity from the store if it exists
	 * @param {import('vuex').MutationContext<typeof storeState, any>} _
	 * @param {Object} p
	 * @param {String} p.customerID		Customer id that default telem exists in
	 * @param {String} p.make			Make of device that default telem exists for
	 * @param {String} p.model			Model of device that default telem exists for
	 * @param {String} p.key			Telemetry Key to delete
	*/
	_removeDefaultTelemetry(state, { customerID, make, model, key }) {
		if (state.defaultTelemetry?.[customerID]?.[make]?.[model]?.[key]) {
			Vue.delete(state.defaultTelemetry[customerID][make][model], key)
		}
	},

	/** Assigns the passed in custom telemetry to the store, overriding values if it already existed
	 *
	 * @param {CustomTelemetry} telemetry - Single custom telemetry observation
	 */
	setCustomTelemetry(state, telemetry) {
		// If the corresponding device does not exist initialize it first
		if (!(telemetry.deviceID in state.customTelemetry)) Vue.set(state.customTelemetry, telemetry.deviceID, {})
		Vue.set(state.customTelemetry[telemetry.deviceID], telemetry.telemetryName, telemetry)
	},

	/** Assign the passed in default telemetry object (structured to be put directly into the store) into a customerID
	 * So the end result is:
	 * {
	 * 		<customer ID>: {
	 * 			<make>: {
	 * 				<model>: {
	 * 					<telem key>: {
	 * 						<default telemetry object>
	 * 					}
	 * 				}
	 * 			}
	 * 		}
	 * }
	 * @param {import('vuex').MutationContext<typeof storeState, any>} _
	 * @param {Object} p
	 * @param {String} p.customerID		Customer id that default telem is being added to
	 * @param {String} p.telemetry		Nested object with default telemetry in it
	*/
	_setDefaultTelemetry(state, { customerID, telemetry }) {
		if (!(customerID in state.defaultTelemetry)) {
			Vue.set(state.defaultTelemetry, customerID, {})
		}
		Vue.set(state.defaultTelemetry, customerID, telemetry)
	},
	/** Sets the state's telemetry meta for a device
	 *
	 * @param state
	 * @param {Object} p
	 * @param {String} 			p.deviceID
	 * @param {TelemetryMeta[]} p.telemetry
	 */
	_setTelemetry(state, { deviceID, telemetry }) {
		if (state.telemetry === null) {
			Vue.set(state, 'telemetry', {})
		}
		if (!deviceID) return
		if (!(deviceID in state.telemetry)) {
			Vue.set(state.telemetry, deviceID, {})
		}
		for (const telemKey of Object.values(telemetry)) {
			Vue.set(state.telemetry[deviceID], telemKey.key, telemKey)
		}
	},
	/** Remove a telemetry meta object
	 *
	 * @param state
	 * @param {Object} p
	 * @param {string} p.deviceID
	 * @param {string} p.telemKey
	 */
	_removeTelemetry(state, { deviceID, telemKey }) {
		if (state.telemetry === null) return
		if (!(deviceID in state.telemetry)) return
		Vue.delete(state.telemetry[deviceID], telemKey)
	},
	/** Add a queried id to queried set
	 *
	 * @param state
	 * @param {String} deviceID - ID of machine queried for
	 */
	_setQueried(state, deviceID) {
		state.queried.add(deviceID)
	},

	/** Add a queried customer id to the set of default telemetry queries
	 * @param {String} customerID	ID of customer default telemetry was queried for
	 */
	_setDefaultQueried(state, customerID) {
		state.defaultQueried.add(customerID)
	},

	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultTelemetryState())
	}
}

/** @type {import("vuex").GetterTree<TelemetryMetaStore>} */
export const storeGetters = {
	/** Gets all telemetry translations associated with a device
	 * All key and values are treated as numeric whenever possible
	*/
	getTelemetryTranslations: (state, getters) =>
	/**
	 * @param {String} deviceID
	 *
	 * @returns
	*/
		(deviceID) => {
			const translations = {}
			if (getters.getTelemetry !== null && deviceID in getters.getTelemetry) {
				for (const [id, telem] of Object.entries(getters.getTelemetry[deviceID])) {
					if (telem.useTranslationValues) {
						translations[id] = telem.translationValues
					}
				}
			}
			return translations
		},

	getAllCustomTelemetry(state) {
		return state.customTelemetry
	},

	/** Gets all loaded custom telemetry meta */
	getCustomTelemetry: (state) =>
	/**
	 * @param {String} deviceID - ID of the device to get all custom data for
	 *
	 * @return {CustomTelemetry|{}} */
		(deviceID) => {
			if (deviceID in state.customTelemetry) {
				return state.customTelemetry[deviceID]
			}
			return {}
		},

	/** Gets all default telemetry in store, key traversal looks like getDefaultTelemetry[customerID][make][model][key] = defaultTelem
	 */
	getDefaultTelemetry(state) {
		return state.defaultTelemetry
	},

	/** Gets all loaded telemetry meta data
	 * Default metadata is added to any telemetry that does not already have its own set
	 * @return {object} Keys are device id, values are a map of telemetry keys to telem meta objects
	 */
	getTelemetry(state, getters, _, rootGetters) {
		if (state.telemetry === null) return state.telemetry

		// Modifying elements in place break away a copy
		const t = JSON.parse(JSON.stringify(state.telemetry))
		for (const [deviceID, deviceTelem] of Object.entries(t)) {
			// Need customer id, make and model for the given device
			const device = rootGetters?.['Devices/getDevices']?.[deviceID]
			if (device === undefined) break
			// See if the device exists within default telemetry
			const deviceDefaultTelem = state.defaultTelemetry?.[device.customerID]?.[device.make]?.[device.model]
			if (deviceDefaultTelem !== undefined) {
				// All the telemetry that has some sort of default assigned
				for (const [telemKey, defaultTelem] of Object.entries(deviceDefaultTelem)) {
					for (const [metaKey, defaultMetaValue] of Object.entries(defaultTelem)) {
						// Keys are expected to be strings except for `translationValues` which is a map<string,string>
						if (metaKey === 'translationValues') {
							deviceTelem[telemKey][metaKey] = { ...defaultMetaValue, ...deviceTelem[telemKey][metaKey] }
						} else if (!deviceTelem[telemKey][metaKey]) {
							deviceTelem[telemKey][metaKey] = defaultMetaValue
						}
					}
				}
			}
		}
		return t
	},

	/** Gets all parameters previously queried for
	 * @return {Set}	Set of entity ids queried for
	 */
	getQueried(state) {
		return state.queried
	},

	/** Gets a set of all customerIDs that have default ID queried in the curretn state
	 * @returns {Set}
	 */
	getDefaultQueried(state) {
		return state.defaultQueried
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
