<template>
  <overlay
    :ref-name="displayRef"
    class="overlay"
  >
    <div
      class="overlay-content"
      @click="close"
    >
      <!-- Unacknowledged notifications table -->
      <template>
        <ruleTable
          v-if="unAckNotifications.length > 0"
          class="rule-table unacknowledged"
          :rows="unAckNotifications"
          :columns="unacknowledgedColumns"
          :title="$t('Unacknowledged Notifications')"
          :new-btn="showButton"
          :button-text="$t('Acknowledge')"
          :expand="true"
          :search-include="['details']"
          @createNew="updateAcks"
        >
          <template #acknowledge="row">
            <input
              v-model="ackState[row.UID].checked"
              type="checkbox"
            >
          </template>
          <template #expanded-content="row">
            <div class="extra-details">
              <div class="detail-column">
                <div class="extra-detail-label">
                  {{ $t(`First Notification`) }}
                </div>
                <div>
                  {{ row.earliest }}
                </div>
              </div>
              <div class="detail-column">
                <div class="extra-detail-label">
                  {{ $t(`Number Of Unacknowledged`) }}
                </div>
                {{ row.count }}
              </div>
              <div class="detail-column">
                <div class="extra-detail-label">
                  {{ $t(`Most Recent Notification`) }}
                </div>
                {{ row.mostRecent }}
              </div>
            </div>
          </template>
        </ruleTable>
      </template>

      <!-- All notifications Table -->
      <template>
        <ruleTable
          class="rule-table all-notifications"
          :rows="notifications"
          :columns="notificationColumns"
          :title="$t('All Notifications')"
          :search-include="['details', 'source']"
          :new-btn="false"
        >
          <!-- Block to display when talking about rule violations -->
          <template #details="row">
            <div
              v-if="row.source === 'rule_violation'"
              class="clickable rule-violation-details"
              @click="navToURL(`/rules?defaultSearch=${row.name}`)"
            >
              {{ row.details }}
            </div>
          </template>
        </ruleTable>
      </template>
    </div>
  </overlay>
</template>
<script>
import overlay from '@/components/templates/overlay.vue'
import ruleTable from '@/components/templates/table.vue'
import { getActiveUserID } from '@/helpers/jwt.js'
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

/** Notifications helps visualize current notification events assigned to the user.
 * Content is refreshed ever minute.
 *
 * Note currently only supports rule notifications, will need revisiting if different types are expected.
 *
 * There are two tables, the top one always displays notifications that require acknowledgement and have yet to be acknowledged
 * When there are no unacknowledged notifications the table will not appear
 *
 * The second table always shows the top 100 most recent notifications available to the user.
 *
 * It is setup to use the overlay feature to display all of its content
 *
 * Emits:
 * - unackCount: Number representing how many rows are present in the unacknowledged table
 * - close: Value-less event triggered when user clicks outside of content area and should close notification panel
 * @type {Vue}
 */
export default {
	components: { overlay, ruleTable },
	props: {
		/** Indicates what element notifications should be written over */
		displayRef: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			/** Raw notifications pulled in straight from API, used to trigger computed
			* @type {AmpNotification[]} */
			rawNotifications: [],
			/** Raw notifications pulled in straight from API, used to trigger computed
			 * @type {AmpNotification[]}
			*/
			rawUnacknowledged: [],
			/** Ack state holds the acknowledged state of each row to be used with the unacknowledged table
				checked: Boolean indicating if the row is checked
				id: Notification UID for referencing what is checked
				@type {Object<string,{checked:boolean,id:string,groupID:string,grouped:boolean}>}
			*/
			ackState: {},
			// Simple flag to flip back and forth to trigger reactivity
			showButton: false
		}
	},

	computed: {
		...mapGetters('Rules/',
			['getRules']),

		/** List of rule ID's that are not found in the store
		 * @returns {String[]}
		*/
		missingRules() {
			const rules = {}
			for (const n of this.rawNotifications) {
				if (this.getRules !== null) {
					if (!(n.notificationID in this.getRules)) {
						rules[n.notificationID] = 1
					}
				} else {
					rules[n.notificationID] = 1
				}
			}
			for (const n of this.rawUnacknowledged) {
				if (this.getRules !== null) {
					if (!(n.notificationID in this.getRules)) {
						rules[n.notificationID] = 1
					}
				} else {
					rules[n.notificationID] = 1
				}
			}

			return Object.keys(rules)
		},

		/** Shows the 50 most recent notifications each row holds:
		 * timestamp: <Formatted date/time string>
		 * source: Notification source string
		 * details: NotificationID
		 * grouped: Boolean indicating if it is part of a group
		 * unixTS: Unix timestamp
		 * @returns {{timestamp:string,source:string,details:string,grouped:Boolean,unixTS:number}[]}
		 */
		notifications() {
			if (this.rawNotifications === null) return []
			/** @type {{timestamp:string,source:string,details:string,name:string,grouped:Boolean,unixTS:number}[]} */
			const rows = []

			for (const n of this.rawNotifications) {
				rows.push({
					timestamp: new Date(n.created).toLocaleDateString(...this.dateSettings),
					source: n.source,
					details: `${this.$t('Rule Violated')}: ${this.getRuleName(n.notificationID)}`,
					name: this.getRuleName(n.notificationID),
					grouped: n.grouped,
					unixTS: n.created
				})
			}
			return rows
		},

		/** Shows all unacknowledged notifications.  Notifications in the same group are condensed down to 1 row
		 *  @returns {Object<string,string|number|boolean>[]}
		 */
		unAckNotifications() {
			if (this.rawUnacknowledged === null) return []
			/** @type {Object<string,string|number|boolean>[]} */
			const rows = []
			const groups = {}
			// Build out single notifications, while putting aside grouped ones
			for (const n of this.rawUnacknowledged) {
				if (n.grouped) {
					if (!(n.notificationID in groups)) {
						groups[n.notificationID] = []
					}
					groups[n.notificationID].push(n)
				} else {
					rows.push({
						UID: n.UID,
						name: this.getRuleName(n.notificationID),
						details: this.getRuleName(n.notificationID),
						unixTS: n.created,
						grouped: n.grouped
					})
				}
			}
			// Some quick and dirty aggregation
			for (const notificationGroup of Object.values(groups)) {
				let count = 0
				let earliest = notificationGroup[0].created
				let latest = notificationGroup[0].created
				for (const n of notificationGroup) {
					count += 1
					if (n.create < earliest) earliest = n.created
					if (n.created > latest) latest = n.created
				}

				// simple reference to shorten spam
				const n = notificationGroup[0]
				rows.push({
					UID: n.UID,
					groupID: n.notificationID,
					unixTS: n.created,
					source: n.source,
					details: this.getRuleName(n.notificationID),
					id: n.created,
					grouped: n.grouped,
					earliest: new Date(earliest).toLocaleDateString(...this.dateSettings),
					count: count,
					mostRecent: new Date(latest).toLocaleDateString(...this.dateSettings)
				})
			}
			return rows
		}
	},

	watch: {
		/** Whenever we are missing rules ask the store to load them */
		missingRules() {
			// Log timestamps so we don't pound the API to rapidly
			if (this.ruleRequestTimes === undefined) {
				this.ruleRequestTimes = {}
			}

			for (const rule of this.missingRules) {
				if (!(rule in this.ruleRequestTimes)) {
					this.ruleRequestTimes[rule] = 5612820540108 // Sun Nov 12 2147
				}
				if (new Date().getTime() + 2000 < this.ruleRequestTimes[rule]) {
					this.fetchSingleRule(rule)
					this.ruleRequestTimes[rule] = new Date().getTime()
				}
			}
		},

		/** Anytime a user selects a checkbox this indicates if the acknowledgement button should be visible */
		ackState: {
			handler() {
				for (const checked of Object.values(this.ackState)) {
					if (checked.checked === true) {
						this.showButton = true
						return
					}
				}
				this.showButton = false
			},

			deep: true
		},

		// When new unacknowledged are brought up, ensure we have an object to monitor if the checkbox is selected
		unAckNotifications() {
			// Emit number of rows so parent can decide if it wants to do anything
			this.$emit('unackCount', this.unAckNotifications.length)

			for (const row of Object.values(this.unAckNotifications)) {
				Vue.set(this.ackState, row.UID, {
					checked: false,
					id: row.UID,
					groupID: row.groupID,
					grouped: row.grouped
				})
			}
		}
	},

	created() {
		// Need to fetch all recent notifications from API
		this.refreshNotifications()
		// Then check them again every minute
		this.refreshNotificationsInterval = setInterval(this.refreshNotifications, 60000)

		// Define table columns for notifications
		// @ts-ignore
		this.notificationColumns = [
			{
				id: 'timestamp',
				text: 'translationString.Created',
				sortField: 'unixTS',
				sortDescending: true
			},
			{
				id: 'source',
				text: 'translationString.Source',
				sortField: 'source'
			},
			{
				id: 'details',
				text: 'translationString.Details',
				sortField: 'details'
			}
		]
		// Columns for unacknowledged
		// @ts-ignore
		this.unacknowledgedColumns = [
			{
				id: 'mostRecent',
				text: 'translationString.Most Recent',
				sortField: 'unixTS',
				sortDescending: true
			},
			{
				id: 'details',
				text: 'translationString.Name (If Exists)',
				sortField: 'details'
			},
			{
				id: 'acknowledge',
				text: 'translationString.Acknowledge',
				sortField: 'acknowledged'
			}
		]

		// Display settings for date time strings
		this.dateSettings = [
			this.$i18n.locale,
			{
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit'
			}
		]
	},

	destroyed() {
		clearInterval(this.refreshNotificationsInterval)
	},

	methods: {
		...mapActions('Rules/', ['fetchSingleRule']),

		/** Returns a formatted rule name to account for undefined an null */
		getRuleName(ruleID) {
			if (this.getRules === null) { return 'Loading' }
			const temp = this.getRules[ruleID]?.name
			if (temp === undefined) { return '' }
			return temp
		},

		// Refresh both notification lists from API
		refreshNotifications() {
			this.axios.get(`/notifications/v1/notifications/${getActiveUserID()}/50`,
				{ headers: { Authorization: localStorage.getItem('authToken') } })
				.then(response => {
					if (response.data.notification) {
						Vue.set(this, 'rawNotifications', response.data.notification)
					} else {
						Vue.set(this, 'rawNotifications', [])
					}
				})
				.catch((error) => {
					console.error('Error fetching notifications', error)
				})

			// Fetch all unacknowledged notifications
			this.axios.get(`/notifications/v1/unacknowledged/${getActiveUserID()}`,
				{ headers: { Authorization: localStorage.getItem('authToken') } })
				.then(response => {
					if (response.data.notification) {
						Vue.set(this, 'rawUnacknowledged', response.data.notification)
					} else {
						Vue.set(this, 'rawUnacknowledged', [])
					}
				})
				.catch((error) => {
					console.error('Error fetching notifications', error)
				})
		},

		/** When user selects multiple unacknowledged rows and hits Acknowledge button
 * This method bundles it up and sends the request to API
 */
		updateAcks() {
			const singleN = []
			const groupN = []
			for (const [key, row] of Object.entries(this.ackState)) {
				if (row.checked) {
					if (row.grouped) {
						groupN.push(row.groupID)
					} else {
						singleN.push(Number(key))
					}
				}
			}
			this.axios.put('/notifications/v1/notifications/ack',
				{
					userID: getActiveUserID(),
					ackNotificationList: singleN,
					ackNotificationGroups: groupN
				},
				{ headers: { Authorization: localStorage.getItem('authToken') } })
				.then(response => {
					this.refreshNotifications()
				})
				.catch((error) => {
					console.error('Error fetching notifications', error)
				})
		},

		close(event) {
			if (event.target === event.currentTarget) {
				this.$emit('close')
			}
		}
	}

}
</script>
<style lang="scss" scoped>
.overlay {
	display: flex;
	justify-content: center;
}
.overlay-content {
	flex-grow: 1;
    margin: 0px auto;
    max-width: 1300px;
    padding: 50px;
}
.rule-table{
	max-height: 40%;
	overflow: auto;
	text-align: center;
	margin-top: 20px;
}
.unacknowledged{
	max-height: 40%;
}
.all-notifications{
	max-height: 40%;
}

.rule-violation-details{
	font-weight: bold;
	text-decoration: underline;
}

.extra-detail-label{
	font-weight: bold;
	text-decoration: underline;
}
.detail-column{
	flex: 1 1 auto;
}
.extra-details{
	display: flex;
}
</style>
