
import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import { getActiveUserCustomerID, getActiveUserRole } from '@/helpers/jwt.js'

Vue.use(VueRouter)

// Lazy loading
function loadView(view) {
	return () => import(`@/views/${view}.vue`)
}

/** Vue route mapping
 *  A route's meta can contain the following:
 * 	@param {String} 	title 			- title of page to be displayed in browser tab
 * 	@param {Boolean} 	authNeeded		- if false, no authorization is needed to access page
 * 	@param {String} 	permission		- name of permission needed to access page
*/
const routes = [
	{
		path: '*',
		redirect: 'home',
		meta: {
			title: 'AMP: IoT Demo',
			authNeeded: true
		}
	},
	{
		path: '/login',
		name: 'login',
		props: true,
		component: loadView('Login'),
		meta: {
			title: 'AMP: IoT Demo',
			authNeeded: false
		}
	},
	{
		path: '/contact-us',
		name: 'contact',
		component: loadView('ContactUs'),
		meta: {
			title: 'AMP: Contact Us',
			authNeeded: true
		}
	},
	{
		path: '/forgot-password',
		name: 'forgot',
		component: loadView('Forgot'),
		meta: {
			title: 'AMP: Forgot Password',
			authNeeded: false
		}
	},
	{
		path: '/home',
		name: 'home',
		component: loadView('Home'),
		meta: {
			title: 'AMP: Home',
			authNeeded: true
		}
	},
	{
		path: '/dashboard/:propID?',
		component: loadView('Dashboard'),
		name: 'dashboard',
		props: true,
		meta: {
			title: 'AMP: Dashboard',
			authNeeded: true
		}
	},
	{
		path: '/reset-password/:token?',
		name: 'reset',
		component: loadView('Reset'),
		props: true,
		meta: {
			title: 'AMP: Reset Password',
			authNeeded: false
		}
	},
	{
		path: '/my-account',
		name: 'my-account',
		component: loadView('MyAccount'),
		meta: {
			title: 'AMP: Account Settings',
			authNeeded: true
		}
	},
	{
		path: '/engineer',
		name: 'engineer',
		component: loadView('Engineer'),
		meta: {
			title: 'AMP: Deep Chart',
			permission: 'Deep Chart Page',
			authNeeded: true
		}
	},
	{
		path: '/blog',
		name: 'blog',
		component: loadView('Blog'),
		meta: {
			title: 'AMP: Blog',
			authNeeded: true
		}
	},
	{
		path: '/blog/:auth',
		name: 'openBlog',
		component: loadView('Blog'),
		props: true,
		meta: {
			title: 'AMP: Blog',
			authNeeded: true
		}
	},
	{
		path: '/admin',
		component: loadView('Admin'),
		name: 'admin',
		meta: {
			title: 'AMP: Admin',
			permission: 'Admin Page',
			authNeeded: true
		}
	},
	{
		path: '/analytics/:deviceId?',
		component: loadView('Analytics'),
		name: 'analytics',
		props: true,
		meta: {
			title: 'AMP: Analytics',
			permission: 'Device Data Page',
			authNeeded: true
		}
	},
	{
		path: '/help',
		name: 'help',
		component: loadView('Help'),
		meta: {
			title: 'AMP: Help',
			authNeeded: true
		}
	},
	{
		path: '/signup/:jwt',
		name: 'signup',
		component: loadView('SignUp'),
		meta: {
			title: 'AMP: Sign Up',
			authNeeded: false
		}
	},
	{
		path: '/rules',
		name: 'rules',
		component: loadView('Rules'),
		meta: {
			title: 'AMP: Rules',
			permission: 'Rules Page',
			authNeeded: true
		}
	},
	{
		path: '/reports',
		name: 'reports',
		component: loadView('Report'),
		meta: {
			title: 'AMP: Report',
			authNeeded: true
		}
	},
	{
		path: '/jobs',
		name: 'jobs',
		component: loadView('Jobs'),
		meta: {
			title: 'AMP: Jobs',
			authNeeded: true
		}
	},
	{
		path: "/download",
		name: "downoad",
		component: loadView("Download"),
		meta:{
			title: "AMP: Download",
			authNeeded:true
		}
	},
	{
		path: '/job/:propID?',
		component: loadView('Job'),
		name: 'job',
		props: true,
		meta: {
			title: 'AMP: Job Data',
			authNeeded: true
		}
	},
]

/** Initializing the Vue Router */
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

/** Method run before loading URL
 * Used to authenticate user and validate they have permission to access content
 * Supports a variety of paths:
 * - If the route does not need authorization, move right along
 * - If the route for the blog w/ JWT appended to URL is triggered, it is handled separately by saving the JWT to local storage
 * - If newly authenticated from /login, router will redirect automatically to /home
 * - If non login page lacks jwt authentication, redirected to /login. After user logs in, they will be brought to previously un-authenticated page
 * - If user lacks permissions to load a url (which should never happen organically), redirect to /home
 */
export const beforeEachGuard = (to, from, next) => {
	// Loading permformance measurement for google analytics
	window.performance.clearMeasures() // Clear measures in case they were not used yet, prevent memory overflow
	window.performance.mark('pageLoadStart')

	if (to.name === 'openBlog') {
		// Blog/* is a special case and can have a jwt passed in
		localStorage.setItem('authToken', to.path.split('blog/')[1])
		next({ name: 'blog' })
		return
	}
	if (to.name === 'signup') {
		localStorage.setItem('authToken', to.path.split('signup/')[1])
		next()
		return
	}
	if (!to.meta.authNeeded) {
		// if no authorization is needed to load page, go ahead and load it
		next()
		return
	}
	// page requires auth - check if user has a valid JWT and valid permissions
	Promise.all(
		[validJWT(), validPermission(to.meta.permission)] // check for jwt + permissions at the same time
	).then((values) => { 					// promise.all returns array of corresponding values
		const validJWT = values[0] 			// is user authorized?
		const validPermission = values[1] 	// does user have permission to access page?
		if (!validJWT) {
			// user is not logged in. Route them to login
			next({
				name: 'login', // Must redirect with name to support passing in params(props)
				params: { targetURL: to.fullPath } // full path includes params
			})
		} else if (!validPermission) {
			// user is logged in but doesn't have access to page. Route them back to printers page
			next({ name: 'home' })
		} else {
			// user is logged in and has access to page
			next()
		}
	}).catch(error => {
		console.log('Error validating user for JWT + Permissions:', error)
	})
}

/** validate a user's JWT authToken
 * @returns {Promise} return a promise with a boolean result value, true if JWT is valid, false if not
 */
function validJWT() {
	if (localStorage.getItem('authToken') === null) {
		return Promise.resolve(false)
	}
	return axios
		.post(
			'/api/v1/users/authorize',
			{},
			{
				headers: {
					Authorization: localStorage.getItem('authToken')
				}
			}
		)
		.then(() => {
			return true
		})
		.catch(error => {
			console.log('Error authorizing user:', error)
			return false
		})
}

/** validates user's permission to access page based on their role + customer group
 * @returns {Promise} return a promise with a boolean result value, true if permission is valid, false if not
 */
function validPermission(permissionName) {
	if (localStorage.getItem('authToken') === null) {
		// if there is no auth token in local storage, there's no way permission is valid
		return Promise.resolve(false)
	}
	if (permissionName === undefined) {
		// we don't need a permission check, return true
		return Promise.resolve(true)
	}

	// parse JWT in local storage to get role and customer ID
	const role = getActiveUserRole()
	const customerID = getActiveUserCustomerID()

	if (role === 1) {
		// if user is an admin, they automatically have permission
		return Promise.resolve(true)
	}

	// query list of permissions for role + customer id. See if there is a match, return corresponding bool
	return axios
		.get('/api/v1/role-permissions/roles/' + role + '/customers/' + customerID + '/permissions', {
			headers: {
				Authorization: localStorage.getItem('authToken')
			}
		})
		.then(({ data }) => {
			return data.permissions.find(permission => permission.name === permissionName) !== undefined
		})
		.catch(error => {
			console.log('Error finding permissions:', error)
			return false
		})
}

router.beforeEach(beforeEachGuard)

/** Once URL has changed this runs to update window title */
router.afterEach((to, from) => {
	window.performance.mark('pageLoadFinish')
	window.performance.measure('pageLoadTime', 'pageLoadStart', 'pageLoadFinish')
	window.performance.clearMarks()
	// call window.performance.clearMeasures() when you access the value
	// Change window name
	const defaultTitle = 'AMP: IoT Demo'
	document.title = to.meta.title || defaultTitle
})

export default router
