<template>
  <div id="app">
    <!-- Header at the top of every page -->
    <BwHeader
      :authenticated="authenticated"
      :show-menu="showMenu && authenticated"
      :unack-count="unackCount"
      @show-menu="x => showMenu = x"
      @notification-click="displayNotifications = !displayNotifications"
    />

    <div class="app-container">
      <!-- Side nav that toggles in and out on left side of page -->
      <SideNav
        class="side-nav"
        :show-menu="showMenu && authenticated"
        :authenticated="authenticated"
        :blog-titles="blogTitles"
        :help-titles="helpTitles"
        @nav-show-menu="x => showMenu = x"
      />

      <!-- Main page content -->
      <div
        ref="main-root-element"
        class="main"
      >
        <!-- Target for elements to override main contents -->
        <PortalTarget name="root-portal-target" />
        <!--
          Notifications can be displayed over the entire page for the time being
          Important that is uses v-show, so that it is able to inform users when something changes
        -->
        <notifications
          v-show="displayNotifications"
          display-ref="main-root-element"
          @close="displayNotifications = false"
          @unackCount="x => unackCount = x"
        />
        <!--
          If the active page is `blog` or `help`, they will emit a list of titles to
          display in the sidebar nav. They will only emit once on creation
        -->
        <router-view
          @blog-md="setBlogTitles"
          @help-md="setHelpTitles"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BwHeader from '@/components/global_page_header.vue'
import { PortalTarget } from 'portal-vue'
import notifications from '@/components/notifications.vue'
import SideNav from '@/components/global_side_bar.vue'
import Vue from 'vue'

export default {
	components: { PortalTarget, BwHeader, SideNav, notifications },
	/**
	 * @prop {Boolean} showMenu			- Tell the side nav menu to appear (true) or hide (false)
	 * @prop {Boolean} authenticated 	- Whether the user is authenticated, controls display of menu + header content
	 */
	data() {
		return {
			showMenu: false, // Note, is ANDed with authenticated in side-nav prop, to assure menu never shows when user is not authenticated
			authenticated: false,
			blogTitles: [],
			helpTitles: [],
			// Indicates if the notification overlay should be visible
			displayNotifications: false,
			// Used to track how many unacknowledged notifications their are, color changes circle
			unackCount: 0
		}
	},

	watch: {
		/** Anytime the route changes, check if user is authenticated and remove notification overlay */
		$route: function(to, from) {
			this.authenticated = localStorage.getItem('authToken') !== null && to.name !== 'login' && to.name !== 'signup'
			this.displayNotifications = false
		}
	},

	created() {
		// Check if user is authenticated, watcher will take over after this init
		this.authenticated = localStorage.getItem('authToken') !== null &&
			this.$route.name !== 'login' &&
			this.$route.name !== 'signup' &&
			this.$route.path !== '/' // Special case - handle redirects towards login
		this.$t = () => 'WHLKJ'
	},

	methods: {
		/** Set blogTitles to the recieved array of strings (blog document titles) */
		setBlogTitles(x) {
			Vue.set(this, 'blogTitles', x)
		},

		/** Set helpTitles to the recieved array of strings (manual document titles) */
		setHelpTitles(x) {
			Vue.set(this, 'helpTitles', x)
		}
	}
}
</script>

<style lang="scss">
#app {
	height: 100%;
	font-family: 'Roboto Condensed';
	overflow: hidden;
}
.app-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	height: 100%;
}
.side-nav {
	flex-shrink: 0;
}
.main {
	width: 100%;
    height: calc(100% - 70px);
}
</style>
