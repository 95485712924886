import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
import { bisector } from 'd3-array'

/** Checks the current dashboard widgets and creates a name that does not collied with given object
 * @param {Object<string,Object>} widgets - Widget keys are expected to be the top level key
*/
function makeWidgetID(widgets) {
	let counter = 0
	while (`customWidget${counter}` in widgets) counter += 1
	return `customWidget${counter}`
}

/** Dashboard state information
 * @typedef {Object} DashboardStore
 * @prop {String} 								editingWidget 					- WidgetID indicating what widget is being edited. Empty means nothing is being edited
 * @prop {Object<string, string>} 				activeDeviceModel 				- Model key pointing to a device ID. Dashboard can have an active device per model type
 * @prop {Number} 								activeMachinePosition 			- Integer indicating what position of the machine is being viewed
 * @prop {String|null} 							activeTabID 					- tabID that is currently active on the dashboard
 * @prop {DashboardConfig} 						dashboardConfig 				- Full dashboard config
 * @prop {Object<string,number>}				lineChartTelemetry 				- DeviceID points to a timestamp of the last time that device was updated
 * @prop {Object<string,number>} 				bulkAddTimestamps 				- <deviceID>:<Timestamp MS>. Lookup table indicating last time a devices bulk data was updated
 * @prop {number} 								numberOfLineChartObservations 	- Number of data points that should be stored in line chart data
 * @prop {number} 								DASHBOARD_TRANSITION_LENGTH 	- Sets the base line length of transitions in ms. Use 1000ms for smooth, 0ms for performance
 */

/** Contains all data to be used by line chart telemetry
 * This can be a very large data structure so it is kept outside the state to avoid reactive performance costs
 * DeviceID as keys point to arrays of telemetry observations
 * @type {Object<string,DashboardTelemetry[]>}
 */
const lineChartTelemetry = {}

/** Instantiates a new object consisting of the dashboard.store state
 * @returns {DashboardStore}
*/
export const getDefaultDashboardState = () => {
	return {
		editingWidget: '',
		activeDeviceModel: {},
		activeMachinePosition: -1,
		activeTabID: null,
		dashboardConfig: {},
		lineChartTelemetry: {},
		bulkAddTimestamps: {},
		numberOfLineChartObservations: 300,
		DASHBOARD_TRANSITION_LENGTH: 1000
	}
}

export const storeState = getDefaultDashboardState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {
	/** Generators */
	addTopWidget({ commit, getters }) {
		// Find a valid UID
		const widgetName = makeWidgetID(getters.getWidgets)
		const w = {
			name: 'Widget Name',
			telemKeys: [],
			type: 'simple',
			configChanges: {
				label: 'Label'
			}
		}

		commit('_addWidget', {
			activeTabID: getters.getActiveTabID,
			widgetID: widgetName,
			widget: w
		})

		commit('_addTopWidget', {
			activeTabID: getters.getActiveTabID,
			widgetName: widgetName
		})
	},
	/** Creates a new default widget row on the side indicated by the parameter.
	 * It then adds the new row to the store
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Boolean} left		- True indicates we are modifying the left column, right otherwise
	 */
	addWidgetRow({ commit, getters }, left) {
		if (left) {
			// need two uids
			const leftWidgetName = makeWidgetID(getters.getWidgets)

			// Add the widget objects
			commit('_addWidget', {
				activeTabID: getters.getActiveTabID,
				widgetID: leftWidgetName,
				widget: {
					configChanges: {},
					name: 'Widget Name',
					telemKeys: [],
					type: 'multilinechart'
				}
			})
			const rightWidgetName = makeWidgetID(getters.getWidgets)
			commit('_addWidget', {
				activeTabID: getters.getActiveTabID,
				widgetID: rightWidgetName,
				widget: {
					configChanges: {},
					name: 'Widget Name',
					telemKeys: [],
					type: 'minimalgauge'
				}
			})
			// Update display row
			commit('_addWidgetRow', {
				activeTabID: getters.getActiveTabID,
				left: true,
				row: {
					leftWidget: leftWidgetName,
					rightWidget: rightWidgetName,
					name: 'New Widget'
				}
			})
		} else {
			const widgetName = makeWidgetID(getters.getWidgets)
			// Add the widget object
			commit('_addWidget', {
				activeTabID: getters.getActiveTabID,
				widgetID: widgetName,
				widget: {
					configChanges: {},
					name: 'Widget Name',
					telemKeys: [],
					type: 'radarchart'
				}
			})
			// Update display row
			commit('_addWidgetRow', {
				activeTabID: getters.getActiveTabID,
				left: false,
				row: {
					name: 'New Widget',
					widget: widgetName
				}
			})
		}
	},
	/** Using the passed in dashboard config all meta data is populated, then the whole config is set into the store
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {DashboardConfig} dashboardConfig - Dashboard config object
	 */
	addDashboard({ commit, getters, rootGetters }, dashboardConfig) {
		// Generating a deep copy allows us to manipulate the passed in object even if it is coming from the state already
		dashboardConfig = JSON.parse(JSON.stringify(dashboardConfig))
		// Once we know the dashboard we need to determine what machine positions have devices, create the position map for each tab
		// Initialize an empty map on each tab, to be used to track positioning
		for (const tab of Object.values(dashboardConfig)) {
			tab.positionMapping = {}
		}
		// Populate the mapping based on devices found on the machine
		for (const device of Object.values(rootGetters['Devices/getDevices'])) {
			if (device.machineID === rootGetters['Machines/getMachineActive'].id) {
				// Runs in n^2 but it will always be very small and infrequently used
				//  and this form makes it easier to reason with, rather then lookup tables
				for (const tab of Object.values(dashboardConfig)) {
					if (device.model === tab.deviceModel) {
						tab.positionMapping[device.machinePosition] = device.id
					}
				}
			}
		}
		commit('setDashboardConfig', dashboardConfig)
		// Ensure the active tab still exists, otherwise set it to the left most one
		if (!(getters.getTabDisplayOrder.includes(getters.getActiveTabID))) {
			commit('_setActiveTabID', getters.getTabDisplayOrder[0])
		}

		// Find the largest needed time for any line chart widgets
		let max = 300 // most common default
		for (const config of Object.values(dashboardConfig)) {
			for (const chartWidget of Object.values(config.chartWidgets)) {
				if (chartWidget.type === 'multilinechart') {
					if (chartWidget?.configChanges?.collapsedLimit > max) {
						max = chartWidget.configChanges.collapsedLimit
					}
					if (chartWidget?.configChanges?.expandedLimit > max) {
						max = chartWidget.configChanges.expandedLimit
					}
				}
			}
		}
		commit('setNumberOfLineChartObservations', max)

		// Ensure the machine position is valid, if not set it
		if (!(getters.getDevicePositions.includes(getters.getActiveMachinePosition))) {
			commit('_setActiveMachinePosition', Math.min(...Object.keys(getters.getActiveTab.positionMapping).map(Number)))
		}

		// If active position and tab id are embedded in url, use them:
		if (getters.getDevicePositions.includes(+router.currentRoute.query.activePosition)) {
			commit('_setActiveMachinePosition', +router.currentRoute.query.activePosition)
		}
		if (getters.getTabDisplayOrder.includes(router.currentRoute.query.activeTab)) {
			commit('_setActiveTabID', router.currentRoute.query.activeTab)
		}
		// embed new state in URL:
		if (router.app !== null) {	// Prevents the unit tests inside dashboard.store from failing from failing
			// @ts-ignore
			router.app.updateQueryParamsWithMap({
				activePosition: getters.getActiveMachinePosition,
				activeTab: getters.getActiveTabID
			})
		} else {
			// I am unsure on the outcome of this in practical use, but it allows the unit tests to work without having Vue inside the router object
			router.currentRoute.query.activePosition = getters.getActiveMachinePosition
			router.currentRoute.query.activeTab = getters.getActiveTabID
		}
	},
	/** Setters */
	/** Sets the active device associated with the given model
	 * If the passed in device's model does not correspond to the model given nothing is set
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object} p
	 * @param {String} p.model 		- Model of device being changed
	 * @param {String} p.deviceID 	- ID of the device to be set to the model
	 */
	setActiveModelDevice({ rootGetters, commit }, { model, deviceID }) {
		if (model === rootGetters['Devices/getDevices'][deviceID].model) {
			commit('_setActiveModelDevice', { model: model, deviceID: deviceID })
		}
	},

	/** Updates config file for dashboard based on passed in settings
	 * @param {import('vuex').ActionContext<typeof storeState>} _
	 * @param {Object} 		p
	 * @param {String} 							p.type 				- Type of widget, multilinechart, gauge, etc
	 * @param {Object<string,string|number>} 	p.chartSettings 	- key value pairs of chart settings, varies by widget type
	 * @param {String} 							p.containerLabel 	- String displayed on widget container
	 * @param {String[]} 						p.telemKeys 		- List of telemetry keys to be used by widget
	 * @param {String} 							p.dataSource 		- Data source type being used by widget
	 * @param {String} 							p.dataSourceDetails	- DeviceID corresponding to a user selection and dataSource
	*/
	setWidgetConfig({ commit, getters }, { type, chartSettings, containerLabel, telemKeys, dataSource, dataSourceDetails }) {
		// Update dashboard config fully
		const fullConfig = {
			configChanges: chartSettings,
			telemKeys: telemKeys,
			type: type,
			dataSource: dataSource,
			dataSourceDetails: dataSourceDetails
		}
		if (type === 'simple') {
			commit('_setWidgetConfig', { activeTabID: getters.getActiveTabID, widgetID: getters.getEditingWidgetID, config: fullConfig })
			commit('setEditingWidget', '')
			return
		}

		const widgetID = getters.getEditingWidgetID
		let leftSide = false
		let widgetRow = -1
		// Find the side and row that this widgetID corresponds to
		for (const [index, row] of Object.entries(getters.getActiveTab.leftColumn)) {
			if (row.leftWidget === widgetID || row.rightWidget === widgetID) {
				leftSide = true
				widgetRow = Number(index)
				break
			}
		}
		if (widgetRow === -1) {
			for (const [index, row] of Object.entries(getters.getActiveTab.rightColumn)) {
				if (row.widget === widgetID) {
					leftSide = false
					widgetRow = Number(index)
					break
				}
			}
		}
		commit('_setWidgetConfig', { activeTabID: getters.getActiveTabID, widgetID: widgetID, config: fullConfig })
		commit('setWidgetLabel', { activeTabID: getters.getActiveTabID, newText: containerLabel, left: leftSide, index: widgetRow })
		commit('setEditingWidget', '')
	},

	/** Sets the currently active machine position. If it does not exist then machine position will not change
	 * Also updates activePosition in URL if the position exists
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Number} position	- New position on the machine to use
	 */
	setActiveMachinePosition({ commit, getters }, position) {
		if (getters.getDevicePositions.includes(position)) {
			commit('_setActiveMachinePosition', position)
			// embed new state in URL:
			if (router.app !== null) {	// Prevents the unit tests inside dashboard.store from failing from failing
				// @ts-ignore
				router.app.updateQueryParamsWithMap({ activePosition: position })
			} else {
				// I am unsure on the outcome of this in practical use, but it allows the unit tests to work without having Vue inside the router object
				router.currentRoute.query.activePosition = `${position}`
			}
		}
	},
	/** Sets the currently active tab id. If it does not exist then tab id will not change
	 * Also updates tabID in the url if the tab exists
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String} tabID - ID of tab to be set as active
	 */
	setActiveTabID({ commit }, tabID) {
		commit('_setActiveTabID', tabID)
		// embed new state in URL:
		if (router.app !== null) {	// Prevents the unit tests inside dashboard.store from failing from failing
			// @ts-ignore
			router.app.updateQueryParamsWithMap({ activeTab: tabID })
		} else {
			// I am unsure on the outcome of this in practical use, but it allows the unit tests to work without having Vue inside the router object
			router.currentRoute.query.activeTab = tabID
		}
	},

	/** Remove */
	/** Removes the given tab from the dashboard config.
	 * Ensures that there is always at least one tab on the dashboard.
	 * Ensures that if the active tab is deleted the state is updated to activate a new tab
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String} tabID - ID of the tab to be removed
	 */
	removeTab({ commit, dispatch, getters }, tabID) {
		// If this is the last tab we need an empty place holder
		if (getters.getTabDisplayOrder.length === 1) commit('addDashboardConfigTab')

		// If this is the active tab we need to activate a different one
		if (tabID === getters.getActiveTabID) {
			for (const tab of getters.getTabDisplayOrder) {
				if (tab !== tabID) {
					dispatch('setActiveTabID', tab)
				}
			}
		}
		// State is now ready to remove the tab
		commit('_removeTab', tabID)
		// Refresh the dashboard so all auto generated meta data is included
		dispatch('addDashboard', getters.getDashboardConfig)
	},
	/** Others */
	/** Given the dashboard ID this function takes the current dashboard and attempts to save it to the DB
	 * This function will always use the currently active machineID and the configID passed in.
	 * First it will attempt to update an already existing entity, if that fails it tries to make a new one.
	 *
	 * IMPORTANT: This function will wait for all API promises to be finished before exiting
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String} dashboardID 	- ID of dashboard to be used when attempting to save
	 *
	 * @returns {Promise}	- Promise will be in failure state if all API requests failed
	 */
	async updateDashboardConfig({ getters, rootGetters }, dashboardID) {
		let savingFailed = false
		const body = {
			dashboard_id: dashboardID,
			machine_id: rootGetters['Machines/getMachineActive'].id,
			dashboard_config: getters.getDashboardConfig
		}
		await axios.put(`/api/v1/machines/${body.machine_id}/dashboards/${body.dashboard_id}`, body, {
			headers: {
				Authorization: localStorage.getItem('authToken')
			}
		})
			.catch(error => {
				savingFailed = true
				console.log("Dashboard didn't exist, creating one", error)
			})
		if (savingFailed) {
			await axios.post('/api/v1/dashboards', body, {
				headers: {
					Authorization: localStorage.getItem('authToken')
				}
			})
				.then(data => {
					savingFailed = false
				})
				.catch(createConfigError => {
					console.log('Problem saving dashboard config to DB:', createConfigError)
				})
		}
		if (savingFailed) return Promise.reject(new Error('Unable to save dashboard'))
	},
	/** Pulls down the requested dashboard from the API
	 * When the config is pulled down it gets loaded into the store
	 *
	 * IMPORTANT: Error state is not handled here, instead it is expected the caller handles it.
	 * This returns the promise generated by axios
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object} p
	 * @param {String} p.userID		- Value used as the dashboard config ID
	 * @param {string} p.machineID	- Value used as the machineID in DB
	 *
	 * @returns {Promise} Axios promise object, with the error state not handled
	 */
	fetchDashboardConfig({ dispatch, commit, getters }, { userID, machineID }) {
		return axios.get(`/api/v1/machines/${machineID}/dashboards/${userID}`, {
			headers: { Authorization: localStorage.getItem('authToken') }
		})
			.then(response => {
				dispatch('addDashboard', response.data.dashboard.dashboard_config)
				if (getters.getDevicePositions.includes(+router.currentRoute.query.activePosition)) {
					commit('_setActiveMachinePosition', +router.currentRoute.query.activePosition)
				} else {
					commit('_setActiveMachinePosition', Math.min(...Object.keys(getters.getActiveTab.positionMapping).map(Number)))
				}
			})
			// Note errors are not handled here as they are expected to be handled by the caller
	},
	/** Loads the users personal dashboard from API if it exists
	 *
	 * @param {import('vuex').ActionContext<typeof storeState>} _
	 * @param {String} machineID
	*/
	fetchPersonalDashboardConfig({ dispatch }, machineID) {
		// First need the userUID
		const userID = JSON.parse(atob(localStorage.getItem('authToken').split('.')[1])).UserId // TODO atob depreciated ?

		return dispatch('fetchDashboardConfig', { userID: userID, machineID: machineID })
	},

	/** Loads the default dashboard for the machine if it exists
	 * @param {import('vuex').ActionContext<typeof storeState>} _
	 * @param {String} machineID
	 */
	async fetchDefaultDashboardConfig({ dispatch }, machineID) {
		return dispatch('fetchDashboardConfig', { userID: machineID, machineID: machineID })
	},

	/** This function loads the dashboard config from the DB.
	 * It goes in order User specific config -> Machine default config -> Generic empty local config.
	 * If a previous API call fails the next one is tried
	 * This function does not return until all API calls have been tried and failed, or one succeeded.
	 *
	 * @param {import('vuex').ActionContext<typeof storeState>} _
	 * @param {String} machineID
	 */
	async fetchPriorityDashboardConfig({ dispatch, commit, getters }, machineID) {
		dispatch('Machines/setActiveMachineID', machineID, { root: true })

		let dashboardLoaded = true

		await dispatch('fetchPersonalDashboardConfig', machineID).catch(error => {
			console.log('Unable to load user dashboard config', error)
			dashboardLoaded = false
		})
		if (!dashboardLoaded) {
			dashboardLoaded = true
			await dispatch('fetchDefaultDashboardConfig', machineID)
				.catch(error => {
					console.log('Unable to fetch default dashboard:', error)
					dashboardLoaded = false
				})
		}

		if (!dashboardLoaded) {
			console.error('Loading empty dashboard. No remote dashboards found:')
			const dashboard = {
				default: { tabName: 'Empty Dashboard', deviceModel: 'XP9', tabOrderPosition: 3, leftColumn: [], rightColumn: [], chartWidgets: {} }
			}
			dispatch('addDashboard', dashboard)
			commit('_setActiveMachinePosition', Math.min(...Object.keys(getters.getActiveTab.positionMapping).map(Number)))
		}
	}
}
/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
	/** Sets the max size of allowed telemetry observations to be kept for streaming purposes
	 * Enforces a max history size of 8 hours.
	 * @param {number} n - New value to be assigned
	 */
	setNumberOfLineChartObservations(state, n) {
		if (n > 28800) n = 28800
		Vue.set(state, 'numberOfLineChartObservations', n)
	},
	/** Flips the dashboard into optimization or pretty mode
	 *
	 * @param {Boolean} enable - True turns on optimization mode
	 */
	setOptimizeMode(state, enable) {
		if (enable) {
			Vue.set(state, 'DASHBOARD_TRANSITION_LENGTH', 0)
		} else {
			Vue.set(state, 'DASHBOARD_TRANSITION_LENGTH', 1000)
		}
	},
	/** Add new set of data from the API. Updating timestamps for that device
	 *
	 * @param state
	 * @param {Object} p
	 * @param {String} p.deviceID 					- Device being updated
	 * @param {DashboardTelemetry[]} p.telemetry 	- Telemetry to be assigned
	 */
	addBulkHistoricalData(state, { deviceID, telemetry }) {
		Vue.set(state.bulkAddTimestamps, deviceID, new Date().getTime())
		lineChartTelemetry[deviceID] = telemetry
		Vue.set(state.lineChartTelemetry, deviceID, new Date().getTime())
	},
	/** Add a single telemetry observation
	 *
	 * @param state
	 * @param {Object} p
	 * @param {String} 					p.deviceID 	- ID of device
	 * @param {Object<string,number>} 	p.telemetry - Telemetry to add to the end of the line
	 * @param {Number}			 		p.timestamp - UNix timestamp for telemetry
	 */
	addTelemetryMessage(state, { deviceID, telemetry, timestamp }) {
		if (!(deviceID in lineChartTelemetry)) {
			lineChartTelemetry[deviceID] = []
		}
		lineChartTelemetry[deviceID].push({
			telemetry: telemetry,
			time: timestamp
		})
		// To save performance only prune excess telemetry every 5 minutes worth of data
		if (lineChartTelemetry[deviceID].length > state.numberOfLineChartObservations + 300) {
			const diff = lineChartTelemetry[deviceID].length - state.numberOfLineChartObservations
			lineChartTelemetry[deviceID] = lineChartTelemetry[deviceID].slice(diff, lineChartTelemetry[deviceID].length)
		}
		Vue.set(state.lineChartTelemetry, deviceID, new Date().getTime())
	},

	/** Creates a new tab on the dashboard config, populating it with empty defaults */
	addDashboardConfigTab(state) {
		// tabUID needs to be unique, but no need to get fancy about it
		let tabNum = 0
		while (`customTab${tabNum}` in state.dashboardConfig) tabNum += 1
		const tabUID = `customTab${tabNum}`

		const newTab = {
			chartWidgets: {},
			deviceModel: '',
			leftColumn: [],
			rightColumn: [],
			tabName: 'New Tab',
			tabOrderPosition: -1
		}
		Vue.set(state.dashboardConfig, tabUID, newTab)
	},
	/** Adds the passed in widget to the store
	 * @param state
	 * @param {Object} p
	 * @param {String} p.activeTabID	- tabID that the widget is added to
	 * @param {String} p.widgetID		- ID to be attached to the widget and used as key
	 * @param {Object} p.widget			- Full widget object to put added to the state
	 */
	_addWidget(state, { activeTabID, widgetID, widget }) {
		Vue.set(state.dashboardConfig[activeTabID].chartWidgets, widgetID, widget)
	},
	/** Adds the passed in widget row to the store
	 * @param state
	 * @param {Object}  p
	 * @param {String}  p.activeTabID 	- tabID that the widget is added to
	 * @param {Boolean} p.left			- True indicates we are modifying th left column, right otherwise
	 * @param {Object}  p.row			- Row object to be added to the array
	 */
	_addWidgetRow(state, { activeTabID, left, row }) {
		let side = 'rightColumn'
		if (left) side = 'leftColumn'
		state.dashboardConfig[activeTabID][side].unshift(row)
	},
	/** Adds a widget to the top section of widgets
	 * @param state
	 * @param {Object} p
	 * @param {String} p.activeTabID -Tab UID to add widget to
	 * @param {String} p.widgetName - ID of widget to set in place
	 */
	_addTopWidget(state, { activeTabID, widgetName }) {
		if (state.dashboardConfig[activeTabID].top === undefined) { Vue.set(state.dashboardConfig[activeTabID], 'top', []) }
		state.dashboardConfig[activeTabID].top.push(widgetName)
	},
	/** Setters */
	/** Sets the dashboard config state to the passed in dashboard config object
	 * @param {DashboardConfig} config - Incoming dashboard object to be loaded into the store
	 */
	setDashboardConfig(state, config) {
		Vue.set(state, 'dashboardConfig', config)
	},
	/** Modifies the order in the state to match the passed in array
	 * @param {String[]} newOrder - Array of widgetID's in the order from index 0 (left) working to the right
	 */
	setTabDisplayOrder(state, newOrder) {
		let position = 0
		for (const tabID of newOrder) {
			Vue.set(state.dashboardConfig[tabID], 'tabOrderPosition', position)
			position += 1
		}
	},
	/** Modifies the displayed tab name in the state
	 * @param state
	 * @param {Object} p
	 * @param {String} p.tabID		- Tab to be modified
	 * @param {String} p.newName 	- New string to be displayed as the tab name
	 */
	setTabName(state, { tabID, newName }) {
		Vue.set(state.dashboardConfig[tabID], 'tabName', newName)
	},
	/** Modifies the device type associated with a tab
	 * @param state
	 * @param {Object} p
	 * @param {String} p.tabID			- Tab being modified
	 * @param {String} p.newDevice 	- Device type to set the tab to
	 */
	setTabDevice(state, { tabID, newDevice }) {
		state.dashboardConfig[tabID].deviceModel = newDevice
	},
	/** Sets the active device associated with a model type
	 * @param state
	 * @param {Object} p
	 * @param {String} p.model - Model being modified
	 * @param {String} p.deviceID - Device being activated for the model
	 */
	_setActiveModelDevice(state, { model, deviceID }) {
		Vue.set(state.activeDeviceModel, model, deviceID)
	},
	/** Changes what tab is currently active to the passed in tab
	 * @param {String} tabID	- ID of the tab to be set as active
	 */
	_setActiveTabID(state, tabID) {
		state.activeTabID = tabID
	},
	/** Sets the active machine position
	 * @param {Number} position	- New position to set the active machine position
	 */
	_setActiveMachinePosition(state, position) {
		state.activeMachinePosition = position
	},

	/** Sets the ID of the widget currently being edited
	 * @param {String} widgetID 	- ID of widget currently being edited
	 */
	setEditingWidget(state, widgetID) {
		state.editingWidget = widgetID
	},

	/** Modify update column widget order with new array of settings
	 * @param state
	 * @param {Object}   p
	 * @param {String} 	 p.activeTabID											- Tab being modified
	 * @param {Boolean}  p.left													- True indicates we are modifying the left column, right otherwise
	 * @param {DashboardRightColumnRow[]|DashboardLeftColumnRow[]} p.newOrder	- Array of column row objects to be added
	 */
	setWidgetOrder(state, { activeTabID, left, newOrder }) {
		let columnSide = 'rightColumn'
		if (left) columnSide = 'leftColumn'
		state.dashboardConfig[activeTabID][columnSide] = newOrder
	},
	/** Update the given widget's config
	 * @param state
	 * @param {Object} p
	 * @param {String} p.activeTabID 	- Tab being modified
	 * @param {String} p.widgetID		- Widget being modified
	 * @param {WidgetSettings} p.config	- Widget config option to replace the current settings
	 */
	_setWidgetConfig(state, { activeTabID, widgetID, config }) {
		state.dashboardConfig[activeTabID].chartWidgets[widgetID] = config
	},
	/** Updates the container name holding the widget
	 * @param state
	 * @param {Object} p
	 * @param {String}  p.activeTabID 	- Tab being modified
	 * @param {string}  p.newText		- New container label
	 * @param {Boolean} p.left			- True indicates we are modifying th left column, right otherwise
	 * @param {Number}  p.index			- Location of the row being modified
	 */
	setWidgetLabel(state, { activeTabID, newText, left, index }) {
		let columnSide = 'rightColumn'
		if (left) columnSide = 'leftColumn'
		state.dashboardConfig[activeTabID][columnSide][index].name = newText
	},
	/** Removers */
	/** Deletes the given tab
	 * @param {String} tabID 	- Tab to be removed
	 */
	_removeTab(state, tabID) {
		Vue.delete(state.dashboardConfig, tabID)
	},
	/** Removes the given row from the tab
	 * @param state
	 * @param {Object} 	p
	 * @param {String}  p.activeTabID 	- Tab to edit
	 * @param {Number}  p.index			- Index within the column to edit
	 * @param {Boolean} p.left			- True indicates we are modifying th left column, right otherwise
	 */
	removeWidgetRow(state, { activeTabID, index, left }) {
		const chartWidgets = state.dashboardConfig[activeTabID].chartWidgets
		if (left) {
			const removedWidget = state.dashboardConfig[activeTabID].leftColumn.splice(index, 1)[0]
			Vue.delete(chartWidgets, removedWidget.leftWidget)
			Vue.delete(chartWidgets, removedWidget.rightWidget)
		} else {
			const removedWidget = state.dashboardConfig[
				activeTabID
			].rightColumn.splice(index, 1)[0]
			Vue.delete(chartWidgets, removedWidget.widget)
		}
	},
	/** Remove single widget from top row of dashboard
	 * @param state
	 * @param {Object} p
	 * @param {String} p.activeTabID 	- ID of tab being modified
	 * @param {number} p.index	   		- Index of widget to be removed
	 */
	removeWidgetSimple(state, { activeTabID, index }) {
		const removedWidget = state.dashboardConfig[activeTabID].top.splice(index, 1)[0]
		Vue.delete(state.dashboardConfig[activeTabID].chartWidgets, removedWidget)
	},
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultDashboardState())
	}
}
/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {
	/** Number of data points to be kept for streaming line charts
	 * @returns {number}
	*/
	getNumberOfLineChartObservations(state) {
		return state.numberOfLineChartObservations
	},
	/** Returns the active device associated with a given model */
	getActiveDeviceForModel: (state) =>
	/**
	 * @param {Object} p
	 * @param {string} p.model - Model type
	 * @returns {String} - Device ID
	 */
		({ model }) => {
			return state.activeDeviceModel[model]
		},
	/** Returns the widget ID currently being edited
	 * @returns {String} - Widget ID being edited or blank string
	 */
	getEditingWidgetID(state) {
		return state.editingWidget
	},
	/** Returns the container label associated with the passed in widgetID */
	getWidgetLabel: (state, getters) =>
	/**
	 * @param {Object} p
	 * @param {String} p.widgetID
	 * @returns {String} - Name associated with corresponding widgetID, or empty string if no match
	*/
		({ widgetID }) => {
			for (const row of getters.getActiveTab.leftColumn) {
				if (row.leftWidget === widgetID || row.rightWidget === widgetID) {
					return row.name
				}
			}
			for (const row of getters.getActiveTab.rightColumn) {
				if (row.widget === widgetID) {
					return row.name
				}
			}
			return ''
		},
	/** Returns the timestamp that the device was last updated. Undefined if it doesn't exist */
	getDeviceBulkAddTimestamp: (state) =>
	/**
	 * @param {{deviceID: String}} _
	 * @returns {Number} - Unix timestamp of when last bulk telemetry add was
	*/
		({ deviceID }) => {
			return state.bulkAddTimestamps[deviceID]
		},
	/** Returns a reference to the data for line charts */
	getLinechartTelemetry: (state) =>
	/**
	 * @param {Object} p
	 * @param {string} p.deviceID 		- Device ID who's telemetry to use
	 * @returns {[number,DashboardTelemetry[]]}
	 */
		({ deviceID }) => {
			if (!(deviceID in state.lineChartTelemetry)) return [state.lineChartTelemetry[deviceID], []]
			return [state.lineChartTelemetry[deviceID], lineChartTelemetry[deviceID]]
		},
	/** Returns the entire dashboard config */
	getDashboardConfig(state) {
		return state.dashboardConfig
	},
	/** Returns the currently active device model being viewed
	 * @returns {String} - Device Model
	 */
	getActiveDeviceModel(_, getters) {
		if (getters?.getActiveTab?.deviceModel === undefined) return ''
		return getters.getActiveTab.deviceModel
	},
	/** Returns the active device ID for the passed in widgetID
	 * Defaults to using Machine/Device/Position legacy setup
	 */
	getActiveDeviceID: (__, getters) =>
	/**
	 * @param {String} widgetID - Active deviceID
	 * */
		(widgetID) => {
			const widget = getters.getWidget(widgetID)
			if (widget === undefined || widget.dataSource === undefined || widget.dataSource === 'machineDevicePosition') {
				return getters.getActiveTab.positionMapping[getters.getActiveMachinePosition]
			}
			if (widget.dataSource === 'deviceUID') {
				return widget.dataSourceDetails
			}

			if (widget.dataSource === 'deviceModel') {
				return getters.getActiveDeviceForModel({ model: widget.dataSourceDetails })
			}

			return ''
		},
	/** Returns the currently available device positions on the machine
	 * @returns {Number[]} - Array of integers each indicating a position on the machine a device exists
	 */
	getDevicePositions(_, getters) {
		if (getters?.getActiveTab?.positionMapping === undefined) return []
		return Object.keys(getters.getActiveTab.positionMapping).map(index => parseInt(index))
	},
	/** Gets the active tab object
	 * @returns {DashboardTab} - Dashboard config tab object
	 */
	getActiveTab(state) {
		return state.dashboardConfig[state.activeTabID]
	},
	/** Returns a sorted list of tab UID. They are sorted by an internal position attribute each tab has
		 * @return {String[]} - Sorted tab UID list
		 */
	getTabDisplayOrder(state) {
		if (state.dashboardConfig === undefined) return
		const tabs = Object.keys(state.dashboardConfig).sort((a, b) => {
			if (state.dashboardConfig[a].tabOrderPosition < state.dashboardConfig[b].tabOrderPosition) return -1
			else if (state.dashboardConfig[a].tabOrderPosition > state.dashboardConfig[b].tabOrderPosition) return 1
			return 0
		})
		return tabs
	},
	/** Returns the tab object of the passed in ID */
	getTab: (state) =>
	/**
	 * @param {String} tabID - ID of the tab to return
	 * @returns {DashboardTab}
	 */
		(tabID) => {
			return state.dashboardConfig[tabID]
		},
	/** Returns the ID of the currently active tab
	 * @returns {String} 	- Currently active tab's ID
	 */
	getActiveTabID(state) {
		return state.activeTabID
	},
	/** Returns the currently active machine position
	 * @returns {Number} - Current position being viewed on the machine
	 */
	getActiveMachinePosition(state) {
		return state.activeMachinePosition
	},
	/** Returns the given widget from the the currently live tab */
	getWidget: (_, getters) =>
	/**
	 * @param {String} widgetID 	- ID of widget to be returned
	 * @returns {WidgetSettings}	- Widget config object
	*/
		(widgetID) => {
			return getters.getActiveTab.chartWidgets[widgetID]
		},
	/** Returns all widgets on the active tab
	 * @returns {Object<string,WidgetSettings>} - Returns the entire chartWidgets from dashboard config
	 */
	getWidgets(_, getters) {
		if (getters?.getActiveTab?.chartWidgets === undefined) return {}
		return getters.getActiveTab.chartWidgets
	},
	/** Returns the base length of transitions in milliseconds
	 * @return {number} Milliseconds length of baseline transitions
	 */
	getDashboardTransitionLength(state) {
		return state.DASHBOARD_TRANSITION_LENGTH
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
