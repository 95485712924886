import axios from 'axios'
import { getActiveUserCustomerID } from '@/helpers/jwt.js'
/** Interface for all job info based APIs */

/** Converts Job object from API to uniform object to be used within store/amp
 * @returns {Job}
*/
function parseJobObject(response) {
	const j = {}
	j.customerID = response.customer_id
	j.data = JSON.parse(response.data_string)
	j.imageID = response.image
	j.imageName = response.image_name
	j.jobID = response.job_id
	j.imageURL = response.signed_image
	return j
}

/** Given a HTTP request return value that contains a signed URL
 * This function uploads the passed in file to S3 using that URL
 * @param {{data:"Job Updated"} | {data:{ImageToken: String, ImageID: String}}} r
 * @param {File} imageFile
 * @param {String} image
 */
async function processImage(r, imageFile, image) {
	if (r.data === 'Job Updated' || r.data.ImageToken === '') {
		return Promise.resolve()
	}
	const imageRenamed = new File([imageFile], r.data.ImageID, { type: imageFile.type })
	const myHeaders = new Headers({
		'Content-Type': 'image/*',
		'x-amz-meta-image-name': image
	})
	return fetch(r.data.ImageToken, {
		method: 'PUT',
		headers: myHeaders,
		body: imageRenamed
	})
}

/** AMP Jobs store
 * @typedef {Object} JobStore
 */

/** @returns {JobStore} */
export const getDefaultUserState = () => {
	return {
		jobs: {}
	}
}

export const storeState = getDefaultUserState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {
	/** Returns all jobs for the current customer */
	fetchJobs() {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios.get(`/jobs/v1/jobs/${getActiveUserCustomerID()}`, {
			headers: jwt,
			timeout: 5000
		}).then(r => {
			if (r.data.job === null) return []
			const jobs = []
			for (const j of r.data.job) {
				jobs.push(parseJobObject(j))
			}
			return jobs
		})
	},

	/** Fetches a single job of the passed in ID
	 * @param {null} _
	 * @param {String} jobID
	 */
	fetchJob(_, jobID) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios.get(`/jobs/v1/job/${getActiveUserCustomerID()}/${jobID}`, {
			headers: jwt, timeout: 5000
		}).then(r => {
			return parseJobObject(r.data.job)
		})
	},

	/** Edits a job with the passed in info
	 * @param {JobStore} _
	 * @param {Object} p
	 * @param {Object} p.jobData
	 * @param {string} p.image
	 * @param {string} p.jobID
	 * @param {File} p.imageFile
	 * @param {string} p.imageName
	 */
	editJob(_, { jobData, image, jobID, imageFile, imageName }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			data_string: JSON.stringify(jobData),
			customer_id: getActiveUserCustomerID()
		}
		if (imageFile !== null) {
			body.image = image
			body.image_name = imageName
		}
		return axios.put(`/jobs/v1/job/${getActiveUserCustomerID()}/${jobID}`, body, {
			headers: jwt, timeout: 5000
		}).then(async(r) => {
			await processImage(r, imageFile, image)
				.catch((error) => {
					console.error('Error editing job', error)
				})
		})
	},

	/** Deletes the passed in job
	 * @param {JobStore} _
	 * @param {Object} p
	 * @param {String} p.customerID
	 * @param {string} p.jobID
	 */
	deleteJob(_, { customerID, jobID }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}

		return axios.delete(`/jobs/v1/job/${customerID}/${jobID}`, {
			headers: jwt, timeout: 5000
		})
	},

	/** Creates a job
	 * @param {JobStore} _
	 * @param {Object} p
	 * @param {Object} p.jobData
	 * @param {string} p.image
	 * @param {File} p.imageFile
	 * @param {string} p.imageName
	 */
	createJob(_, { jobData, image, imageFile, imageName }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			image: image,
			image_name: imageName,
			data_string: JSON.stringify(jobData),
			customer_id: getActiveUserCustomerID()
		}
		return axios
			.post(
				'/jobs/v1/job', body, {
					headers: jwt,
					timeout: 5000
				}
			)
			.then(async(r) => {
				await processImage(r, imageFile, image)
					.catch((error) => {
						console.error('Error creating job', error)
					})
			})
	}
}

/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {

}

/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
