<template>
  <div :style="cssProps">
    <div class="checkbox-form">
      <div class="answers">
        <label
          class="item"
        >
          <input
            :checked="checked"
            type="checkbox"
            @input="$emit('input',!checked)"
            @change="(e)=>$emit('change', e)"
          >
          <span
            class="checkmark"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
// Based on https://qvault.io/javascript/how-to-create-a-custom-checkbox-form-in-vue/
export default {

	props: {
		/** Hex code for the color of the background of the checkbox */
		color: {
			type: String,
			default: '#356F7B'
		},

		/** Height in pixels of the checkbox */
		height: {
			type: Number,
			default: 20
		},

		/** Value of checkbox */
		checked: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		cssProps() {
			return {
				'--bg-checked-color': this.color,
				'--height': this.height,
				'--height-px': this.height + 'px',
				// Proportional changes are used to keep the checkmark looking good for _most_ heights
				'--checkmark-height': (this.height * 0.5).toString() + 'px',
				'--checkmark-width': (this.height * 0.25).toString() + 'px',
				'--checkmark-thickness': (this.height * 0.1).toString() + 'px',
				'--checkmark-left': (this.height * 0.4).toString() + 'px',
				'--checkmark-top': (this.height * 0.2).toString() + 'px'
			}
		}
	}
}
</script>

<style scss-scoped>
.checkbox-form .answers {
	display: flex;
	flex-direction: column;
	align-items: left;
	width: 100%;
}

.checkbox-form .item {
	display: block;
	position: relative;
	padding-left: var(--height-px);
	cursor: pointer;
	font-size: 1em;
	height: var(--height-px);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: flex;
	align-items: center;
}

.checkbox-form .item input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkbox-form .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: var(--height-px);
	width: var(--height-px);
	background-color: #c2c2c2;
    border-radius: 5px;
}

.checkbox-form .item:hover input ~ .checkmark {
	background-color: #949494;
}

.checkbox-form .item input:checked ~ .checkmark {
	background-color: var(--bg-checked-color);
}

.checkbox-form .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox-form .item input:checked ~ .checkmark:after {
	display: block;
}

.checkbox-form .item .checkmark:after {
	left: var(--checkmark-left);
	top: var(--checkmark-top);
	width: var(--checkmark-width);
	height: var(--checkmark-height);
	border: solid white;
	border-width: 0 var(--checkmark-thickness) var(--checkmark-thickness) 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
</style>
