import axios from 'axios'
import Vue from 'vue'

/** Report store state
 * @typedef {Object} RoleStore
 * @prop {null|Object<string,Role>} roles 	- All role data
 * @prop {Set<string>} 				queried - Set of customers that roles have been queried
 */

/** @returns {RoleStore} */
export const getDefaultRoleState = () => {
	return {
		roles: null,
		queried: new Set()
	}
}

export const storeState = getDefaultRoleState()

export const storeActions = {

	/** Creates a role from the passed in body
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object}  p
	 * @param {String}  p.name			- The name of the new role
	 * @param {String}  p.description	- The description of the new role
	 * @param {String}  p.customerId	- The customer id that the new role belongs to
	 *
	 * @returns {Promise}
	 */
	createRole({ dispatch }, { name, description, customerId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			name: name,
			description: description,
			customer_id: customerId
		}
		return axios
			.post('/api/v1/roles', body, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				return dispatch('fetchRoles', [customerId])
			})
			.catch((error) => {
				console.error('Error creating roles', error)
			})
	},

	/** Fetch all roles for a list of customers
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String[]} customerIDs - List of customer IDs to query roles for
	 *
	 * @returns {Promise}
	 */
	fetchRoles({ commit, state }, customerIDs) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		function getRolesForCustomer(customerID) {
			return axios
				.get(`/api/v1/customers/${customerID}/roles`, {
					headers: jwt
				})
				.then((response) => {
					const translatedRoles = {}
					for (const role of response.data.roles) {
						translatedRoles[role.id] = role // don't map role parameters for now
					}
					commit('_setRoles', translatedRoles)
				})
				.catch((error) => {
					console.error('Error loading roles:', error)
					commit('_setRoles', {})
				}).finally(_ => {
					commit('_setQueried', customerID)
				})
		}
		const getRolesPromises = []
		for (const customerID of customerIDs) {
			getRolesPromises.push(getRolesForCustomer(customerID))
		}
		return Promise.all(getRolesPromises)
	},

	/** Updates Existing Role
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object}  p
	 * @param {String}  p.id			- ID of the role being edited
	 * @param {String}  p.name			- The name of the new role
	 * @param {String}  p.description	- The description of the new role
	 * @param {String}  p.customerId	- The customer id that the new role belongs to
	 *
	 * @returns {Promise} Axios promise object with response from updating role
	 */
	updateRole({ dispatch }, { id, name, description, customerId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			name: name,
			description: description,
			customer_id: customerId
		}
		return axios
			.put('/api/v1/roles/' + id, body, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				return dispatch('fetchRoles', [customerId])
			})
			.catch((error) => {
				console.error('Error updating role', error)
			})
	},

	/** Deletes an existing role
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String} id - id of role to delete
	 */
	deleteRole({ commit }, id) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.delete(`/api/v1/roles/${id}`, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				commit('_removeRole', id)
			})
			.catch((error) => {
				console.error('Error deleting role', error)
			})
	}
}

/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
	/** Sets the state's roles
	 *
	 * @param {Object<string,Role>} roles - Keys are role IDs, values are role objects
	 */
	_setRoles(state, roles) {
		if (state.roles === null) {
			Vue.set(state, 'roles', {})
		}
		for (const r in roles) {
			Vue.set(state.roles, roles[r].id, roles[r])
		}
	},
	/** Add a queried id to queried set
	 *
	 * @param {String} customerID - ID of machine queried for
	 */
	_setQueried(state, customerID) {
		state.queried.add(customerID)
	},
	/** Remove a role
	 *
	 * @param {String} id - role ID to remove from the store
	 */
	_removeRole(state, id) {
		if (state.roles === null) return
		Vue.delete(state.roles, id)
	},
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultRoleState())
	}
}

/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {
	/** Gets all loaded roles
	 *
	 * @return {Object<string,Role>} Keys are role id, values are role name
	 */
	getRoles(state) {
		return state.roles
	},
	/** Gets all parameters previously queried for
	 *
	 * @return {Set<string>} - Set of entity ids queried for
	 */
	getQueried(state) {
		return state.queried
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
