import axios from 'axios'
import Vue from 'vue'

/** @returns {MachineState} */
export const getDefaultMachineState = () => {
	return {
		machines: null,
		activeMachineIndex: null,
		queried: new Set()
	}
}

export const storeState = getDefaultMachineState()
export const storeActions = {

	/** Creates a Machine from the passed in body
	 *
	 * @param {import('vuex').ActionContext<MachineState, any>} _
	 * @param {Object} p
	 * @param {Date}   p.createdTime	- The time that this machine was added
	 * @param {String} p.description	- The description of the machine
	 * @param {String} p.locationId		- The Location ID this machine belongs to
	 * @param {String} p.make			- The Machine's Make
	 * @param {String} p.model			- The Machine's Model
	 * @param {String} p.name			- The Machine's Name
	 * @param {Number} p.numColors		- Number of colors the machine supports
	 * @param {Number} p.printWidth		- The width of the machines print area
	 * @param {string} p.customerId		- ID of customer creating machine
	 *
	 * @returns {Promise} Axios promise with response, that includes id of new machine
	 */
	createMachine({ dispatch }, { createdTime, description, locationId, make, model, name, numColors, printWidth, customerId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			created_at: createdTime,
			description: description,
			location_id: locationId,
			make: make,
			model: model,
			name: name,
			num_colors: +numColors,
			print_width: +printWidth,
			customer_id: customerId
		}
		return axios
			.post('/api/v1/machines', body, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then((response) => {
				return dispatch('fetchMachines', [customerId])
					.then(() => { return response }) // return response to get ID of newly created machine
			})
			.catch((error) => {
				console.error('Error creating machine:', error)
			})
	},

	/** Fetch all machines from remote API and load it into the store
	 *
	 * @param {import('vuex').ActionContext<MachineState, any>} _
	 * @param {String[]} customerIDs list of customer id strings to generate machines for
	 *
	 * @returns {Promise} Axios promise with results of generate machines
	 */
	fetchMachines({ commit }, customerIDs) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		/** Create a promise factory to get machines for each customer group
		*
		* @param {String} customerID - customer UUID
		*
		* @returns {Promise} Axios promise object with results of getting machines for locations
		*/
		function getMachineForCustomer(customerID) {
			return axios
				.get(`/api/v1/customers/${customerID}/machines`, {
					headers: jwt,
					timeout: 5000
				})
				.then((response) => {
					// Translate machine's parameter names from the API keys to javascript friendly keys
					// These keys are documented in state declaration at the top of the page as well
					const translatedMachines = {}
					for (const machine of response.data.machines) {
						translatedMachines[machine.id] = {
							description: machine.description,
							id: machine.id,
							locationID: machine.location_id,
							make: machine.make,
							model: machine.model,
							name: machine.name,
							numColors: machine.num_colors,
							printWidth: machine.print_width,
							customerID: machine.customer_id
						}
					}
					commit('_setMachines', translatedMachines)
				})
				.catch((error) => {
					console.error('Error getting machines for customer', customerID, ': ', error)
				}).finally(_ => {
					commit('_setQueried', customerID)
				})
		}
		const getMachinePromises = []
		for (const customerID of customerIDs) {
			getMachinePromises.push(getMachineForCustomer(customerID))
		}
		return Promise.all(getMachinePromises)
	},

	/** Updates Existing Machine
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object}  p
	 * @param {String}  p.machineId		- The id of the device being updated
	 * @param {String}  p.description	- The updated description of the machine
	 * @param {String}  p.make			- The updated make of the machine
	 * @param {String}  p.model			- The updated model of the machine
	 * @param {String}  p.name			- The updated name of the machine
	 * @param {Number}  p.numColors		- The updated number of colors on the machine
	 * @param {Number}  p.printWidth	- The updated print width of the machine
	 * @param {String}  p.locationId	- The (new) location id that the machine belongs to, empty string if none
	 * @param {String}  p.customerId	- The (new) customer id that the machine belongs to
	 *
	 * @returns {Promise} Axios promise object with updated machine
	 */
	updateMachine({ dispatch }, { machineId, description, make, model, name, numColors, printWidth, locationId, customerId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			description: description,
			make: make,
			model: model,
			name: name,
			num_colors: parseInt(numColors),
			print_width: parseFloat(printWidth),
			location_id: locationId,
			customer_id: customerId
		}
		return axios
			.put('/api/v1/machines/' + machineId, body, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				return dispatch('fetchMachines', [customerId])
			})
			.catch((error) => {
				console.error('Error updating machine:', error)
			})
	},
	/** Deletes an existing Machine
	 *
	 * @param {import('vuex').ActionContext<MachineState, any>} _
	 * @param {String} machineId MachineId to delete
	 */
	deleteMachine({ commit, getters, dispatch }, machineId) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.delete(`/api/v1/machines/${machineId}`, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				commit('_removeMachine', machineId)
				if (Object.keys(getters.getMachines).length === 0) {
					commit('_setMachineActiveIndex', null)
				} else if (getters.getMachineActive.id === machineId) {
					// If we're deleting the active machine, set activeIndex to 0
					commit('_setMachineActiveIndex', 0)
				} else {
					// Otherwise, the count of machines shrunk by 1, so rotate backwards to keep
					// the same machine active
					dispatch('setActiveMachineRotate', false)
				}
			})
			.catch((error) => {
				console.error('Error deleting machine:', error)
			})
	},

	/** Rotates the currently active machine to the next or previous one in alphabetical order by name
	 *
	 * @param {import('vuex').ActionContext<MachineState, any>} _
	 * @param {Boolean} next - If true rotate forward alphabetically, backwards otherwise
	 */
	setActiveMachineRotate({ getters, commit, state }, next = true) {
		let index = state.activeMachineIndex

		// Locate the next valid machine within index range
		if (next) index += 1
		else index -= 1
		if (index < 0) index = getters.getMachinesOrdered.length - 1
		else if (index >= getters.getMachinesOrdered.length) index = 0

		commit('_setMachineActiveIndex', index)
	},

	/** Set the active machine to a given ID if it exists. If it doesn't, nothing changes
	 *
	 * @param {import('vuex').ActionContext<MachineState, any>} _
	 * @param {String} machineID - ID of machine that should be set to active
	 */
	setActiveMachineID({ commit, getters }, machineID) {
		const index = getters.getMachinesOrdered.findIndex((machine) => machine.id === machineID)
		if (index !== -1) {
			commit('_setMachineActiveIndex', index)
		}
	}
}

/** @type {import("vuex").MutationTree<storeState>} */
export const storeMutations = {
	/** Sets the state's machines
	 * @param {Object<string,Machine>} tempMachines - Keys are machine ID, value are all associated values of that machine
	 */
	_setMachines(state, tempMachines) {
		if (state.machines === null) {
			Vue.set(state, 'machines', {})
		}
		for (const m of Object.values(tempMachines)) {
			Vue.set(state.machines, m.id, m)
		}
		if (state.activeMachineIndex === null && Object.keys(state.machines).length !== 0) {
			Vue.set(state, 'activeMachineIndex', 0)
		}
	},
	/** Sets the active machine index
	 *
	 * @param {Number} index - Integer index of active machine in getMachinesOrdered
	 */
	_setMachineActiveIndex(state, index) {
		Vue.set(state, 'activeMachineIndex', index)
	},
	/** remove a machine
	 *
	 * @param {String} machineID - ID of machine to remove
	 */
	_removeMachine(state, machineID) {
		if (state.machines === null) return
		Vue.delete(state.machines, machineID)
	},
	/** Add a queried id to queried set
	 *
	 * @param {String} locationID - ID of machine queried for
	 */
	_setQueried(state, locationID) {
		state.queried.add(locationID)
	},
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultMachineState())
	}
}

/** @type {import("vuex").GetterTree<storeState>} */
export const storeGetters = {
	/** Gets all loaded machines
	 *
	 * @return {Object<string,Machine>} Keys are machine UID properties are all values associated with machine
	 */
	getMachines(state) {
		return state.machines
	},
	/** Returns an alphabetically sorted array of machines (by name) each index having a machine object
	 *
	 * @returns {Machine[]} A list of machine objects sorted alphabetically by name
	 */
	getMachinesOrdered(state) {
		if (state.machines === null) return []
		// Making an ordered list
		const tempMachines = []
		for (const machine of Object.values(state.machines)) {
			tempMachines.push(machine)
		}
		tempMachines.sort((a, b) => {
			if (a.name < b.name) return -1
			if (a.name > b.name) return 1
			return 0
		})
		return tempMachines
	},
	/** Gets all properties of currently active machine
	 * @return {Machine} Object with all properties of a machine
	 */
	getMachineActive(state, getters) {
		if (state.machines === null || state.activeMachineIndex === null) return null
		return getters.getMachinesOrdered[state.activeMachineIndex]
	},
	/** Gets all parameters previously queried for
	 * @return {Set}	Set of entity ids queried for
	 */
	getQueried(state) {
		return state.queried
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
