import axios from 'axios'
import Vue from 'vue'
import { getActiveUserRole, getActiveUserCustomerID } from '@/helpers/jwt.js'

/** Permission store state
 * @typedef {Object} PermissionStore
 * @prop {null|Object<string,Object<string,Object<string,Boolean>>>} 	permissionsForCustomerRoles	- CustomerID then <Role ID> for keys. If permission not under customer they don't have it
 * @prop {null|Object<string,Permission>} 								permissions 				- All permissions
 */

/** @returns {PermissionStore} */
export const getDefaultPermissionState = () => {
	return {
		permissionsForCustomerRoles: null,
		permissions: null
	}
}

export const storeState = getDefaultPermissionState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {

	/** Give access to a permission for a role within a customer group
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object}  p
	 * @param {Number}  p.roleId		- Role id that will now have permission
	 * @param {Number}  p.permissionId	- Permission id being granted access
     * @param {String}  p.customerId   	- ID of the customer group to which the role belongs
	 */
	createRolePermission({ commit, getters }, { roleId, permissionId, customerId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			permission_id: permissionId,
			role_id: +roleId,
			customer_id: customerId
		}
		return axios
			.post('/api/v1/role-permissions', body, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				const permissions = {}
				permissions[getters.getPermissions[permissionId].name] = true
				commit('_setPermissionsForCustomerRole', {
					customerId: customerId,
					roleId: roleId,
					permissions: permissions
				})
			})
			.catch((error) => {
				console.error('Error adding permission role', error)
			})
	},

	/** Fetch all permission entities, then generate permissions for the logged in user
	 *
	 * @returns {Promise}
	 */
	fetchPermissions({ commit }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.get('/api/v1/permissions', {
				headers: jwt,
				timeout: 5000
			})
			.then((response) => {
				// Translate permission's parameter names from the API keys to javascript friendly keys
				// These keys are documented in state declaration at the top of the page as well
				const translatedPermissions = {}
				for (const perm of response.data.permissions) {
					translatedPermissions[perm.id] = perm // Not currently translating keys
				}
				commit('_setPermissions', translatedPermissions)
			})
			.catch((error) => {
				console.error('Error generating permissions', error)
				commit('_setPermissions', {})
			})
	},
	/** Fetch valid permissions for a given customer and role
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object}  p
     * @param {String}  	p.customerId	- ID of the customer group
     * @param {Number[]}  	p.roles     	- List of role ids to get permissions for
    */
	fetchPermissionsForCustomerRoles({ commit }, { customerId, roles }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		/** Promise factory function to get all permissions for a role
         * @param {Number} roleId   Role id to get permissions for
        */
		function getPermissionsForRole(roleId) {
			const endpoint = `/api/v1/role-permissions/roles/${roleId}/customers/${customerId}/permissions`
			return axios
				.get(endpoint, {
					headers: jwt,
					timeout: 5000
				})
				.then((response) => {
					const translatedPermissions = {}
					for (const permission of response.data.permissions) {
						translatedPermissions[permission.name] = true
					}
					commit('_setPermissionsForCustomerRole', {
						customerId: customerId,
						roleId: roleId,
						permissions: translatedPermissions
					})
				})
				.catch((error) => {
					console.error('Error generating permissions for customer:', customerId, 'role:', roleId, ':', error)
					commit('_setPermissionsForCustomerRole', {
						customerId: customerId,
						roleId: roleId,
						permissions: {}
					})
				})
		}
		const getPermPromises = []
		for (const roleID of roles) {
			getPermPromises.push(getPermissionsForRole(roleID))
		}
		return Promise.all(getPermPromises)
	},

	/** Deletes an existing role-permission
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object}  p
	 * @param {Number}  p.roleId		- role id for the role-permission to delete
	 * @param {Number}  p.permissionId	- permission id for the role-permission to delete
	 * @param {string}  p.customerId 	- ID of customer
	 */
	deleteRolePermission({ commit, getters }, { roleId, permissionId, customerId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.delete(`/api/v1/role-permissions/roles/${roleId}/customers/${customerId}/permissions/${permissionId}`, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				const permissions = {}
				permissions[getters.getPermissions[permissionId].name] = false
				commit('_setPermissionsForCustomerRole', {
					customerId: customerId,
					roleId: roleId,
					permissions: permissions
				})
			})
			.catch((error) => {
				console.error('Error deleting role permission', error)
			})
	}
}

/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
	/** Sets the state's permissions, overriding previous permissions
	 *
	 * @param {Permission[]} permissions - List of objects containing permission details
	 */
	_setPermissions(state, permissions) {
		if (state.permissions === null) {
			Vue.set(state, 'permissions', {})
		}
		for (const p in permissions) {
			Vue.set(state.permissions, permissions[p].id, permissions[p])
		}
	},
	/** Set permissions for a given role within a customerID
	 *
	 * @param state
	 * @param {Object} p
	 * @param {string} 					p.roleId 		- The roleId that we are setting for
	 * @param {Object<string,boolean>} 	p.permissions	- Mapping of permission name to boolean value indicating it is valid
	 * @param {string} 					p.customerId	- customer id containing the role-permission relation
	 */
	_setPermissionsForCustomerRole(state, { customerId, roleId, permissions }) {
		if (state.permissionsForCustomerRoles === null) {
			Vue.set(state, 'permissionsForCustomerRoles', {})
		}
		if (!(customerId in state.permissionsForCustomerRoles)) {
			Vue.set(state.permissionsForCustomerRoles, customerId, {})
		}
		if (!(roleId in state.permissionsForCustomerRoles[customerId])) {
			Vue.set(state.permissionsForCustomerRoles[customerId], roleId, {})
		}
		for (const perm in permissions) {
			Vue.set(state.permissionsForCustomerRoles[customerId][roleId], perm, permissions[perm])
		}
	},
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultPermissionState())
	}
}

export const storeGetters = {
	/** Gets all loaded permissions entities
	 *
	 * @return {Object<string,Permission>} Keys are permission id, value is permission name and description
	 */
	getPermissions(state) {
		return state.permissions
	},

	/** Gets all permissions for all roles of all customers
	 *
	 * @return {Object<string,Object<string,Object<string,boolean>>>}
	 * */
	getPermissionsForCustomerRoles(state) {
		return state.permissionsForCustomerRoles
	},

	/** Returns a boolean of whether a permissions of given name exists for the active user
	 * If user is role id 1 (admin), automatically return true
	 */
	getValidPermissionForUser: (state) =>
	/**
	 * @param {Object} p
	 * @param {string} p.permissionName
	 *
	 * @returns {Boolean}
	 */
		({ permissionName }) => {
			const userRole = getActiveUserRole()
			if (userRole === 1) return true // If user is admin, don't bother
			const userCustomer = getActiveUserCustomerID()
			const valid = state.permissionsForCustomerRoles?.[userCustomer]?.[userRole]?.[permissionName]
			if (valid === undefined) {
				return false
			}
			// Valid will only be false if it was deleted locally
			return valid
		}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
