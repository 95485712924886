<template>
  <button
    class="shared"
    :disabled="disabled"
    :class="[{'padding':!disablePadding, 'shared--margin-left': marginLeft}, coloring, filled, isDisabled]"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>
<script>
/** ampButton is a drop in replacement for HTML <button>. It provides simple props to dictate appearance
 *
 * Inserting text works exactly how it works with <button>s
 */
export default {
	props: {
		/** General style type. Must be:
         *  "text": Simple bolded text
         *  "dark": Solid and empty coloring using a darker pallet
         *  "light": Solid and empty coloring using a lighter pallet
         *  "red": Only empty style in a red format that sticks out */
		coloring: {
			type: String,
			default: 'dark',
			validator: (v) => v in { text: 1, dark: 1, light: 1, red: 1 }
		},

		/** Controls if default padding should be used */
		disablePadding: {
			type: Boolean,
			default: false
		},

		/** Filled with solid color or white filling */
		solidColor: {
			type: Boolean,
			default: true
		},

		/** Disables the button from sending clicks and grays it out */
		disabled: {
			type: Boolean,
			default: false
		},

		/** Adds left margin to the button */
		marginLeft: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		/** @returns {string} */
		filled() {
			return this.solidColor ? 'filled' : 'empty'
		},

		/** @returns {string} */
		isDisabled() {
			return this.disabled ? 'disabled' : ''
		}
	}

}
</script>

<style lang="scss" scoped>
.padding{
    padding: 0.5rem 1rem;
}
.shared{
    font-family: Helvetica;
    letter-spacing: 0.03em;
    transition: 0.25s all;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    height: auto;

    &--margin-left {
		margin-left: 20px;
	}
}
// button padding and spacing
.bttn{
	padding: 13px 34px;

	&--margin-left {
		margin-left: 20px;
	}
}
.text{

    text-decoration: underline;
}
.red{
    border: 2px solid #ff3b30;
    color: #ff3b30;
    background-color: #ffffff;
}
.dark{
    &.filled{
        background-color: #1B2A38;
        color: #FFFFFF;
        &.disabled{
            background: #A6A6A6;
            border: solid 2px #FFFFFF;
            cursor:default;
        }

        &:hover{
            background-color: #525E70;
        }
    }
    &.empty{
        border: solid 2px #383838;
        color: #383838;
        &.disabled{
            background: #A6A6A6;
            cursor: auto;
        }
    }
}

.light{
    &.filled{
        background-color: #4d667d;
        color: #FFFFFF;
    }
    &.empty{
        border: 2px solid #6c6c6c;
        color: #383838;
    }
}
</style>
