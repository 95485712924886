import Vue from 'vue'
import axios from 'axios'
import {
	bisector,
	extent, sum,
	mean as d3mean,
	median as d3median,
	variance as d3variance,
	deviation as d3deviation
} from 'd3-array'
import { curveMonotoneX, curveStepAfter, curveStepBefore, curveLinear, curveBasis, curveNatural } from 'd3-shape'
import { scaleOrdinal, scaleLinear } from 'd3-scale'
import { schemeCategory10 } from 'd3-scale-chromatic'
/** DeepChart state information
 * @typedef {Object} DeepchartStore
 * @prop {Boolean} 																		dataLoading 			- Flag indicating data is being loaded and processed
 * @prop {Number|null} 																	timeWindowStart 		- Unix timestamp start of the large time window
 * @prop {Number|null} 																	timeWindowEnd 			- Unix timestamp end of the large time window
 * @prop {Number|null} 																	timeZoomStart 			- Unix timestamp start of the zoom time window
 * @prop {Number|null} 																	timeZoomEnd 			- Unix timestamp end of the zoom time window
 * @prop {Object<string,TelemetryWindow>} 												largeTelemetryWindow 	- Top level keys are deviceID
 * @prop {Object<string,TelemetryWindow>} 												smallTelemetryWindow 	- Top level keys are deviceID
 * @prop {{aggTime:number,deviceID:string,newDataSet:SingleTelemetryObject[]}[]} 		rawTelemetryData 		- Raw telemetry for each device from API unformatted
 * @prop {Object<string,DeepChartTelemetryInfo>} 										telemData 				- Data formatted and ready for graphing. Top level keys are expected to be <deviceID>-<telemKey> to ensure they are unique
 * @prop {Object<string, DataPoint[]>} 													_navBarData 			- Always holds zoomed out data. Holds nav bar telemetry data. Keys are <telemKey> + <deviceID>
 * @prop {Object<string, {data:Context[],end:number,start:number}>} 					contextData 			- Top level keys are deviceID that context belongs to. Start and end timestamps are time range context values belong to
 * @prop {{device:string,key:string}[]} 												contextLabels 			- Active context labels on the deep chart. Each row having a deviceID and context key name
 * @prop {string} 																		contextShadingDevice 	- DeviceID who's context values can be chosen generate shading on the deep chart
 * @prop {string} 																		contextShadingKey 		- Context key who's values generate shading
 * @prop {Object<string, {start:number,end:number, agg:number,data:ruleViolation[]}>} 	analyticData 			- Top level keys are rule IDs, contains triggered rule information
 * @prop {Object<string, {start:number, end:number, agg:number}>} 						ruleQueries 			- Top level keys are deviceID. Stores previously queried rule info
 * @prop {Object<string, activeRule>} 													activeRules 			- Top level keys are ruleID. Indicates color and device rule belongs to and are displayed on the chart
 * @prop {Object<string, deviceStatusObject>} 											deviceStatus 			- Top keys are deviceID contains information of if telemetry should be displayed
 * @prop {Object<string,DeepChartLine>} 												customLines 			- Top level keys must be unique per line. Contains info about extra lines to be drawn on deep chart
 * @prop {DeepChartSettings} 															chartSettings 			- Customizable settings of the deepchart. Many available to users via settings UI
 * @prop {Function} 																	COLORCALCULATOR 		- D3 hashing function to generate colors for chart
 * @prop {number|null} 																	_dataUpdate 			- `setTimeout` function ID of the last data api query
 * @prop {{useTime:boolean,key:string,device:string}}									xAxisLabelInfo			- Controls what data should be used to generate the x-axis tick marks
*/

/** All information for a telemetry window
 * @typedef {Object} TelemetryWindow
 * @prop {Number} 					aggTime 					- Number of milliseconds data has been aggregated from
 * @prop {string} 					deviceID 					- Device data belongs to
 * @prop {number} 					endTS 						- Ending unix time of window
 * @prop {number} 					length 						- Number of telemetry rows within the message
 * @prop {SingleTelemetryObject[]} 	newDataSet 					- All the data
 * @prop {number} 					reqEnd 						- Requested ending timestamp
 * @prop {number} 					reqStart 					- Requested starting timestamp
 * @prop {number} 					requestedAggregationTime 	- Requested aggregation level
 * @prop {number} 					startTS 					- Starting unix time of window
 * @prop {number} 					totalMsgs 					- Total cassandra rows used when generating data

 */

/** Raw unparsed telemetry message for a single time range
 * @typedef {Object} SingleTelemetryObject
 * @prop {number} 						end_ts 			- Ending unix ms timestamp that data is from
 * @prop {number} 						row_count 		- Number of cassandra rows integrated in the data
 * @prop {number} 						start_ts 		- Starting unix ms timestamp that data is from
 * @prop {Object<string,string>} 		strTelemetry 	- String based telemetry
 * @prop {Object<string,RawTelemetry>} 	telemetry 		- Numerical telemetry keys are telemetry names
 */

/** Raw data about a single telemetry observation
 * @typedef {Object} RawTelemetry
 * @prop {number} count - Number of observations message is composed of
 * @prop {number} max 	- Maximum value within the time range
 * @prop {number} min 	- Minimum value within the time range
 * @prop {number} sum 	- Sum of all observations within time range
 * @prop {number} sumsq - Sum squared of all observations within the time range
 */

/** A single point of data
 * @typedef {Object} DataPoint
 * @prop {Number} time 	- Unix timestamp that data is from
 * @prop {number} value - Value of the telemetry
 */

/** Statistics generated about a section of telemetry. Min and max value keys are poorly named
 * @typedef {Object} Statistics
 * @prop {Number} deviation
 * @prop {Number} max
 * @prop {Number} mean
 * @prop {Number} median
 * @prop {Number} min
 * @prop {Number} slope
 * @prop {Number} variance

 */
/** A single telemetry object used with the Deepchart
 * @typedef {Object} _base
 * @prop {Number} 		aggTime 		- Amount of milliseconds data has been aggregated
 * @prop {DataPoint[]}	globalData 		- Array of objects with value and time keys. These data points are graphed on the nav bar and spans the entire time frame
 * @prop {String} 		selectedValue	- If the user has moused over the chart, then the value of this line closest to the users mouse will be here
 * @prop {Statistics} 	statistics 		- Statistics about the telemetry. Keys are value type (max, mean, etc)
 * @prop {String} 		telemKey 		- Key of the telemetry associated to the data
 * @prop {String} 		uid				- UID of associated device
 *
 * @typedef {_base & DeepChartTelemetry} DeepChartTelemetryInfo
 */

/** A single line entity used with the Deepchart
 * @typedef {Object} DeepChartLine
 * @prop {String} 						color			- Controls the color of the custom line
 * @prop {{value:number,time:number}[]} data			- Array of at least two points of data with .value and .time attributes
 * @prop {String} 						key				- Parent key repeated, used internally by the graphing widget
 * @prop {Number}						strokeDashArray	- HTML attribute controlling line appearance
 */

/** A context shading object (a single shade) to be displayed on the chart
 * @typedef {Object} ContextShading
 * @prop {String} id			- unique id for the context shade
 * @prop {Number} start  		- Time in epoch ms that the shading starts at
 * @prop {Number} end			- Time in epoch ms that the shading ends at
 * @prop {String} color  		- Hex code for the color of the shading
 * @prop {Number} colorOpacity 	- The opacity of the shading for 0 - 1
 * @prop {String} label			- Text displayed to the user for what the shading means
 */

/** A context label object, which is a single box on the deep chart
 * @typedef {Object} ContextLabel
 * @prop {String} color 		- Background color of the box
 * @prop {Number} colorOpacity 	- Background color opacity
 * @prop {Number} end 			- Starting timestamp of label, will be left edge of box
 * @prop {String} key 			- Context key box goes with
 * @prop {String} label 		- Context value that is displayed to user in box, generally <Key>: <Value>
 * @prop {Number} start 		- Ending timestamp of label, will be right edge of box
 */

/** A single rule violation object, to be parsed into custom lines to get displayed on the chart
 * @typedef {Object} ruleViolation
 * @prop {Number} count		- number of times rule violation was triggered in aggregation window
 * @prop {Number} timestamp - starting timestamp in epoch ms of the aggregation window
 */
/** An active rule has the parameters
 * @typedef {Object} activeRule
 * @prop {String} color		- Color in hex of the rule lines
 * @prop {String} device	- Device id that owns the rule
 */
/** Device Status dictates which devices are active and should have their telemetry data queried
 * @typedef {Object} deviceStatusObject
 * @prop {Number} activeCount - Running count of times the deviceID has been set to active
 * @prop {Boolean} 									is_active	- whether the device is active and should have its data queried or not
 * @prop {Object<string, telemetryStatusObject>} 	telemKeys	- map of telemetry key names to metadata and status object
 */

/** Generates a normalized copy of the passed in data
 * @param {Object<string,{time:number,value:number}[]>} data	- Unique keys pointing to array of objects with a .time .value attribute
 *
 * @returns {Object<string,{time:number,value:number}[]>} - Same structure as data but all values have been normalized from 0-1.
 */
const normalizeData = (data) => {
	/** @type {Object<string,{time:number,value:number}[]>} */
	const tempData = {}
	for (const telemKey of Object.keys(data)) {
		const [min, max] = extent(data[telemKey], d => d.value)
		tempData[telemKey] = []
		const scale = scaleLinear()
			.domain([Number(min), Number(max)])
			.range([0, 1])
		for (const d of data[telemKey]) tempData[telemKey].push({ time: d.time, value: scale(d.value) })
	}

	return tempData
}

/** Generates a least squares linear regression function, does not do any scale conversions.
 * @param {{time:number,value:number}[]} data - Requires each index to have a .time and .value
 *
 * @returns {{slope:number,regressionFunction:function}} Returns .slope and .regressionFunction of a y = mx + b style function to be used to generate y values for graphing
 */
const generateRegression = (data) => {
	// Offset all times by the smallest one to reduce the size of the numbers being multiplied
	// Fixes float drifting`
	const minTime = data[0].time
	// These are all generated separately for readability
	// Can all be done in single loop if performance calls for it
	const ySum = sum(data, d => d.value)
	const xSum = sum(data, d => (d.time - minTime))
	const xSumSquared = sum(data, d => ((d.time - minTime) * (d.time - minTime)))
	const xSumY = sum(data, d => (d.time - minTime) * d.value)
	const n = data.length
	// Generate constants by linear least squares
	const m = ((n * xSumY) - (xSum * ySum)) / ((n * xSumSquared) - (xSum * xSum))
	const b = ((ySum * xSumSquared) - (xSum * xSumY)) / ((n * xSumSquared) - (xSum * xSum))

	return { slope: m, regressionFunction: d => (((d - minTime) * m) + b) }
}

/** @returns {DeepchartStore} */
export const getDefaultDeepChartState = () => {
	return {
		dataLoading: false,
		timeWindowStart: null,
		timeWindowEnd: null,
		timeZoomStart: null,
		timeZoomEnd: null,
		largeTelemetryWindow: {},
		smallTelemetryWindow: {},
		rawTelemetryData: [],
		telemData: {},
		_navBarData: {},
		contextData: {},
		contextLabels: [],
		contextShadingDevice: '',
		contextShadingKey: '',
		analyticData: {},
		ruleQueries: {},
		activeRules: {},
		deviceStatus: {},
		customLines: {},
		chartSettings: {
			aggType: 'avg',
			aggTime: 1000,
			aggTimeMin: 1000,
			aggTimeMax: 10000,
			aggTimeStep: 1000,
			maxDataPoints: 750,
			minDataPoints: 25,
			strokeWidth: 1,
			lineOpacity: 1,
			curveType: curveLinear,
			curveTypeLookup: 'linear',
			axisStrokeWidth: 0.8,
			axisTextSize: 15,
			yLineTicks: 6,
			yMin: 0,
			yMax: 10,
			yManualScale: false,
			normalize: false,
			radius: 3,
			circleOpacity: 1,
			showCircles: true,
			useIndividualOpacity: false,
			performance: false
		},
		COLORCALCULATOR: scaleOrdinal(schemeCategory10),
		_dataUpdate: null,
		xAxisLabelInfo: { useTime: true, key: '', device: '' }
	}
}

export const storeState = getDefaultDeepChartState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {

	/** Updates all data displayed on the chart (if necessary), including telemetry, context, and rules
	 * To be called whenever the state of the chart changes (i.e. viewDataChanged in Engineer.Vue)
	 * Will only fetch new data once every 300ms, and only the most recent will be used within that window
	 */
	updateChart({ commit, getters, dispatch }) {
		commit('setDataLoading', true)
		// calculate aggregation values off of new time window
		const timeWindow = getters.getTimeWindowEnd - getters.getTimeWindowStart
		commit('setChartSettings', { name: 'aggTimeMin', value: Math.round(timeWindow / getters.getChartSettings.maxDataPoints) })
		commit('setChartSettings', { name: 'aggTimeMax', value: Math.round(timeWindow / getters.getChartSettings.minDataPoints) })
		// for step, give the user 20 "steps". Round the step the nearest 1000.
		commit('setChartSettings', { name: 'aggTimeStep', value: Math.round((getters.getChartSettings.aggTimeMax - getters.getChartSettings.aggTimeMin) / 20000) * 1000 })

		// If the minimum aggregation time is higher than current aggregation time, set them equal
		if (getters.getChartSettings.aggTimeMin > getters.getChartSettings.aggTime) commit('setChartSettings', { name: 'aggTime', value: getters.getChartSettings.aggTimeMin })

		// Ensure zoomed view shows the same amount of dots as the expanded view
		let tempAggTime = Math.round(getters.getChartSettings.aggTime)
		if (getters.getChartZoomed) {
			const zoomWindow = getters.getTimeZoomEnd - getters.getTimeZoomStart
			const expectedTelemCount = timeWindow / getters.getChartSettings.aggTime
			tempAggTime = Math.round(zoomWindow / expectedTelemCount)
		}

		const dataQuery = async function() {
			// Zoom time should always be used for determining current data window
			// As it with either equal the window, or be smaller
			const start = getters.getTimeZoomStart
			const end = getters.getTimeZoomEnd

			// Determine what devices are needed
			// Fire off as many getTelemetryData() as needed
			// For each active device get its telemetry
			const deviceData = []
			for (const deviceID of getters.getActiveDeviceIDs) {
				deviceData.push(dispatch('_getTelemetryData', {
					deviceID: deviceID,
					start: start,
					end: end,
					aggTime: tempAggTime,
					zoomed: getters.getChartZoomed
				}))
				dispatch('_fetchContextData', deviceID)
			}
			dispatch('_fetchRules')
			await Promise.all(deviceData).then((values) => {
				commit('_setRawTelemetryData', values)
				dispatch('parseTelemetry')
				commit('setDataLoading', false)
			})
		}

		// Only run once per quarter second to limit API spam from rapid user changes
		commit('_clearDataUpdateTimeout')
		commit('_setDataUpdateTimeout', dataQuery)
	},

	// ---- Telemetry Actions ----

	/** Parses the telemetry in `rawTelemetryData` into chart-able format, including calculating statistics
	 * The parsed data to be displayed is stored in `telemData`
	 */
	parseTelemetry({ state, getters, commit }) {
		// If there are no active telemetry keys no need to do anything else
		if (Object.keys(getters.getActiveTelemetryKeys).length === 0) {
			commit('_setTelemData', {})
			return
		}

		const newTelemData = {}
		// loop through devices that are active
		for (const deviceMessageIndex in getters.getRawTelemetryData) {
			const deviceMessage = getters.getRawTelemetryData[deviceMessageIndex]
			const deviceID = deviceMessage.deviceID

			// Device data is preloaded when it is activated, but it doesn't have to have active telemetry
			if (!(deviceID in getters.getActiveTelemetryKeys)) {
				continue
			}

			const deviceData = deviceMessage.newDataSet

			// temp data top level keys are telemetry key
			/// under each is an array of value time objects representing the data
			/** @type {Object<string,{time:number,value:number}[]>} */
			let tempData = {}
			// For each message we extract all the telemetry keys we care about
			for (const telemKey of getters.getActiveTelemetryKeys[deviceID]) {
				const tempDataKey = telemKey + deviceID
				for (const message of deviceData) {
					// Numeric telemetry:
					if (telemKey in message.telemetry) {
						if (!(tempDataKey in tempData)) tempData[tempDataKey] = []
						let value = 0
						if (getters.getChartSettings.aggType === 'range') {
							value = message.telemetry[telemKey].max - message.telemetry[telemKey].min
						} else if (getters.getChartSettings.aggType === 'avg') {
							value = message.telemetry[telemKey].sum / message.telemetry[telemKey].count
						} else {
							value = message.telemetry[telemKey][getters.getChartSettings.aggType]
						}
						tempData[tempDataKey].push({
							time: message.start_ts,
							value: value
						})
					}
				}
			}

			// If the user has opted to normalize data apply it to the data set now
			if (getters.getChartSettings.normalize) {
				tempData = normalizeData(tempData)
			}

			if (!getters.getChartZoomed) {
				commit('_setNavBarData', tempData)
			}

			// For each set of numeric telemetry generate stats to be displayed
			for (const telemKey of getters.getActiveTelemetryKeys[deviceID]) {
				const tempDataKey = telemKey + deviceID
				if (!(tempDataKey in tempData)) continue
				const statsTarget = tempData[tempDataKey]
				// Note all these are calculated separately for readability
				// If this becomes a performance bottle neck they can be created with a single loop
				const [min, max] = extent(statsTarget, d => d.value)
				const slope = (generateRegression(statsTarget).slope * 1000).toFixed(2)
				const mean = d3mean(statsTarget, d => d.value)
				const median = d3median(statsTarget, d => d.value)
				const variance = d3variance(statsTarget, d => d.value)
				const deviation = d3deviation(statsTarget, d => d.value)
				newTelemData[deviceID + '-' + telemKey] = {
					aggTime: deviceMessage.aggTime,
					uid: deviceID,
					key: deviceID + '-' + telemKey,
					data: tempData[tempDataKey],
					globalData: state._navBarData[tempDataKey],
					color: getters.getDeviceStatus[deviceID].telemKeys[telemKey].color,
					selectedValue: 'N/A',
					telemKey: telemKey,
					opacity: 0.5,
					statistics: {
						slope: slope,
						min: min,
						max: max,
						mean: mean,
						median: median,
						variance: variance,
						deviation: deviation
					}
				}
			}
		}

		// Keep track of the telemetry that are currently selected. Also keep track of which devices have which telemetries
		commit('_setTelemData', newTelemData)
	},

	/** Returns a promise that will eventually resolve into the requested data for the device
	 * Will first try and find the data cached in the store, then will attempt API
	 * If the data is cached and the requested window is smaller then the cached data, this function returns a trimmed down array
	 *
	 * @param {import('vuex').ActionContext<DeepchartStore>} _
	 * @param {Object} 	p
	 * @param {String} 	p.deviceID 	- DeviceID who's telemetry to query
	 * @param {number} 	p.start 	- Unix ms timestamp of of starting window
	 * @param {number} 	p.end 		- Unix ms timestamp of of ending window
	 * @param {number} 	p.aggTime 	- MS level to aggregate telemetry to if possible
	 * @param {boolean} p.zoomed 	- If requested telemetry should be stored in zoomed data or not
	 *
	 * @returns {Promise<{aggTime:number,deviceID:string,newDataSet: SingleTelemetryObject[]}>}
	 */
	async _getTelemetryData({ getters, dispatch }, { deviceID, start, end, aggTime, zoomed }) {
		// Determine if data is already cached, if so serve it back
		// Check in the large window first
		let data = getters._getDeviceData({ deviceID: deviceID, start: start, end: end, aggTime: aggTime })
		data = data.data

		if (data) {
			// If the data was found in the zoomed window, but the request indicates this is the max size
			// Move the data over to max window
			dispatch('_cacheTelemetry', { data: data, deviceID: deviceID, zoomed: zoomed })

			// If we found cached data it is possible that the cached data is a wider window then was requested
			// Prior to returning trim the data set to the requested range
			const binarySearch = bisector(d => d.start_ts)
			const leftIndex = binarySearch.left(data.newDataSet, start)
			const rightIndex = binarySearch.right(data.newDataSet, end)

			// Note everything is the same here except for the newDataSet
			// Unused elements are left out
			// But it all has to be recreated to avoid changing values in the state
			data = {
				aggTime: data.aggTime,
				deviceID: data.deviceID,
				newDataSet: data.newDataSet.slice(leftIndex, rightIndex)
			}
			return data
		}

		// Test it out
		data = await dispatch('Telemetry/generateHistoricalTelemetry', {
			deviceID: deviceID,
			startTime: start,
			endTime: end,
			aggTime: aggTime
		}, { root: true })

		// Update the requested aggregation time as the API does some massaging of the window
		data.requestedAggregationTime = aggTime
		dispatch('_cacheTelemetry', { data: data, deviceID: deviceID, zoomed: zoomed })

		// Remove references to unneeded data
		return {
			aggTime: data.aggTime,
			deviceID: data.deviceID,
			newDataSet: data.newDataSet
		}
	},

	/** Cache device telemetry data into the store. Currently supports two potential locations
	 * With the assumption being large window will be used more often so it is overwritten only rarely
	 * And the small window overrides more often
	 *
	 * @param {import('vuex').ActionContext<DeepchartStore>} _
	 * @param {Object} p
	 * @param {TelemetryWindow} p.data		- New data to be set
	 * @param {string} 			p.deviceID 	- DeviceID data belongs to
	 * @param {boolean} 		p.zoomed	- Indicates if it should be stored in zoomed window
	 */
	_cacheTelemetry({ commit }, { data, deviceID, zoomed }) {
		// Replace data into the large window if this is the new largest time frame
		if (zoomed) {
			commit('_setSmallWindowTelemetry', { data: data, deviceID: deviceID })
		} else {
			commit('_setLargeWindowTelemetry', { data: data, deviceID: deviceID })
		}
	},

	// ---- Context Actions ----
	/** Loads historical context data
	 * @param {import('vuex').ActionContext<DeepchartStore>} _
	 * @param {string} deviceID - Device to get context info of
	*/
	_fetchContextData({ state, dispatch, commit, getters }, deviceID) {
		const start = getters.getTimeWindowStart
		const end = getters.getTimeWindowEnd
		// Check if context already exists for this call, in which case skip hitting api
		if (deviceID in state.contextData) {
			if (start >= state.contextData[deviceID].start && end <= state.contextData[deviceID].end) {
				return
			}
		}
		// Data has not been previously loaded, hit the api
		dispatch('Context/generateHistoricalContext', {
			deviceID: deviceID,
			start: start,
			end: end
		}, { root: true }).then(results => {
			commit('_removeContext', deviceID)
			commit('_setContext', { deviceID: deviceID, context: results, start: start, end: end })
		})
	},

	// ---- Rules Actions ----

	/** Queries historical rule evaluations for active rules to display on the chart
	 * Once queried, rules are parsed and stored in `analyticData` and ultimately create custom vertical lines
	 * to be displayed on the chart
	 */
	_fetchRules({ state, commit, getters }) {
		const getRulesHelper = (deviceID) => {
			const start = getters.getTimeWindowStart
			const end = getters.getTimeWindowEnd
			const agg = state.chartSettings.aggTime
			// Check if data already exists and can be skipped
			if (deviceID in state.ruleQueries && agg === state.ruleQueries[deviceID].agg) {
				if (start >= state.ruleQueries[deviceID].start &&
					end <= state.ruleQueries[deviceID].end) {
					// No need to hit the API for this query, data already should exist
					return
				}
			}
			const endpoint =
				'/analytics/v1/devices/' + deviceID +
				'/start/' + start +
				'/end/' + end +
				'/agg/' + agg
			const jwt = {
				Authorization: localStorage.getItem('authToken')
			}
			axios.get(endpoint, { headers: jwt })
				.then((response) => {
					// Log the query
					commit('_setRuleDeviceQuery', { start, end, agg, deviceID })
					// Unpack incoming data
					// Only keep rule violations that are non zero
					const ruleViolations = {}
					for (const timestamp in response.data.data) {
						for (const ruleID in response.data.data[timestamp]) {
							if (response.data.data[timestamp][ruleID] !== 0) {
								if (!(ruleID in ruleViolations)) ruleViolations[ruleID] = []

								ruleViolations[ruleID].push({ timestamp: Number(timestamp), count: response.data.data[timestamp][ruleID] })
							}
						}
					}
					for (const ruleID in ruleViolations) {
						commit('_setRuleData', { start, end, agg, id: ruleID, data: ruleViolations[ruleID] })
					}
				})
				.catch((error) => {
					console.error('Error fetching data:', error)
				})
		}
		for (const details of Object.values(state.activeRules)) {
			getRulesHelper(details.device)
		}
	},

	// ---- Custom Lines Actions ----

	/** Adds a custom line to be graphed
	 * Supports two different varieties, either a number (generates a horizontal line)
	 * Or points at a data store and a regression line is generated from that
	 * Data will be added to customLines with one point at the start window and one at the end
	 *
	 * @param {import('vuex').ActionContext<DeepchartStore>} _
	 * @param {Object} p
	 * @param  {number|{value:number,time:number}[]} 	p.data 	- Either a number for a horizontal line or an array of objects with .value and .time attributes
	 * @param  {string} 								p.key 	- Unique string used to determine if the line already exists
	 * @param  {string} 								p.color - Color code for the line
	 */
	setCustomLine({ getters, commit }, { data, key, color }) {
		// If the line already exists remove it
		if (key in getters.getCustomLines) {
			commit('_removeCustomLine', key)
			return
		}

		// Generate new line
		let graphFunc = null
		if (isNaN(Number(data))) {
			if (getters.getChartSettings.normalize) {
				// @ts-ignore
				data = normalizeData(data)
			}
			// Generate a regression line used to graph the data points
			// @ts-ignore
			graphFunc = generateRegression(data).regressionFunction
		} else {
			// Simple line with no slope
			graphFunc = _ => data
		}
		const lineData = [
			{ time: getters.getTimeWindowStart, value: graphFunc(getters.getTimeWindowStart) },
			{ time: getters.getTimeWindowEnd, value: graphFunc(getters.getTimeWindowEnd) }
		]
		const tempLine = {
			data: lineData,
			color: color,
			strokeDashArray: 4,
			key: key
		}
		commit('_setCustomLine', { id: key, line: tempLine })
	},

	/** Toggle focus on a telemetry line via opacity change
	 * Triggered when the telemetry table emits a focus-line event
	 * @param {import('vuex').ActionContext<DeepchartStore>} _
	 * @param {DeepChartTelemetryInfo} telem - A single telemetry object (form from telemData) that has been toggled for focus
	 */
	focusLine({ getters, commit }, telem) {
		// if the telemetry name was selected, tell parent to toggle individual opacity
		for (const telemRow of Object.values(getters.getParsedTelemetryData)) {
			if (telemRow.key === telem.key) {
				// We already are focused on one line
				if (getters.getChartSettings.useIndividualOpacity) {
					// Line already focused meaning the user is turning it off, otherwise swapping active line
					if (telemRow.opacity === 1) {
						commit('setTelemetryOpacity', { key: telemRow.key, opacity: 0.25 })
						commit('setChartSettings', { name: 'useIndividualOpacity', value: false })
					} else {
						commit('setTelemetryOpacity', { key: telemRow.key, opacity: 1 })
						// trigger reactivity for chart to redraw:
						commit('setChartSettings', { name: 'useIndividualOpacity', value: false })
						commit('setChartSettings', { name: 'useIndividualOpacity', value: true })
					}
				} else {
					// First time we focus a line
					commit('setChartSettings', { name: 'useIndividualOpacity', value: true })
					commit('setTelemetryOpacity', { key: telemRow.key, opacity: 1 })
				}
			} else {
				// Disable all lines not selected
				commit('setTelemetryOpacity', { key: telemRow.key, opacity: 0.25 })
			}
		}
	},

	/** Parses all the current lines in `customLines` and if the creator of the line is no longer visible
	 * then remove that custom line
	 */
	filterDefunctCustomLines({ state, getters, commit }) {
		// Build up reference dict of the two tables being used to display data
		const validLines = new Set()
		for (const row of getters.getStatsTableData) {
			validLines.add(row.rowID)
		}
		for (const ruleID of Object.keys(state.activeRules)) {
			if (ruleID in state.analyticData) {
				for (const row of state.analyticData[ruleID].data) {
					validLines.add(ruleID + row.timestamp)
				}
			}
		}

		// Validate that the original source of the custom line still exists
		for (const lineID in state.customLines) {
			if (!(validLines.has(lineID.split('.')[0]))) {
				commit('_removeCustomLine', lineID)
			}
		}
	}
}

/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
	/** Sets the current data to be used as x-axis label on the deep chart
	 * If `useTime` is true then other data is discarded
	 *
	 * @param state
	 * @param {Object} p
	 * @param {boolean} p.useTime 	- When true time is used as x-axis label
	 * @param {string} 	p.key		- Telemetry key to be used to generate axis label data
	 * @param {string}	p.device	- DeviceID used to generate axis label data
	 */
	setAxisLabel(state, { useTime, key, device }) {
		if (useTime) {
			Vue.set(state.xAxisLabelInfo, 'useTime', true)
			Vue.set(state.xAxisLabelInfo, 'key', '')
			Vue.set(state.xAxisLabelInfo, 'device', '')
		} else {
			Vue.set(state.xAxisLabelInfo, 'useTime', false)
			Vue.set(state.xAxisLabelInfo, 'key', key)
			Vue.set(state.xAxisLabelInfo, 'device', device)
		}
	},
	/** Sets the currently selected value for a given telemetry, end result of mousing over the graph
	 * and generating a tooltip
	 *
	 * @param state
	 * @param {Object} p
	 * @param {string} p.key	- Telemetry key
	 * @param {number} p.value	- New value
	 */
	setSelectedTelemetryValue(state, { key, value }) {
		if (key in state.telemData) {
			Vue.set(state.telemData[key], 'selectedValue', value)
		} else {
			console.warn('Unable to set telemetry key, not in state.telemData', key)
		}
	},
	/** Sets the telemetry opacity to the passed in value
		 * @param state
		 * @param {Object} p
		 * @param {string} p.key - Telemetry key
		 * @param {number} p.opacity - New opacity value to be set
		 */
	setTelemetryOpacity(state, { key, opacity }) {
		if (key in state.telemData) {
			Vue.set(state.telemData[key], 'opacity', opacity)
		} else {
			console.warn('Unable to set opacity value, key not in telemData', key)
		}
	},
	/**
	 * @param state
	 * @param {Object} p
	 * @param {TelemetryWindow} p.data
	 * @param {string} 			p.deviceID
	 */
	_setLargeWindowTelemetry(state, { data, deviceID }) {
		Vue.set(state.largeTelemetryWindow, deviceID, data)
	},
	/**
	 * @param state
	 * @param {Object} p
	 * @param {TelemetryWindow} p.data
	 * @param {string} 			p.deviceID
	 */
	_setSmallWindowTelemetry(state, { data, deviceID }) {
		Vue.set(state.smallTelemetryWindow, deviceID, data)
	},
	/**
	 * @param {{aggTime:number,deviceID:string,newDataSet:SingleTelemetryObject[]}[]} data
	 */
	_setRawTelemetryData(state, data) {
		Vue.set(state, 'rawTelemetryData', data)
	},

	/** @param {Object<string,DeepChartTelemetryInfo>} data */
	_setTelemData(state, data) {
		Vue.set(state, 'telemData', data)
	},
	/** @param {Object<string, DataPoint[]>} data */
	_setNavBarData(state, data) {
		for (const [key, telem] of Object.entries(data)) {
			Vue.set(state._navBarData, key, telem)
		}
	},

	/**
	 * @param {Object} state
	 * @param {Object} p
	 * @param {string} 		p.deviceID
	 * @param {Context[]} 	p.context
	 * @param {number} 		p.start
	 * @param {number} 		p.end
	 */
	_setContext(state, { deviceID, context, start, end }) {
		// Check device is already setup
		if (!(deviceID in state.contextData)) {
			Vue.set(state.contextData, deviceID, { })
		}

		Vue.set(state.contextData[deviceID], 'data', context)
		Vue.set(state.contextData[deviceID], 'start', start)
		Vue.set(state.contextData[deviceID], 'end', end)
	},
	/** Removes context data for the given device
	 * @param {string} deviceID
	*/
	_removeContext(state, deviceID) {
		Vue.delete(state.contextData, deviceID)
	},
	/** Add a context label (by device id and context key name) to be displayed as labels on the chart
	 * @param state
	 * @param {Object} p
	 * @param {String} p.device		- the device ID
	 * @param {String} p.key		- the context key name
	 */
	addContextLabel(state, { device, key }) {
		state.contextLabels.push({
			device: device, key: key
		})
	},

	/** Remove a context label from being displayed on the chart
	 * @param state
	 * @param {Object} p
	 * @param {String} p.device	- the device ID
	 * @param {String} p.key	- the context key name
	 */
	removeContextLabel(state, { device, key }) {
		const i = state.contextLabels.findIndex((e) => e.device === device && e.key === key)
		if (i !== -1) {
			state.contextLabels.splice(i, 1)
		}
	},

	/** Modify the context shading device and context key. Set to empty strings to not display shading
	 * @param state
	 * @param {Object}  p
	 * @param {String}  p.device	- the device ID
	 * @param {String}  p.key		- the context key name
	 */
	setContextShading(state, { device, key }) {
		state.contextShadingDevice = device
		state.contextShadingKey = key
	},

	// ---- Chart Settings Mutations ----

	/** Update the chart settings map for a given setting name to the given value
	 * @param state
	 * @param {Object}  p
	 * @param {String}  		p.name		- Name of the setting (parameter) to update
	 * @param {string|number}  	p.value		- The value to update the setting parameter to
	 */
	setChartSettings(state, { name, value }) {
		if (name === 'curveTypeLookup') {
			// curve type needs to be handled separately, because functions can't be passed around vie events and html
			const curveTypeLookup = {
				linear: curveLinear,
				natural: curveNatural,
				basis: curveBasis,
				mono: curveMonotoneX,
				stepafter: curveStepAfter,
				stepbefore: curveStepBefore
			}
			Vue.set(state.chartSettings, 'curveType', curveTypeLookup[value])
		}
		Vue.set(state.chartSettings, name, value)
	},

	// ---- Rule Mutations ----

	/** Add a query log overriding any old info
	 * @param state
	 * @param {Object} p
	 * @param {number} p.start
	 * @param {number} p.end
	 * @param {number} p.agg
	 * @param {string} p.deviceID
	 */
	_setRuleDeviceQuery(state, { start, end, agg, deviceID }) {
		Vue.set(state.ruleQueries, deviceID, { start, end, agg })
	},
	/** Add a rule to be displayed via vertical lines on the chart
	 * @param state
	 * @param {Object}  p
	 * @param {String}  p.id		- The rule ID
	 * @param {String}  p.color		- The color to display the rule with, as a hex code
	 * @param {String}  p.device	- The device ID that the rule belongs to
	 */
	addActiveRule(state, { id, color, device }) {
		Vue.set(state.activeRules, id, { color: color, device: device })
	},

	/** Remove a rule currently being displayed on the chart
	 * @param {String} id	- Rule ID to remove
	 */
	removeActiveRule(state, id) {
		Vue.delete(state.activeRules, id)
	},

	/**
	 * @param state
	 * @param {Object} p
	 * @param {number} 			p.start
	 * @param {number} 			p.end
	 * @param {number} 			p.agg
	 * @param {string} 			p.id
	 * @param {ruleViolation[]} p.data
	 */
	_setRuleData(state, { start, end, agg, id, data }) {
		Vue.set(state.analyticData, id, {
			start: start,
			end: end,
			agg: agg,
			data: data
		})
	},

	// ---- Custom Line Mutations ----

	/**
	 * @param state
	 * @param {Object} p
	 * @param {string} 			p.id
	 * @param {DeepChartLine} 	p.line
	 */
	_setCustomLine(state, { id, line }) {
		Vue.set(state.customLines, id, line)
	},

	/** @param {string} id */
	_removeCustomLine(state, id) {
		Vue.delete(state.customLines, id)
	},

	/** Remove all custom lines from the chart */
	removeAllCustomLines(state) {
		Vue.set(state, 'customLines', {})
	},

	// ---- Telemetry Selection Mutations ----

	/** Activate a device (to have it's telemetry queried)
	 * @param {String[]} deviceIDs - The ID of the device being activated
	 */
	addActiveDevice(state, deviceIDs) {
		const tempMap = {}
		for (const deviceID of deviceIDs) {
			if (deviceID === '') continue

			if (!(deviceID in state.deviceStatus)) {
				tempMap[deviceID] = { telemKeys: {}, activeCount: 0, is_active: false }
			} else {
				tempMap[deviceID] = state.deviceStatus[deviceID]
			}
			tempMap[deviceID].activeCount += 1
			tempMap[deviceID].is_active = tempMap[deviceID].activeCount > 0
		}

		Vue.set(state, 'deviceStatus', Object.assign({}, state.deviceStatus, tempMap))
	},

	/** Remove a device from the active list when telemetry should not long be updated */
	removeActiveDevice(state, deviceIDs) {
		for (const deviceID of deviceIDs) {
			if (deviceID in state.deviceStatus) {
				state.deviceStatus[deviceID].activeCount -= 1
				Vue.set(state.deviceStatus[deviceID], 'is_active', state.deviceStatus[deviceID].activeCount > 0)
			}
		}
	},

	/** Add telemetry metadata to a device such that it can be activated/viewed by user
	 * @param state
	 * @param {Object}  p
	 * @param {String}  		p.deviceID	- The ID of the device that the telemetry belongs to
	 * @param {TelemetryMeta}  	p.telemObj	- The telemetry metadata object
	 */
	addDeviceTelemetryMetadata(state, { deviceID, telemObj }) {
		if (!(deviceID in state.deviceStatus)) {
			Vue.set(state.deviceStatus, deviceID, { telemKeys: {}, activeCount: 0, is_active: false })
		}
		Vue.set(state.deviceStatus[deviceID].telemKeys, telemObj.key, {
			is_active: false,
			color: state.COLORCALCULATOR(deviceID + '_' + telemObj.key),
			readableName: telemObj.readableName,
			description: telemObj.description,
			firstRecieved: telemObj.firstRecieved,
			lastRecieved: telemObj.lastRecieved,
			unit: telemObj.unit,
			str: telemObj.minVal === null // boolean value indicating type of telemetry
		})
	},

	/** Activate a telemetry key so that it is displayed on the chart
	 * @param state
	 * @param {Object}  p
	 * @param {String}  p.deviceID	- The ID of the device the telemetry belongs to
	 * @param {String}  p.telemKey	- The name/key of the telemetry being activated
	 * @param {String}  p.color		- The hex code color of the telemetry line
	 */
	addActiveTelemetry(state, { deviceID, telemKey, color }) {
		if (!(deviceID in state.deviceStatus)) {
			Vue.set(state.deviceStatus, deviceID, { telemKeys: {}, activeCount: 0, is_active: false })
		}
		if (!(telemKey in state.deviceStatus[deviceID].telemKeys)) { // if telemetry key is new, create nested object
			Vue.set(state.deviceStatus[deviceID].telemKeys, telemKey, {})
		}
		Vue.set(state.deviceStatus[deviceID].telemKeys[telemKey], 'is_active', true)
		Vue.set(state.deviceStatus[deviceID].telemKeys[telemKey], 'color', color)
	},

	/** Remove telemetry from being displayed on the chart
	 * @param state
	 * @param {Object}  p
	 * @param {String}  p.deviceID	- The ID of the device the telemetry belongs to
	 * @param {String}  p.telemKey	- The name/key of the telemetry being activated
	 */
	removeActiveTelemetry(state, { deviceID, telemKey }) {
		if (!(deviceID in state.deviceStatus)) {
			return
		}
		if (!(telemKey in state.deviceStatus[deviceID].telemKeys)) { // if telemetry key is new, create nested object
			return
		}
		Vue.set(state.deviceStatus[deviceID].telemKeys[telemKey], 'is_active', false)
	},

	// ---- Time Window Mutations ----

	/** Set the global start time of the chart
	 * @param {Number} start	- The start time in unix ms
	 */
	setTimeWindowStart(state, start) {
		state.timeWindowStart = start
	},

	/** Set the global end time of the chart
	 * @param {Number} end		- The end time in unix ms
	 */
	setTimeWindowEnd(state, end) {
		state.timeWindowEnd = end
	},

	/** Set the zoomed in start time of the chart
	 * @param {Number} start	- The start time in unix ms
	 */
	setTimeZoomStart(state, start) {
		state.timeZoomStart = start
	},

	/** Set the zoomed in end time of the chart
	 * @param {Number} end		- The end time in unix ms
	 */
	setTimeZoomEnd(state, end) {
		state.timeZoomEnd = end
	},

	_clearDataUpdateTimeout(state) {
		clearTimeout(state._dataUpdate)
	},

	// ---- Misc Mutations ----

	/** @param {Function} fn - Data fetch function to be run */
	_setDataUpdateTimeout(state, fn) {
		state._dataUpdate = setTimeout(fn, 300)
	},

	/** Sets the data loading flag to true of false
	 * @param {Boolean} loading	- loading flag state
	 */
	setDataLoading(state, loading) {
		state.dataLoading = loading
	},

	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultDeepChartState())
	}
}

/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {

	/** Gets telemetry that should be used as x-axis labels if needed.
	 * An empty array is considered a valid set of data, it simply means that no telemetry exists.
	 * A return of false means a telemetry key/device are not currently set and time should be used.
	 *
	 * @returns {false|{ts:number,value:number}[]}
	 */
	getAxisLabelTelemetry(state) {
		if (state.xAxisLabelInfo.useTime) return false

		const data = []
		for (const device of state.rawTelemetryData) {
			if (device.deviceID !== state.xAxisLabelInfo.device) continue

			for (const t of device.newDataSet) {
				if (state.xAxisLabelInfo.key in t.telemetry) {
					data.push({
						ts: t.start_ts,
						value: t.telemetry[state.xAxisLabelInfo.key].sum / t.telemetry[state.xAxisLabelInfo.key].count
					})
				}
			}
		}
		return data
	},
	/** Gets all information about x-axis label
	 *
	 * @returns {{useTime:boolean,key:string,device:string}}
	*/
	getXAxisLabelInfo(state) {
		return state.xAxisLabelInfo
	},
	/** Gets the data loading flag state
	 * @returns {Boolean}
	 */
	getDataLoading(state) {
		return state.dataLoading
	},

	// ---- Telemetry Getters ----

	/** Gets the raw telemetry for the chart
	 * @returns {{aggTime:number,deviceID:string,newDataSet:SingleTelemetryObject[]}[]}
	*/
	getRawTelemetryData(state) {
		return state.rawTelemetryData
	},

	/** Gets the parsed telemetry to be displayed on the chart
	 * @return {Object<string,DeepChartTelemetryInfo>}
	 */
	getParsedTelemetryData(state) {
		return state.telemData
	},

	/** Returns a data for the given device and time frame if it exists
	 * Note it returns the entire set of data that meets the given requirements
	 * So telemetry can be outside of the passed in ranges
	 */
	_getDeviceData: (state) =>
	/**
	 * @param {Object} p
	 * @param {string} p.deviceID
	 * @param {number} p.aggTime
	 * @param {number} p.start
	 * @param {number} p.end
	 *
	 * @returns {{data:boolean|TelemetryWindow, large:boolean}}
	 */
		({ deviceID, aggTime, start, end }) => {
			let data
			let large
			// To ensure that the data is the correct time resolution the start, end, and aggregation need to all match
			if (deviceID in state.largeTelemetryWindow &&
			aggTime === state.largeTelemetryWindow[deviceID].requestedAggregationTime &&
			start >= state.largeTelemetryWindow[deviceID].reqStart &&
			end <= state.largeTelemetryWindow[deviceID].reqEnd) {
				data = state.largeTelemetryWindow[deviceID]
				large = true
			}
			if (deviceID in state.smallTelemetryWindow &&
			aggTime === state.smallTelemetryWindow[deviceID].requestedAggregationTime &&
			start >= state.smallTelemetryWindow[deviceID].reqStart &&
			end <= state.smallTelemetryWindow[deviceID].reqEnd) {
				data = state.smallTelemetryWindow[deviceID]
				large = false
			}

			// No data found
			if (data === undefined) {
				return { data: false, large: false }
			}

			return { data: data, large: large }
		},

	// ---- Context Getters ----
	/** Returns the list of active device and keys for context labels
	 * @returns {{device: string, key:string}[]} */
	getContextLabels(state) {
		return state.contextLabels
	},
	/** Returns array of array of objects of active context labels
	 * Order will be the same as in contextLabels
	 *
	 * @returns {ContextLabel[][]}
	 */
	getContextLabelData(state) {
		// Map insertion order is saved
		/** @type {Map<string, ContextLabel[]>} */
		const data = new Map()
		/** @type {Set<string>} */
		const devices = new Set()
		for (const c of Object.values(state.contextLabels)) {
			data.set(c.device + c.key, [])
			devices.add(c.device)
		}
		// Search through each device for possible matches
		// @ts-ignore
		for (const d of devices) {
			if (d in state.contextData) {
				for (const c of state.contextData[d].data) {
					if (data.has(c.uid + c.key)) {
						data.get(c.uid + c.key).push({
							start: new Date(c.start_ts).getTime(),
							end: c.end_ts.Valid ? new Date(c.end_ts.Time).getTime() : new Date().getTime(),
							color: state.COLORCALCULATOR(c.value),
							colorOpacity: 0.5,
							label: c.key + ': ' + c.value,
							key: c.key
						})
					}
				}
			}
		}
		// Vue2 can't have reactivity on maps
		// Compress down to array of arrays
		const rows = []
		// @ts-ignore
		for (const r of data.values()) {
			rows.push(r)
		}
		return rows
	},
	/** Context shading data to be displayed
	 * @returns {ContextShading[]}
	 */
	getContextShadingData(state) {
		const data = []
		if (state.contextShadingDevice in state.contextData) {
			for (const c of state.contextData[state.contextShadingDevice].data) {
				if (state.contextShadingKey === c.key) {
					data.push({
						id: c.start_ts,
						start: new Date(c.start_ts).getTime(),
						end: c.end_ts.Valid ? new Date(c.end_ts.Time).getTime() : new Date().getTime(),
						color: state.COLORCALCULATOR(c.value),
						colorOpacity: 0.5,
						label: c.key + ': ' + c.value,
						key: c.key,
						value: c.value
					})
				}
			}
		}
		return data
	},
	/** Gets the context shading values, mapped to colors
	 * @returns {Object<string, string>}
	 */
	getContextShadingValues(state, getters) {
		/** @type {Object<string,string>} */
		const data = {}
		for (const c of getters.getContextShadingData) {
			data[c.value] = c.color
		}
		return data
	},

	/** Get the context shading device id
	 * @returns {String}
	*/
	getContextShadingDevice(state) {
		return state.contextShadingDevice
	},

	/** Gets the context shading context key
	 * @returns {String}
	 */
	getContextShadingKey(state) {
		return state.contextShadingKey
	},

	// ---- Chart Settings Getters ----

	/** Gets the chart settings
	 * @returns {DeepChartSettings}
	 */
	getChartSettings(state) {
		return state.chartSettings
	},

	// ---- Custom Lines Getters ----
	/** Generates line objects for deep chart from active rules
	 *
	 * @returns {Object<string,DeepChartLine>}
	*/
	_getRuleLines(state, getters) {
		/** @type {Object<string,DeepChartLine>} */
		const ruleLines = {}
		for (const ruleID in getters.getActiveRules) {
			if (ruleID in state.analyticData) {
				for (const ruleObj of state.analyticData[ruleID].data) {
					const points = [
						{ time: ruleObj.timestamp, value: -100000000 }, // hacky way to keep lines full height
						{ time: ruleObj.timestamp, value: 100000000 }
					]
					const tempLine = {
						data: points,
						color: state.activeRules[ruleID].color,
						strokedDashArray: 5,
						key: ruleID + ruleObj.timestamp
					}
					ruleLines[ruleID + ruleObj.timestamp] = tempLine
				}
			}
		}
		return ruleLines
	},
	/** Gets the custom lines to be displayed on the chart
	 * @returns {Object<string, DeepChartLine>}
	 */
	getCustomLines(state, getters) {
		return { ...state.customLines, ...getters._getRuleLines }
	},

	// ---- Rules Getters ----

	/** Gets the active rules that user has selected to display on the chart
	 * @returns {Object<String, activeRule>}
	 */
	getActiveRules(state) {
		return state.activeRules
	},

	// ---- Telemetry Selection Getters ----

	/** Gets the device status, detailing which devices and telemetry keys are active
	 * @returns {Object<string, deviceStatusObject>}
	 */
	getDeviceStatus(state) {
		return state.deviceStatus
	},

	/** Returns an object that represents any active telemetry
	 * A deviceID will only be present if it has at least one active telemetry key
	 * Top level keys are deviceIDs, each points to an array of strings of active telemetry for that device
	 * {
	 * 		<deviceID>: [<Telemetry Names>]
	 * }
	 * @returns {Object<string, string[]>}
	 */
	getActiveTelemetryKeys(state, getters) {
		/** @type {Object<string, string[]>} */
		const activeTelem = {}
		for (const deviceID of getters.getActiveDeviceIDs) {
			for (const telemKey in state.deviceStatus[deviceID].telemKeys) {
				if (state.deviceStatus[deviceID].telemKeys[telemKey].is_active) {
					if (!(deviceID in activeTelem)) activeTelem[deviceID] = []
					activeTelem[deviceID].push(telemKey)
				}
			}
		}
		return activeTelem
	},

	/** List of deviceID's that are on the machine being viewed
	 * Note that an active device does not mean telemetry is being displayed
	 * It means the device should be ready to display data
	 * @returns {String[]}
	 */
	getActiveDeviceIDs(state, getters) {
		const deviceIDs = []
		for (const [deviceID, details] of Object.entries(getters.getDeviceStatus)) {
			// console.log('details sanity check', JSON.parse(JSON.stringify(details)))
			if (details.is_active) deviceIDs.push(deviceID)
		}
		return deviceIDs
	},

	// ---- Time Window Getters

	/** Gets start of global time window in unix ms
	 * @returns {Number}
	 */
	getTimeWindowStart(state) {
		return state.timeWindowStart
	},

	/** Gets end of global time window in unix ms
	 * @returns {Number}
	 */
	getTimeWindowEnd(state) {
		return state.timeWindowEnd
	},

	/** Gets start of zoomed time window in unix ms
	 * @returns {Number}
	 */
	getTimeZoomStart(state) {
		return state.timeZoomStart
	},

	/** Gets end of zoomed time window in unix ms
	 * @returns {Number}
	 */
	getTimeZoomEnd(state) {
		return state.timeZoomEnd
	},

	/**
	 * Used to check if the currently viewed time is a local window or if it is using the stores start/end times
	 * Used to show and hide the 'Apply Viewed Time' button to re-query data using the current window as the min/max
	 * @returns {Boolean}
	 */
	getChartZoomed(state) {
		return (state.timeWindowStart !== state.timeZoomStart ||
		state.timeWindowEnd !== state.timeZoomEnd)
	},

	// ---- Stats Table Getters ----

	/** Creates an object to be used with the table component
	 * @returns {Object<string,number> & {rowID:string,rowColor:string}[]}
	*/
	getStatsTableData(_, getters) {
		const rows = []
		try {
		// Generate entries to be used in the statistics table
			for (const [key, deviceTelem] of Object.entries(getters.getParsedTelemetryData)) {
				const newRow = {
					rowID: deviceTelem.key,
					rowColor: deviceTelem.color
				}

				// Check if the entire line should be bolded
				// Individual cell can also be bolded if key has a line being drawn (tested in each cell in the template)
				let bold = false
				if (getters.getChartSettings.useIndividualOpacity) {
					if (deviceTelem.opacity === 1) {
						bold = true
					}
				}

				// Set value to row so we can access in template to bold the whole row
				newRow.opacity = bold

				// Add Selected Value & Device UID & Telemetry name
				newRow.selectedValue = deviceTelem.selectedValue
				newRow.device = deviceTelem.uid.substring(0, 6)
				newRow.telemetryName = deviceTelem.telemKey

				// Add all statistics
				for (const statName in deviceTelem.statistics) {
					const value = parseFloat(deviceTelem.statistics[statName])

					const cell = value === undefined ? 'N/A' : value.toFixed(2)

					newRow[statName] = cell
				}
				rows.push(newRow)
			}
		} catch (error) {
			console.error(error)
		}
		return rows
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
