<template>
  <div
    class="table-container"
  >
    <div :class="{sticky: stickyHeader}">
      <!-- Everything in this group will stick to the top of the page when scrolling -->

      <!-- If there is a title specified, include this header row -->
      <div
        v-if="title != ''"
        class="table-header"
      >
        <!-- Title of table -->
        <div
          class="header-title"
          :class="{long:!enableSearch}"
        >
          {{ $t(title) }}
        </div>
        <!-- Search box -->
        <div
          v-if="enableSearch"
          class="table-search"
        >
          <FontAwesomeIcon
            class="fa-md icon-color"
            :icon="['fa', 'search']"
          />
          <input
            v-model="searchTerm"
            :placeholder="$t('search')"
          >
        </div>
        <!-- Actions to filter and create new -->
        <div class="header-actions">
          <!-- Only display filter button if there are filters to use -->
          <div
            v-if="filters.length != 0"
            class="clickable filter-btn"
            :class="{active: Object.values(userFilters).filter(x => x !== '').length > 0}"
            @click="filterActive = !filterActive"
          >
            <FontAwesomeIcon
              class="fa-md clickable icons filter-icon icon-color"
              :icon="['fas', 'filter']"
            />
            {{ $t("Filter") }}
          </div>
          <slot :name="'newBtn'">
            <ampButton
              v-if="newBtn"
              @click="$emit('createNew')"
            >
              {{ $t(buttonText) }}
            </ampButton>
          </slot>
        </div>
      </div>

      <!-- Filter dropdown -->
      <transition name="list-grow-shrink">
        <div
          v-if="filters.length !== 0 && filterActive"
          :key="filterActive"
          class="filter-container"
        >
          <!-- List of select dropdown to filter by -->
          <div class="inputs">
            <div
              v-for="filter in filters"
              :key="filter.title"
              class="filter-select-container"
            >
              <ampSelect
                v-model="userFilters[filter.field]"
                class="select"
                :label="`${filter.title}:`"
                default-label="translationString.All"
                :enable-default="true"
                :options="filterValues[filter.field]"
              />
            </div>
          </div>
          <!-- Buttons to clear filter and close panel -->
          <div class="filter-btns">
            <!-- TODO: Mobile will look different -->
            <ampButton
              :solid-color="false"
              @click="clearFilters()"
            >
              {{ $t("Clear Filters") }}
            </ampButton>
            <ampButton
              :solid-color="false"
              @click="filterActive = false"
            >
              {{ $t("Close") }}
            </ampButton>
          </div>
        </div>
      </transition>

      <!-- Title Row in table -->
      <div class="table">
        <div class="table-row title">
          <!-- Sortable column headers -->
          <div
            v-for="(col, index) in columns"
            :key="col.id"
            class="table-col table-title"
            :class="{clickable:enableSort, compressed:compressRows}"
            :style="columnWidth(col.id)"
            @click="sortColumn(index)"
          >
            <div>{{ $t(col.text) }}</div>
            <div
              v-if="'sortField' in col"
              class="col-indicator"
              :class="{'active': index === sortColIndex, 'inverse': index === sortColIndex && !sortDescending}"
            >
              <FontAwesomeIcon
                v-if="enableSort"
                class="fa-md clickable icons filter-icon"
                :icon="['fa', 'arrow-down']"
              />
            </div>
          </div>
          <!-- Extra blank column if we allow rows to expand -->
          <div
            v-if="expand"
            class="table-col expand"
          />
        </div>
      </div>
    </div>

    <!-- Main Table Content -->
    <div class="table">
      <!-- Loop through rows -->
      <template v-for="row in validRows">
        <!-- Provide a named + scoped slot for each column of the row -->
        <div
          :key="row.id"
          class="table-row"
        >
          <div
            v-for="col in columns"
            :key="col.id"
            class="table-col"
            :style="columnWidth(col.id)"
            :class="{mobile: col.mobileTitle, compressed:compressRows}"
          >
            <slot
              :name="col.id"
              v-bind="row"
            >
              <!-- Default slot is just indexing into the row with the column id -->
              {{ row[col.id] }}
            </slot>
          </div>
          <div
            v-if="expand"
            class="table-col expand"
            @click="rowDetailsClick(row.id)"
          >
            <div v-if="expandedIDs[row.id]">
              {{ $t(`Less Details`) }}
            </div>
            <div v-else>
              {{ $t(`More Details`) }}
            </div>
          </div>
        </div>
        <!-- If the row is expanded, provide an additional slot in the space below the row -->
        <transition
          :key="row.id+'exp'"
          name="list-grow-shrink"
        >
          <div
            v-if="expandedIDs[row.id]"
            :key="row.id+'exp'"
          >
            <div
              :colspan="expand? columns.length + 1 : columns.length"
              class="expanded-content"
            >
              <!-- Mobile Expanded table: -->
              <div class="mobile-table-container">
                <div
                  v-for="col in columns.filter(col => !col.mobileTitle)"
                  :key="col.id"
                  class="table-row mobile-exp-table"
                >
                  <div class="table-col mobile table-title">
                    {{ $t(col.text) }}
                  </div>
                  <div
                    class="table-col mobile"
                  >
                    <slot
                      :name="col.id"
                      v-bind="row"
                    >
                      <!-- Default slot is just indexing into the row with the column id -->
                      {{ row[col.id] }}
                    </slot>
                  </div>
                </div>
              </div>
              <slot
                :name="'expanded-content'"
                v-bind="row"
              />
            </div>
          </div>
        </transition>
      </template>
      <!-- If no rows are found: -->
      <div
        v-if="validRows.length === 0"
        class="table-row no-rows"
      >
        {{ emptyRowText }}
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

// FA Icons:
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faFilter, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faSearch, faFilter, faArrowDown)

/** Generic table component
 *
 * - Table can easily display passed in row data
 * - Slots can be used to have more detailed row content
 * - Table supports expanding an individual row
 *
 * By default values will be sorted by the first column in ascending order.
 * Default descending can be set via the columns prop.
 * - Ascending: Up arrow, a .. z,  0 ... 9
 * - Descending: down arrow, z ... a,  9 ... 0
 * Emits:
 *  - `search-changed`: Happens anytime the search text changes, emits the new text
 */
export default {
	components: { FontAwesomeIcon },
	props: {
		/** A title for the table. The title row will be excluded if left blank */
		title: {
			type: String,
			default: ''
		},

		/** If the rows are allowed to expand */
		expand: {
			type: Boolean,
			default: false
		},

		/** List of row objects, must include 'id' parameter
     * @type {import('vue').PropOptions<(Object<string,number> & {id:string})[]>}
    */
		rows: {
			type: Array,
			default: () => []
		},

		/** List of columns, objects:
     *  - 'id': unique id of column, should correspond to row parameter
     *  - 'text': column header text
     *  - 'sortField': which row parameter should the column sort on. If not present, column will not sort
     *  - 'mobileTitle': boolean flag that will display column on table when in mobile view
     *  - 'sortDescending': Boolean flag controlling default sort direction when column first is used
     * @type {import('vue').PropOptions<{id:String, text:String, sortField?:String,mobileTitle?:Boolean,sortDescending?:Boolean}[]>}
     */
		columns: {
			type: Array,
			default: () => []
		},

		/** list of filter fields, objects that must include 'title' and 'field'
     * @type {import('vue').PropOptions<{title:string,field:string}[]>}
    */
		filters: {
			type: Array,
			default: () => []
		},

		/** This text will appear when no rows exist */
		emptyRowText: {
			type: String,
			default: 'No Rows Found'
		},

		/** To hide the search input flip to false */
		enableSearch: {
			type: Boolean,
			default: true
		},

		/** Uses a more in depth search instead of direct string matching */
		enableKeywordSearch: {
			type: Boolean,
			default: false
		},

		/** Hides ability to sort by columns */
		enableSort: {
			type: Boolean,
			default: true
		},

		compressRows: {
			type: Boolean,
			default: false
		},

		/** Define a list of keys in the row that are searchable
     * @type {import('vue').PropOptions<string[]>}
    */
		searchInclude: {
			type: Array, // of strings
			default: () => []
		},

		/** Flag that will toggle display of "+ New" button in title */
		newBtn: {
			type: Boolean,
			default: true
		},

		/** Controls the text inside of the new button  */
		buttonText: {
			type: String,
			default: '+ New'
		},

		/** This text is auto populated into the search bar upon creations, then never used again */
		defaultSearchValue: {
			type: String,
			default: ''
		},

		/** Controls if a column is going to consume more or less size
     * Should always be <Column ID,Integer representing % of table for this column to use>
     * All columns not listed have the remainder of the space evenly split
     * @type {import('vue').PropOptions<Object<string,number>>}
     */
		propColumnSizes: {
			type: Object,
			default: () => { return {} }
		},

		/** Will make the table title and column headers stick to the top of the page when scrolling */
		stickyHeader: {
			type: Boolean,
			default: true
		}
	},

	data() {
		return {
			/** Used to tell which rows are expanded. Key is The ID of the row currently expanded,
       * value is a boolean where true = expanded
       * @type {Object<string,boolean>}
       */
			expandedIDs: {},
			/** The user (sub)string that rows must have a matching value for
       * During creation will take on the value of defaultSearchValue
      */
			searchTerm: '',
			/** The column index being sorted on */
			sortColIndex: 0,
			/** Sort order */
			sortDescending: false,
			/** An object mapping filterFields (row keys) to filter values. Empty string value = no filter */
			userFilters: {},
			/** Flag to indicate filter panel is open (true) or hidden (false) */
			filterActive: false
		}
	},

	computed: {

		/** The sorted rows to display matching the filter + search parameters
     * @returns {Object<string,number|string>[]}
    */
		validRows() {
			// If there are now columns we are done
			if (this.columns.length === 0) return []

			// First, sort
			if (!this.sortColIndex in this.columns) return []

			const sortField = this.columns[this.sortColIndex].sortField
			let rowsCopy = [...this.rows]

			if (sortField !== undefined) {
				rowsCopy.sort((a, b) => {
					const aVal = String(a[sortField])
					const bVal = String(b[sortField])

					// Force undefined and null to always have the lowest value
					if (aVal === 'undefined' || aVal === 'null') return this.sortDescending ? -1 : 1
					if (bVal === 'undefined' || bVal === 'null') return this.sortDescending ? 1 : -1

					// Sort the rest naturally
					let sortVal = aVal.localeCompare(bVal,
						this.$root.$i18n.locale,
						{ numeric: true, sensitivity: 'base' }
					)
					// Reverses the ordering if needed
					if (this.sortDescending) {
						sortVal = sortVal * -1
					}

					return sortVal
				})
			}

			// Then filter
			if (this.filters.length > 0) {
				for (const [filterKey, filterVal] of Object.entries(this.userFilters)) {
					rowsCopy = rowsCopy.filter(
						rowObj => {
							return (filterVal === '') || (filterKey in rowObj && rowObj[filterKey] === filterVal)
						}
					)
				}
			}

			// Trim by search
			// Search for exact full string matches
			if (!this.enableKeywordSearch) {
				if (this.searchTerm === '' || this.searchInclude.length === 0) {
					return rowsCopy
				} else {
					return rowsCopy.filter(
						rowObj => Object.entries(rowObj).find(
							keyVal =>
								this.searchInclude.includes(keyVal[0]) &&
              keyVal[1] && // Make sure value exists (isn't null/undef)
              keyVal[1].toString().toLowerCase().includes(this.searchTerm.toLowerCase())
						) !== undefined
					)
				}
			} else {
				// Search for individual keyword matches
				if (!this.searchTerm?.trim()) {
					return rowsCopy
				} else {
					const searchTermParts = this.searchTerm.toLowerCase().split(' ').filter(part => part)

					return rowsCopy.filter(
						rowObj => this.searchInclude.some(
							key => {
								const valueNormalized = rowObj[key]?.toString().toLowerCase()
								return valueNormalized && searchTermParts.some(part => valueNormalized.includes(part))
							}
						)
					)
				}
			}
		},

		/** A map of values in the filter selects. Key is the filter name, values is an array of selectable
         * values to display as options
         * @returns {Object<string,Set<string>>} of type:
         *  {
         *      <filter id>: [<options>,]
         *  }
         */
		filterValues() {
			/** @type {Object<string,Set<string>>} */
			const filterVals = {}
			for (const filter of this.filters) {
				filterVals[filter.field] = new Set()
			}
			for (const row of this.rows) {
				for (const filterKey of Object.keys(filterVals)) {
					if (filterKey in row) {
						filterVals[filterKey].add(row[filterKey])
					} else {
						console.warn("Can't find filter key", filterKey, 'in table row', row)
					}
				}
			}
			return filterVals
		}
	},

	watch: {
		/** When rows change, update the expanded IDs object */
		rows: {
			handler: function() {
				if (this.expand) {
					for (const row of this.rows) {
						if (!(row.id in this.expandedIDs)) {
							Vue.set(this.expandedIDs, row.id, false)
						}
					}
				}
			},

			immediate: true
		},

		/** When filters change, update userFilters objects */
		filters: {
			handler: function() {
				for (const filter of this.filters) {
					Vue.set(this.userFilters, filter.field, '')
				}
			},

			immediate: true
		},

		/** Anytime a new column is selected it might have a default sorting direction passed in */
		sortColIndex: {
			handler() {
			// Check if the columns has passed in a default
				if (this.columns[this.sortColIndex].sortDescending) {
					this.sortDescending = true
				} else {
					this.sortDescending = false
				}
			},

			immediate: true
		},

		/** When search input changes emit the value to the parent */
		searchTerm() {
			this.$emit('search-changed', this.searchTerm)
		},

		/** If parent changes the default search term, mirror changes */
		defaultSearchValue: function() {
			// With a watcher on the default search value, a delayed reaction can trigger never ending loops of calls
			// This is because of a delay in how the routing query system works
			clearTimeout(this.defaultSearchValueTimeout)
			this.defaultSearchValueTimeout = setTimeout(() => {
				this.searchTerm = this.defaultSearchValue
			}, 750)
		},

		// if column layout changes, reset the table state
		columns() {
			this.expandedIDs = {}
			this.sortColIndex = 0
			this.searchTerm = ''
			this.sortDescending = false
			this.userFilters = {}
			this.filterActive = false
		}
	},

	created() {
		this.searchTerm = this.defaultSearchValue
	},

	methods: {
		/** Is used to dynamically calculate column width to better align the table
     * Returns an object intended to be used in ":style" in the template
     * @prop {String} column - Name of the column to get the width of
     * @returns {Object<"--col-basis",String>} - Returns an object to be used as style
    */
		columnWidth: function(column) {
			const sets = Object.keys(this.propColumnSizes).length
			let reduceAmount = 0
			for (const reserved of Object.values(this.propColumnSizes)) {
				reduceAmount += reserved
			}
			// If there is no preset for this column return the shared size
			if (!(column in this.propColumnSizes)) {
				const colBasis = (100 - reduceAmount) / ((this.columns?.length - sets) + (this.expand ? 1 : 0))
				return { '--col-basis': (Math.round(colBasis * 100) / 100).toString() + '%' }
			}
			return { '--col-basis': `${this.propColumnSizes[column]}%` }
		},

		/** When a row is clicked, expand it
         * @param {String} id   - Row ID being expanded
         */
		rowDetailsClick(id) {
			Vue.set(this.expandedIDs, id, !this.expandedIDs[id])
		},

		/** When a column header is clicked on to sort, change sort parameters
         * @param {Number} index    - Index of clicked column in column array
         */
		sortColumn(index) {
			if (this.sortColIndex === index) {
				// If this column is already sorting, reverse order
				this.sortDescending = !this.sortDescending
			} else {
				this.sortColIndex = index
			}
		},

		/** Clear the values in the filters */
		clearFilters() {
			for (const filterKey of Object.keys(this.userFilters)) {
				Vue.set(this.userFilters, filterKey, '')
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.icon-color{
  color: #1b2a38
}
.sticky {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}
.table-container {
    width: 100%;
    height: fit-content;
    border-radius: 11px;
    box-shadow: 0 5px 10px 0 rgba(45, 45, 45, 0.1);
    background-color: #ffffff;

    .table-header {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        border-bottom: solid 2px #c0c0c0;
        padding: 15px 30px;
        @media (max-width: 425px) {
              flex-flow: column wrap;
              height: 120px;
              padding: 12px;
        }

        .header-title {
            margin: 10px 0 10px 0;
            font-family: Helvetica;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.88;
            letter-spacing: normal;
            color: #1b2a38;
            flex-basis: 30%;
            &.long{
              flex-basis: unset;
            }
        }

        .table-search {
            flex-basis: 30%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            padding: 0px 10px;
            border-color: transparent;
            border-radius: 20px;
            background-color: #f2f2f2;
            max-height: 40px;
            flex-grow: 1;
            margin: 0px 15px;
            max-width: 320px;
            @media (max-width: 425px) {
              flex-shrink: 1;
              margin: 0 0 0 0;
            }
            input {
                width: 100%;
                display: block;
                padding-left: 5px;
                text-align: left;
                text-align-last: left;
                border-color: transparent;
                border-radius: 20px;
                background-color: #f2f2f2;
                box-shadow: none;
                height: 100%;
                &::placeholder {
                    font-family: Helvetica;
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }
        }

        .header-actions {
            flex-basis: 30%;
            display: flex;
            flex-flow: row;
            justify-content: flex-end;
            align-items: center;
            @media (max-width: 425px) {
              flex-flow: column;
              justify-content: flex-end;
              align-items: flex-end;
              min-height: 94px;
            }
            .filter-btn {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-evenly;
                align-items: center;
                height: 40px;
                border-radius: 5px;
                padding: 0 5px;
                width: 75px;
                @media (max-width: 425px) {
                  margin-bottom: 15px;
                }
            }
            button {
              margin-left: 30px;
              padding: 12px 25px 11px 24px;
            }
        }
    }
    .filter-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        .inputs {
            width: 100%;
            display: flex;
            padding: 20px 10px;
            flex-flow: row wrap;
            justify-content: center;
            .filter-select-container {
              display: flex;
              flex-flow: column nowrap;
              align-items: flex-start;
              flex-shrink: 1;
              flex-grow: 1;
              margin: 9px 5px;
              max-width: 300px;
              @media (max-width: 425px) {
                flex-basis: 100%;
                max-width: none;
              }
              .select {
                  width: 100%;
              }
            }
        }
        .filter-btns {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-evenly;
            margin-bottom: 20px;
            button {
                margin: 0 5px;
                height: 40px;
                padding: 12px auto;
                max-width: 300px;
                @media (max-width: 425px) {
                  flex-basis: 100%;
                  max-width: none;
                  margin: 5px 15px;
                }
                width: 100%;
                flex-shrink: 1;
                flex-basis: 50%;
                border-radius: 5px;
                text-align: center;
                &.inverse {
                    color: #1b2a38;
                    background-color: #ffffff;
                    border: 2px solid #1b2a38;
                }
            }
        }
    }
}

.table {
    .table-row {
        display:flex;
        flex-flow: row nowrap;

        &.title {
          @media (max-width: 425px) {
              display: none;
          }
        }
        &.mobile-exp-table {
          @media (min-width: 426px) {
            display: none;
          }
        }

        &.no-rows {
            display: block;
            text-align: center;
            padding: 10px;
            width: 100%;
            color: #898989
        }
    }
    .mobile-table-container {
      @media (max-width: 425px) {
        margin: 5px;
      }
      border-radius: 11px;
      box-shadow: 0 5px 10px 0 rgba(45, 45, 45, 0.1);
      overflow: hidden;
    }

    .table-col {
        flex-grow: 1;
        overflow-x: hidden;
        flex-basis: var(--col-basis);
        width: var(--col-basis);
        border: solid 1px #f2f2f2;
        padding: 14px;
        font-family: Helvetica;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        &.compressed{
          padding: 0px;
        }
        @media (max-width: 425px) {
              display: none;
          }
        &.mobile {
          @media (max-width: 425px) {
              display: flex;
          }
        }

        &.table-title {
            font-weight: normal;
            line-height: 1.5;
            color: #898989;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            .col-indicator {
                color: #898989;

                &.inverse {
                    transform: rotate(180deg);
                }
                &.active {
                    color: #1b2a38;
                }
            }

        }

        &.expand {
            flex-basis: 130px;
            flex-grow: 0;
            flex-shrink: 0;
            font-weight: bold;
            line-height: 1.29;
            color: #1b2a38;
            text-decoration: underline;
            cursor: pointer;
            @media (max-width: 425px) {
              display: flex;
          }
        }
    }
}

.list-grow-shrink {
    // Handles expanding of row
    &-enter,
    &-leave-to{
        opacity: 0;
        max-height: 0px;
    }
    &-enter-to,
    &-leave{
        opacity: 1;
        max-height: 435px;
    }
    &-enter-active,
    &-leave-active{
        transition: all 0.2s linear;
    }
}
</style>
