
import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import axios from 'axios'
import { getActiveUserRole, getActiveUserCustomerID } from '../../helpers/jwt'

Vue.use(Vuex)

/** Vuex Store's State Properties
 * @prop {Boolean} loadingDependencies				- Boolean flag that is true when checkDependencies is running
 */
export const getDefaultState = () => {
	return {
		loadingDependencies: false
	}
}
export const storeState = getDefaultState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {
	/** Top level action called by all Vue's to populate state with necessary data (if it does not exist)
	 *	This is especially useful on page refresh, where state is cleared. As opposed to link navigation, where the state persists.
	 * @returns {Promise} Axios promise with status of request
	 */
	checkDependencies({ getters, dispatch, commit }) {
		commit('_loadingDependencies', true)

		// Returns an array of arrays that is reduced to sequentially execute each item in order with promises.
		// The functions are executed with a Promise.all with the other functions in their array
		// Each function (object) is composed of the action name and the arguments to pass to that function
		// The arguments must be an anonymous function that can be called such that the contents are evaluated lazily
		// A function (object) can choose to not execute itself by setting the action name to an empty string
		return [
			[
				(function() {
					// We don't need to reload permissions every time if we already have them
					if (getters['Permissions/getPermissions'] === null) return { action: 'Permissions/fetchPermissions', args: () => {} }
					else return { action: '', args: () => {} }
				})(),
				(function() {
					// We don't need to reload roles every time if we already have them
					if (getters['Roles/getRoles'] === null) return { action: 'Roles/fetchRoles', args: () => [getActiveUserCustomerID()] }
					else return { action: '', args: () => {} }
				})(),
				// Always check permissions for active user's customer and role
				{ action: 'Permissions/fetchPermissionsForCustomerRoles', args: () => { return { customerId: getActiveUserCustomerID(), roles: [getActiveUserRole()] } } },
				(function() {
					if (getters['Customers/getCustomers'] === null || !(getActiveUserCustomerID() in getters['Customers/getCustomers'])) {
						// Only query customers if they haven't been queried yet or we don't have the user's customer
						return { action: 'Customers/fetchCustomers', args: () => {} }
					} else {
						return { action: '', args: () => {} }
					}
				})()
			],
			[ // Get users and locations
				{
					action: 'Users/fetchUsers',
					args: () => Object.keys(getters['Customers/getCustomers']).filter(id => !getters['Users/getQueried'].has(id))
					// The following pattern is used to decide which (if any) entities to query
					// Set1 = All parent entity ids in the store (so for locations, thats all customer ids)
					// Set2 = All parent entity ids that have already been queried (this is handled in the entities store)
					// The INTERSECTION of Set1 and Set2 is used as the parameter list for the generate call. This can be an empty list, and the promise
					// will resolve without executing an API call
				},
				{
					action: 'Locations/fetchLocations',
					args: () => Object.keys(getters['Customers/getCustomers']).filter(id => !getters['Locations/getQueried'].has(id))
				},
				// Get machines
				{
					action: 'Machines/fetchMachines',
					args: () => Object.keys(getters['Customers/getCustomers']).filter(id => !getters['Machines/getQueried'].has(id))
				},
				// Get Devices
				{
					action: 'Devices/fetchDevices',
					args: () => Object.keys(getters['Customers/getCustomers']).filter(id => !getters['Devices/getQueried'].has(id))
				},
				// Get the rest of the roles for all customer groups, technically a duplicate call but easiest to handle it this way
				{
					action: 'Roles/fetchRoles',
					args: () => Object.keys(getters['Customers/getCustomers']).filter(id => !getters['Roles/getQueried'].has(id))
				}
			]
		].reduce(
			// Reduce array by executing promises sequentially in order
			(p, x) =>
				p.then(() => {
					return Promise.all(
						// For each grouping of functions, execute them in parallel and wait for them all to resolve
						x.map(
							x => {
								if (x.action === '') return Promise.resolve()
								else return dispatch(x.action, x.args())
							}
						)
					)
				}).catch(error => console.error('Error loading dependencies', error)),
			Promise.resolve()
		).finally(_ => {
			commit('_loadingDependencies', false)
		})
	},

	/** Send API request to logout. Removes local JWT, wipes Vuex store */
	logout({ commit }) {
		axios
			.post(
				'/api/v1/users/logout',
				{},
				{
					headers: {
						Authorization: localStorage.getItem('authToken')
					}
				}
			).catch(error => {
				console.error('Error logging out:', error)
			})
		// Regardless of the success of the API call, still clear localstorage and state, then route to login
		localStorage.clear()
		commit('resetState')
		// @ts-ignore
		router.app.navToURL('/login')
	},

	resetState({ commit }) {
		commit('resetState') // Reset global state
		for (const module of Object.keys(this._modulesNamespaceMap)) {
			// For each namespaced module, call their resetState mutation
			commit(module + 'resetState')
		}
	}

}

export const storeMutations = {
	/** Set loading flag for loading dependencies
	 * @param {Boolean} loading
	 */
	_loadingDependencies(state, loading) {
		state.loadingDependencies = loading
	},

	/** Global state resetter. Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultState())
	}

}

export const storeGetters = {
	dependencyLoading(state) {
		return state.loadingDependencies
	}
}

export default {
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
