
import Vue from 'vue'
import Vuex from 'vuex'

// Auto exported modules
import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules,
	// Strict monitors the store state for any changes to happen outside of a mutation. It will throw an error if it happens
	strict: process.env.NODE_ENV !== 'production'
})

export default store
