import axios from 'axios'
import Vue from 'vue'

/** Locations state information
 * @typedef {Object} LocationStore
 * @prop {Object<string,AmpLocation>|null} locations - LocationID as keys. Null if not initialized, empty if no locations found.
 * @prop {Set<string>} queried
 */

/** @returns {LocationStore} */
export const getDefaultLocationState = () => {
	return {
		locations: null,
		queried: new Set()
	}
}

export const storeState = getDefaultLocationState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {

	/** Creates a Location from the passed in body
	 * 		Note: Latitude and Longitude are assigned to the location when they are added to the db, values passed from
	 * 			  here are used as defaults
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object}  p
	 * @param {String} 	p.address		- The Location's Address
	 * @param {String} 	p.city			- The Location's City
	 * @param {String} 	p.country		- The Location's Country
	 * @param {Date} 	p.createdTime	- The time that this Location was added
	 * @param {String} 	p.customerId	- The Customer Attached to this location
	 * @param {String} 	p.name			- The Location's Name
	 * @param {String} 	p.locState		- The Location's State, named to not conflict with store.state
	 *
	 * @returns {Promise}
	 */
	createLocation({ dispatch }, { address, city, country, createdTime, customerId, name, locState }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		// Whatever latitude and longitude are set to will be what is returned if the address is incorrect or not found
		const body = {
			address: address,
			city: city,
			country: country,
			created_at: createdTime,
			customer_id: customerId,
			latitude: 25,
			longitude: -71,
			name: name,
			state: locState
		}
		return axios
			.post(
				'/api/v1/locations', body, {
					headers: jwt,
					timeout: 5000
				}
			)
			.then(() => {
				return dispatch('fetchLocations', [customerId])
			})
			.catch((error) => {
				console.error('Error creating location', error)
			})
	},

	/** Fetch all locations from remote API and load it into the store
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String[]} customerIDs List of customer ids to fetch locations for
	 *
	 * @returns {Promise} Axios promise all for fetching locations from api
	 */
	fetchLocations({ commit }, customerIDs) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		/** Create a promise factory to get locations for each customer
		* @param {String} customerID customer ID to fetch locations for
		* @returns {Promise} Axios promise object with results of getting locations for customer
		*/
		function getLocationsForCustomer(customerID) {
			return axios
				.get(`/api/v1/customers/${customerID}/locations`, {
					headers: jwt,
					timeout: 5000
				})
				.then((response) => {
					// Translate location's parameter names from the API keys to javascript friendly keys
					// These keys are documented in state declaration at the top of the page as well
					const translatedLocations = {}
					for (const location of response.data.locations) {
						translatedLocations[location.id] = location // Leaving for later, not translating keys right now.
					}
					commit('_setLocations', translatedLocations)
				})
				.catch((error) => {
					console.error('Error loading locations', error)
				}).finally(_ => {
					commit('_setQueried', customerID)
				})
		}
		const getLocationPromises = []
		for (const customerID of customerIDs) {
			getLocationPromises.push(getLocationsForCustomer(customerID))
		}
		return Promise.all(getLocationPromises)
	},

	/** Updates Existing Location
	 * 		Note: Latitude and Longitude that are passed in here are only used as defaults if the values are not generated
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object}  p
	 * @param {String}  p.locationId	- The id of the device being updated
	 * @param {String}  p.address		- The Location's Address
	 * @param {String}  p.city			- The Location's City
	 * @param {String}  p.country		- The Location's Country
	 * @param {Number}  p.latitude		- The Location's Latitude
	 * @param {Number}  p.longitude		- The Location's Longitude
	 * @param {String}  p.name			- The Location's Name
	 * @param {String}  p.locState		- The Location's State, named to not conflict with store.state
	 * @param {String}  p.customerId	- The (new) customer ID that location belongs to
	 *
	 * @returns {Promise} Axios promise object with response from updating location
	 */
	updateLocation({ dispatch }, { locationId, address, city, country, latitude, longitude, name, locState, customerId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		// Whatever latitude and longitude are set to will be what is returned if the address is incorrect or not found
		const body = {
			address: address,
			city: city,
			country: country,
			latitude: latitude,
			longitude: longitude,
			name: name,
			state: locState,
			customer_id: customerId
		}
		return axios
			.put(
				'/api/v1/locations/' + locationId, body, {
					headers: jwt,
					timeout: 5000
				}
			)
			.then(() => {
				return dispatch('fetchLocations', [customerId])
			})
			.catch((error) => {
				console.error('Error updating location', error)
			})
	},

	/** Deletes an existing Location
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String} locationId	- LocationId to delete
	 */
	deleteLocation({ commit }, locationId) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.delete(
				`/api/v1/locations/${locationId}`, {
					headers: jwt,
					timeout: 5000
				}
			)
			.then(() => {
				commit('_removeLocation', locationId)
			})
			.catch((error) => {
				console.error('Error deleting location', error)
			})
	}
}

/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
	/** Sets the state's locations
	 * @param {Object<string,AmpLocation>} locations - Keys are location IDs
	 */
	_setLocations(state, locations) {
		if (state.locations === null) {
			Vue.set(state, 'locations', {})
		}
		for (const loc in locations) {
			Vue.set(state.locations, locations[loc].id, locations[loc])
		}
	},
	/** Remove a Location
	 * @param {String} locationID	- Location ID to remove from the store
	 */
	_removeLocation(state, locationID) {
		if (state.locations === null) return
		Vue.delete(state.locations, locationID)
	},
	/** Add a queried id to queried set
	 * @param {String} customerID	ID of machine queried for
	 */
	_setQueried(state, customerID) {
		state.queried.add(customerID)
	},
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultLocationState())
	}
}

/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {
	/** Gets all loaded locations
	 *
	 * @return {Object<string,AmpLocation>} Keys are location UID
	 */
	getLocations(state) {
		return state.locations
	},
	/** Gets all parameters previously queried for
	 *
	 * @return {Set<string>} Set of entity ids queried for
	 */
	getQueried(state) {
		return state.queried
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
