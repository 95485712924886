<template>
  <div class="header-container">
    <!-- Hamburger Menu that toggles and emits -->
    <div
      v-if="authenticated"
      class="menu-toggle-container"
      @click="toggleMenu()"
    >
      <div
        class="menu-bar-holder"
        :class="{'change': menuOpen}"
      >
        <div class="bar1" />
        <div class="bar2" />
        <div class="bar3" />
      </div>
    </div>

    <!-- Baldwin Logo on left side of page -->
    <div class="logo">
      <img src="/images/new-logo.png">
    </div>

    <!-- Loading icon for mobile -->
    <loading-icon
      v-if="dependencyLoading"
      class="loading-icon-mobile"
    />

    <!-- Language Selector and logout button on right side of page  -->
    <div class="options-container">
      <!-- Loading icon for desktop and tablet -->
      <loading-icon
        v-if="dependencyLoading"
        class="loading-icon"
      />
      <!-- Language Selector Dropdown -->
      <div class="options-container-child">
        <ampSelect
          v-model="$root.$i18n.locale"
          class="header-font"
          :options="langs"
          :option-label="(o) => o.toUpperCase()"
          :hide-default="true"
        />
      </div>
      <!-- Logout Button -->
      <div
        v-if="authenticated"
        class="options-container-child logout-btn"
      >
        <div
          v-if="authenticated"
          class="notification-holder"
        >
          <FontAwesomeIcon
            class="fa-2x fa-bell clickable oval"
            :class="{notify: unackCount}"
            :icon="['fas', 'bell']"
            @click="$emit('notification-click')"
          />
          <div
            v-if="unackCount"
            class="counter clickable"
            @click="$emit('notification-click')"
          >
            {{ unackCount }}
          </div>
        </div>

        <!-- Notification overlay -->
        <ampButton
          :coloring="'dark'"
          :solid-color="false"
          class="header-font logout-button"
          @click="logout()"
        >
          {{ $t(`Logout`) }}
        </ampButton>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
// Font Awesome Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import loadingIcon from '@/components/loading_icon.vue'

library.add(faSpinner)
library.add(faBell)

/** Header Component
 * Primary responsibilities
 * 	- Display top banner with:
 * 		- In desktop width (> 768px)
 * 			- Logo
 * 			- Language Selector
 * 			- Logout Button (If logged in, as noted by `authenticated` prop)
 * 		- In tablet view (< 768px)
 * 			- Hamburger Menu Toggle
 * 				- When pressed, emits `show-menu` with a boolean (true if visible, false if hidden) to parent
 * 			- Logo
 * 			- Language Drop down selector
 * 		- In mobile view (< 425px)
 * 			- Hamburger Menu Toggle
 * 			- Logo
 * 	- All versions will display the global loading icon when the store is checking dependencies
 */
export default {
	components: { FontAwesomeIcon, loadingIcon },
	props: {
		/** Dictates whether the user is logged in (and can thus access the logout button and menu toggle) */
		authenticated: {
			type: Boolean,
			default: false
		},

		/** A flag noting whether the side nav is visible or not - used to assure the hamburger toggle
            always matches the state of the side nav */
		showMenu: {
			type: Boolean,
			default: true
		},

		/** Number of unacknowledged notifications to be displayed */
		unackCount: {
			type: Number,
			default: 0
		}

	},

	/** Data
	 * @prop {Boolean} menuOpen	- Flag dictating if the hamburger menu is open (true) or closed/hidden (false)
	 */
	data() {
		return {
			menuOpen: false,
			/** @type {String[]} Language options to be selected */
			langs: []
		}
	},

	computed: {
		...mapGetters([
			'dependencyLoading'
		])
	},

	watch: {
		authenticated: function(to, from) {
			if (to && !from) {
				// If user just became authenticated, the menu toggle should auto close
				this.menuOpen = false
			}
		},

		showMenu: function() {
			this.menuOpen = this.showMenu
		}
	},

	created() {
		// Available languages to be select
		this.langs = Object.keys(this.$i18n.messages)
	},

	/** Methods
	 * @prop {Vuex_Mutation} setCustomerFilter
	 */
	methods: {
		...mapActions(['logout']),
		/** Toggle the hamburger menu icon and emit the change */
		toggleMenu() {
			this.menuOpen = !this.menuOpen
			this.$emit('show-menu', this.menuOpen)
		}
	}
}
</script>
<style lang="scss" scoped>
.header-container {
	position: relative;
	z-index: 100;
	height: 70px;
	width: 100%;
	padding: 15px 52px 14px 36px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	display: flex;
	justify-content: space-between;
	@media (max-width: 768px) {
		justify-content: flex-start
	}
	position: relative;
    z-index: 100;
}
.logout-button{
	width: 100px;
    height: 35px;
	padding: 0 1rem;
}
.logo {
	height: 45px;
	display: flex;
	@media (max-width: 425px) {
		flex-grow: 1;
	}
}
img {
	height: 100%;
	width: auto;
	margin: auto;
}
.options-container {
	@media (max-width: 425px) {
		display: none
	}
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	flex: 1;
}
.options-container-child {
	margin-left: 40px;
	height: 40px;
}
.header-font {
	text-transform: uppercase;
}
.logout-btn {
	@media (max-width: 768px) {
		display: none
	}
	display: flex;
	align-items: center;
}
.oval {
	width: 30px;
	height: 30px;
	text-align: center;
	//border-radius: 50%;
	color: #d8d8d8;
	float: left;
	margin-right: 10px;
	display: flex;
    justify-content: center;
    align-items: center;
}
.loading-icon-mobile {
	display: none;
  top: -35px;
  position: relative;
	@media (max-width: 425px) {
		display: flex
	}
}
.loading-icon{
	z-index: 1;
}
.notification-holder{
 position: relative;
}
.counter{
	top: 4px;
	left: 11px;
	position: absolute;
}

.notify{
	color: #dae676;
	font-weight: bold;
}
// Hamburger Menu Hijinx
.menu-toggle-container {
	@media (min-width: 769px) {
		display: none
	}
	height: 40px;
	width: 40px;
	cursor: pointer;
}
.menu-bar-holder {
	margin-top: 7px;
	display: inline-block;
}
// Menu bars
.bar1, .bar2, .bar3 {
  margin: 0 0 7px;
  border: solid 1px #151515;
  transition: 0.2s;
}
.bar1, .bar3 {
	width: 24px;
}
.bar2 {
	width: 18px;
}
// Transform menu bars into X
.change .bar1 {
  transform: rotate(-45deg) translate(-9px, 6px) ;
}
.change .bar2 {
  opacity: 0;
}
.change .bar3 {
  transform: rotate(45deg) translate(-7px, -5px) ;
}
</style>
