import axios from 'axios'
import Vue from 'vue'

/** AMP Users store
 * @typedef {Object} UserStore
 * @prop {Object<string,User>|null} users 		- Top level key are user ID
 * @prop {Set<string>} queried	- Set of customers that users have been quired for
 */

/** @returns {UserStore} */
export const getDefaultUserState = () => {
	return {
		users: null,
		queried: new Set()
	}
}

export const storeState = getDefaultUserState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {

	/** Creates a User from the passed in body
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object} 	 p
	 * @param {String} 	 p.countryCode	- The Users Assigned Country
	 * @param {Date} 	 p.createdTime	- The time that this User was added
	 * @param {String} 	 p.customerId	- The Customer Attached to this User
	 * @param {String} 	 p.email		- The User's Email
	 * @param {String} 	 p.fname		- The User's First Name
	 * @param {String} 	 p.lname		- The User's Last Name
	 * @param {Number} 	 p.roleId		- The User's Role ID
	 * @param {String} 	 p.password		- The User's Password
	 *
	 * @returns {Promise} Axios promise with id of new machine
	 */
	createUser({ dispatch }, { countryCode, createdTime, customerId, email, fname, lname, roleId, password }) {
		const header = {
			Authorization: localStorage.getItem('authToken'),
			password: password
		}
		const body = {
			country_code: countryCode,
			created_at: createdTime,
			customer_id: customerId,
			email: email,
			fname: fname,
			lname: lname,
			role_id: roleId
		}
		return axios
			.post('/api/v1/users', body, {
				headers: header,
				timeout: 5000
			}
			)
			.then(() => {
				return dispatch('fetchUsers', [customerId])
			})
			.catch((error) => {
				console.error('Error creating user:', error)
			})
	},

	/** Fetch all users for given customer id list
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
     * @param {String[]} customerIDs    array of customer ids to fetch users for
     */
	fetchUsers({ commit }, customerIDs) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		/** Create a promise factory to get users for each customer
		* @param {String} customerID customer UUID
		* @returns {Promise} Axios promise object with results of getting users for customer
		*/
		function getUsersForCustomer(customerID) {
			return axios
				.get(`/api/v1/customers/${customerID}/users`, {
					headers: jwt,
					timeout: 5000
				})
				.then((response) => {
					// Translate users's parameter names from the API keys to javascript friendly keys
					// These keys are documented in state declaration at the top of the page as well
					const translatedUsers = {}
					for (const user of response.data.users) {
						translatedUsers[user.id] = user
						// Currently not running any translations. Proceed with caution if API changes
					}
					commit('_setUsers', translatedUsers)
				})
				.catch((error) => {
					console.error('Error getting users for customer:', customerID, ': ', error)
					commit('_setUsers', {})
				}).finally(_ => {
					commit('_setQueried', customerID)
				})
		}
		const getUserPromises = []
		for (const customerID of customerIDs) {
			getUserPromises.push(getUsersForCustomer(customerID))
		}
		return Promise.all(getUserPromises)
	},

	/** Updates Existing User
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object} 	 p
	 * @param {String} 	 p.userId		- The Users Id
	 * @param {String} 	 p.countryCode	- The Users Assigned Country
	 * @param {String} 	 p.email		- The User's Email
	 * @param {String} 	 p.fname		- The User's First Name
	 * @param {String} 	 p.lname		- The User's Last Name
	 * @param {Number} 	 p.roleId		- The User's Role ID
	 * @param {String} 	 p.customerId	- The (new) Customer ID for the user
	 *
	 * @returns {Promise} Axios promise object with updated device
	 */
	updateUser({ dispatch }, { userId, countryCode, email, fname, lname, roleId, customerId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			country_code: countryCode,
			email: email,
			fname: fname,
			lname: lname,
			role_id: roleId,
			customer_id: customerId
		}
		return axios
			.put('/api/v1/users/' + userId, body, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				return dispatch('fetchUsers', [customerId])
			})
			.catch((error) => {
				console.error('Error update user:', error)
			})
	},

	/** Deletes an existing User
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String} userId UserId to delete
	 */
	deleteUser({ commit }, userId) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.delete(`/api/v1/users/${userId}`, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				commit('_removeUser', userId)
			})
			.catch((error) => {
				console.error('Error deleting user', error)
			})
	}
}

/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
	/** Sets the state's users
	 *
	 * @param {object} users - Keys are user IDs, values are all associated values of that user
	 */
	_setUsers(state, users) {
		if (state.users === null) {
			Vue.set(state, 'users', {})
		}
		for (const user in users) {
			Vue.set(state.users, users[user].id, users[user])
		}
	},
	/** remove a user
	 *
	 * @param {String} userId - ID of user to remove
	 */
	_removeUser(state, userId) {
		if (state.users === null) return
		Vue.delete(state.users, userId)
	},
	/** Add a queried id to queried set
	 *
	 * @param {String} customerID	ID of machine queried for
	 */
	_setQueried(state, customerID) {
		state.queried.add(customerID)
	},
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultUserState())
	}
}

/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {
	/** Gets all loaded users
	 *
	 * @return {Object<string,User>} Keys are user UID, values consist of user properties
	 */
	getUsers(state) {
		return state.users
	},
	/** Gets all parameters previously queried for
	 * @return {Set<string>}	Set of entity ids queried for
	 */
	getQueried(state) {
		return state.queried
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
