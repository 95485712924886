
import axios from 'axios'
import Vue from 'vue'
import { getActiveUserID, getActiveUserCustomerID } from '@/helpers/jwt.js'

/** Rules state
 * @typedef {Object} RuleStore
 * @prop {Object<string,Rule>|null} 			rules 			- RuleID is key pointing to rule info
 * @prop {Set<string>} 							queried 		- All devices who's rules have been queried
 * @prop {Object<string,CompositeRule>|null} 	compositeRules 	- CompositeRuleID is key point to composite rule info
 */

/** @returns {RuleStore} */
export const getDefaultRuleState = () => {
	return {
		rules: null,
		queried: new Set(),
		compositeRules: null
	}
}

export const storeState = getDefaultRuleState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {

	/** Wrapped API call to create a new analytic rule for an existing telemetry + device.
	 *
	 * @param {import('vuex').ActionContext<typeof storeState>} _
	 * @param {Object} 	 p
     * @param {String}	 p.deviceID             - The device id that the rule is being created for
     * @param {String} 	 p.telemetryKey			- The telemetry key's name
	 * @param {String} 	 p.name					- Name for the rule
     * @param {String} 	 p.alarmLevel 			- The level of analytics alarm (choose "warn" or "fail")
     * @param {String} 	 p.thresholdType 		- The type of threshold analytics (choose "gt", "lt", or "delta")
     * @param {String} 	 p.thresholdVal			- The value of the threshold
     * @param {Number} 	 p.deltaWindowMinutes 	- The delta window length in minutes
	 * @param {Boolean}	 p.getNotifications		- Boolean flag to subscribe to notifications for when the rule triggers
	 * @param {String} 	 p.email				- Optional email to send notification to
	 * @param {String} 	 p.description			- Description of the rule
	 *
     * @returns {Promise}
     */
	createSingleRule({ dispatch }, { deviceID, telemetryKey, name, alarmLevel, thresholdType, thresholdVal, deltaWindowMinutes, getNotifications, email, description }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			device_id: deviceID,
			key_name: telemetryKey,
			alarm_level: alarmLevel,
			threshold_type: thresholdType,
			threshold_val: parseFloat(thresholdVal),
			delta_window_minutes: (thresholdType === 'delta') ? +deltaWindowMinutes : 0,
			creator_id: getActiveUserID(),
			name: name,
			email: email,
			acknowledgementRequired: getNotifications,
			description: description
		}
		return axios
			.post('/analytics/v1/rules', body, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				return dispatch('fetchRules', [deviceID])
			})
			.catch((error) => {
				console.error('Error creating single rule', error)
				return Promise.reject(error)
			})
	},

	/** Sends a request to the API to create a new composite rule.
	 * Then updates the components composite rules
	 *
	 * @param {import('vuex').ActionContext<typeof storeState>} _
	 * @param {Object} p
	 * @param {string} p.name
	 * @param {string[]} p.rules
	 *
	 * @returns {Promise}
	 */
	createCompositeRule({ dispatch }, { name, rules }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			rule_user_label: name,
			add_rules: rules
		}
		return axios.post(
			'/analytics/v1/compositerules', body, {
				headers: jwt,
				timeout: 1000
			}
		).then(() => {
			return dispatch('fetchCompositeRules', [getActiveUserCustomerID()])
		}).catch((error) => {
			console.error('Error from creating composite rule', error)
			return Promise.reject(error)
		})
	},

	/** Fetch all rules from remote API and load it into the store
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String[]} deviceIDs - List of device ids to fetch rules for
	 *
	 * @returns {Promise} Axios promise all for fetching rules from api
	 */
	fetchRules({ commit }, deviceIDs) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		/** Create a promise factory to get rules for each device
		 *
		* @param {String} deviceID device ID to fetch rules for

		* @returns {Promise} Axios promise object with results of getting rules for device
		*/
		function getRulesForDevice(deviceID) {
			return axios
				.get('/analytics/v1/devices/' + deviceID + '/rules', {
					headers: jwt,
					timeout: 5000
				})
				.then((response) => {
					// Translate rule's parameter names from the API keys to javascript friendly keys
					// These keys are documented in state declaration at the top of the page as well
					const translatedRules = {}
					if (response.data.rules) { // rules can be null if none are found
						for (const rule of response.data.rules) {
							translatedRules[rule.rule_id] = rule // Leaving for later, not translating keys right now.
						}
					}
					commit('_setRules', translatedRules)
				})
				.catch((error) => {
					console.error('Error loading Rules', error)
				}).finally(_ => {
					commit('_setQueried', deviceID)
				})
		}
		const getRulePromises = []
		for (const deviceID of deviceIDs) {
			getRulePromises.push(getRulesForDevice(deviceID))
		}
		return Promise.all(getRulePromises)
	},

	/** Gets a single rule from the API and sets it in rules
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {string} ruleID
	*/
	fetchSingleRule({ commit }, ruleID) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.get(`/analytics/v1/rules/${ruleID}`, {
				headers: jwt,
				timeout: 5000
			})
			.then((response) => {
				if (response.data.rule) { // rules can be null if none are found
					commit('_setSingleRule', response.data.rule)
				}
			})
			.catch((error) => {
				console.error('Error loading Rules', error)
			})
	},

	/** Fetches all composite rules that the user can see and populates the list
	 * Then fetches all rules that are references by the composite rules
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String[]} customerIDs
	 */
	fetchCompositeRules({ commit }, customerIDs) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		function getCompositeRulesForCustomer(customerID) {
			return axios
				.get(`/analytics/v1/customers/${customerID}/compositerules`, {
					headers: jwt,
					timeout: 5000
				})
				.then((response) => {
					// Translate rule's parameter names from the API keys to javascript friendly keys
					// These keys are documented in state declaration at the top of the page as well
					const translatedRules = {}
					if (response.data.composite_rules) { // rules can be null if none are found
						for (const rule of response.data.composite_rules) {
							translatedRules[rule.composite_rule_id] = rule // Leaving for later, not translating keys right now.
						}
					}
					commit('_setCompositeRules', translatedRules)
				})
				.catch((error) => {
					console.error('Error fetching composite rules', error)
				})
		}
		const getRulePromises = []
		for (const customerID of customerIDs) {
			getRulePromises.push(getCompositeRulesForCustomer(customerID))
		}
		return Promise.all(getRulePromises)
	},

	/** Gets historical analytic results
	 *
	 * @param _
	 * @param {Object} p
	 * @param {String} p.deviceID
	 * @param {Number} p.startTime
	 * @param {Number} p.endTime
	 * @param {Number} p.aggTime
	 */
	generateHistoricalRuleResults(_, { deviceID, startTime, endTime, aggTime }) {
		const endpoint =
            '/analytics/v1/devices/' + deviceID +
            '/start/' + startTime.toString() +
            '/end/' + endTime.toString() +
            '/agg/' + aggTime
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.get(endpoint, {
				headers: jwt
			})
			.then((response) => {
				return response.data
			}, this) // TODO, why is this needed?
			.catch((error) => {
				console.error('Error fetching data:', error)
			})
	},

	/** Updates Existing Rule
	 *
	 * @param _
	 * @param {Object} p
	 * @param {string} 	p.deviceID
	 * @param {string} 	p.telemetryKey
	 * @param {string} 	p.ruleID
	 * @param {string} 	p.name
	 * @param {string} 	p.alarmLevel
	 * @param {string} 	p.thresholdType
	 * @param {number} 	p.thresholdVal
	 * @param {number} 	p.deltaWindowMinutes
	 * @param {boolean} p.acknowledgementRequired
	 * @param {string} 	p.email
	 * @param {string} 	p.description
	 *
	 * @returns {Promise} Axios promise object with response from updating device
	 */
	updateRule({ dispatch }, { deviceID, telemetryKey, ruleID, name, alarmLevel, thresholdType, thresholdVal, deltaWindowMinutes, acknowledgementRequired, email, description }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			name: name,
			alarm_level: alarmLevel,
			threshold_type: thresholdType,
			threshold_val: +thresholdVal,
			delta_window_minutes: (thresholdType === 'delta') ? +deltaWindowMinutes : 0,
			acknowledgementRequired: acknowledgementRequired,
			email: email,
			description: description
		}
		return axios
			.put('/analytics/v1/devices/' + deviceID + '/telemetry/' + telemetryKey + '/rules/' + ruleID, body, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				return dispatch('fetchRules', [deviceID])
			})
			.catch((error) => {
				console.error('Error updating rule', error)
				return Promise.reject(error)
			})
	},

	/** Triggers when user tries to apply a new rule to a focused composite rule
	 *
	 * @param _
	 * @param {Object} p
	 * @param {string} p.ruleID
	 * @param {string[]} p.addRules
	 * @param {string[]} p.removeRules
	*/
	updateCompositeRule({ dispatch }, { ruleID, addRules, removeRules }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			add_rules: addRules,
			remove_rules: removeRules
		}
		return axios.put(
			`/analytics/v1/compositerules/${ruleID}/rules`, body, {
				headers: jwt,
				timeout: 5000
			}
		).then(() => {
			return dispatch('fetchCompositeRules', [getActiveUserCustomerID()])
		}).catch((error) => {
			console.error('Error from updating composite rule', error)
			return Promise.reject(error)
		})
	},

	/** Deletes an existing rule
	 *
	 * @param _
	 * @param {Object}  p
	 * @param {String}  p.ruleID		- ID of rule to delete
	 * @param {String}  p.deviceID  	- ID of device rule belongs to
	 * @param {String}  p.telemetryKey 	- Telemetry key associated with rule
	 */
	deleteRule({ commit }, { deviceID, telemetryKey, ruleID }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.delete('/analytics/v1/devices/' + deviceID + '/telemetry/' + telemetryKey + '/rules/' + ruleID, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				commit('_removeRule', ruleID)
			})
			.catch((error) => {
				console.error('Error deleting rule', error)
				return Promise.reject(error)
			})
	},

	/** Delete composite rule via API
	 *
	 * @param _
	 * @param {Object} p
	 * @param {String} p.ruleID
	 */
	deleteCompositeRule({ commit }, { ruleID }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios.delete(`/analytics/v1/compositerules/${ruleID}`, {
			headers: jwt,
			timeout: 5000
		}
		)
			.then(() => {
				commit('_removeCompositeRule', ruleID)
			})
			.catch((error) => {
				console.error('Error from delete composite rule', error)
				return Promise.reject(error)
			})
	}
}

/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
	/** Sets the state's single rules
	 *
	 * @param {Object<string,Rule>} rules - Keys are rule IDs, values are all associated values of that rule
	 */
	_setRules(state, rules) {
		if (state.rules === null) {
			Vue.set(state, 'rules', {})
		}
		for (const rule in rules) {
			Vue.set(state.rules, rule, rules[rule])
		}
	},
	/** Sets a single rule into the state
	 *
	 * @param {Rule} rule - Single rule to be inserted into the store
	 */
	_setSingleRule(state, rule) {
		if (state.rules === null) {
			Vue.set(state, 'rules', {})
		}
		Vue.set(state.rules, rule.rule_id, rule)
	},

	/** Sets the state's single composite rules
	 *
	 * @param {Object<string,CompositeRule>} rules - Keys are rule IDs, values are all associated values of that rule
	 */
	_setCompositeRules(state, rules) {
		if (state.compositeRules === null) {
			Vue.set(state, 'compositeRules', {})
		}
		for (const rule in rules) {
			Vue.set(state.compositeRules, rule, rules[rule])
		}
	},
	/** Remove a rule
	 *
	 * @param {String} ruleID	- Rule ID to remove from the store
	 */
	_removeRule(state, ruleID) {
		if (state.rules === null) return
		Vue.delete(state.rules, ruleID)
	},
	/** Remove a composite rule
	 *
	 * @param {String} ruleID	- Rule ID to remove from the store
	 */
	_removeCompositeRule(state, ruleID) {
		if (state.compositeRules === null) return
		Vue.delete(state.compositeRules, ruleID)
	},
	/** Add a queried id to queried set
	 *
	 * @param {String} deviceID	ID of machine queried for
	 */
	_setQueried(state, deviceID) {
		state.queried.add(deviceID)
	},
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultRuleState())
	}
}

/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {
	/** Gets all loaded rule
	 *
	 * @return {Object<string,Rule>} Keys are rule UID, values consist of rule properties
	 */
	getRules(state) {
		return state.rules
	},
	/** Gets all loaded composite rule
	 *
	 * @return {Object<string,CompositeRule>} Keys are rule UID, values consist of composite rule properties
	 */
	getCompositeRules(state) {
		return state.compositeRules
	},
	/** Gets all parameters previously queried
	 *
	 * @return {Set<string>}	Set of entity ids queried for
	 */
	getQueried(state) {
		return state.queried
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
