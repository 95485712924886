<template>
  <transition name="grow-shrink-main">
    <div
      v-if="showMenuToggle && loaded"
      :key="showMenu"
      style="display:flex"
    >
      <div
        class="sidenav"
        :class="{'collapsed': collapsed}"
      >
        <!-- Main Nav options -->
        <div class="column-container">
          <router-link
            v-for="navItem in mainNavContent.filter(item => hasPermission(item.permission))"
            :key="navItem.pathPrefix"
            :to="navItem.route"
            class="nav-text clickable"
            :class="{'active': pathPrefix === navItem.pathPrefix}"
            :title="collapsed? $t(navItem.text) : ''"
            @click.native="closeMenuIfMobile()"
          >
            <img
              class="clickable"
              :src="'/images/navigation/'+navItem.icon"
            >
            <a
              v-if="!collapsed"
              class="clickable"
            >{{ $t(navItem.text) }}</a>
          </router-link>
        </div>

        <!-- Admin Nav options -->
        <div class="column-container">
          <router-link
            v-for="navItem in adminNavContent.filter(item => hasPermission(item.permission))"
            :key="navItem.pathPrefix"
            :to="navItem.route"
            class="nav-text clickable"
            :class="{'active': pathPrefix === navItem.pathPrefix}"
            :title="collapsed? $t(navItem.text) : ''"
            @click.native="closeMenuIfMobile()"
          >
            <img :src="'/images/navigation/'+navItem.icon">
            <a v-if="!collapsed">{{ $t(navItem.text) }}</a>
          </router-link>

          <!-- Content in this group will only appear in mobile view: -->
          <div class="mobile-content">
            <div
              class="nav-text clickable"
              @click="expandedLang = !expandedLang"
            >
              <img src="/images/navigation/language.svg">
              <a> {{ $t(`Language`) }} </a>
            </div>
            <transition name="list-grow-shrink">
              <ul v-if="expandedLang">
                <li
                  v-for="(lang, index) in langs"
                  :key="index"
                  class="lang-text-options clickable"
                  :class="{'selected-lang': $root.$i18n.locale === lang}"
                  :value="lang"
                  @click="$root.$i18n.locale = lang; expandedLang = false"
                >
                  {{ lang }}
                </li>
              </ul>
            </transition>
          </div>

          <!-- Content in this group will only appear in tablet + mobile view -->
          <div
            class="tablet-content nav-text clickable"
            @click="logout"
          >
            <img
              class="clickable"
              src="/images/navigation/logout.svg"
            >
            <a> {{ $t(`Log Out`) }} </a>
          </div>
        </div>

        <!-- Blog and Help are special cases as they need to expand in mobile: -->
        <!-- Blog w/ secondary mobile menu: -->
        <div
          class="nav-text blog clickable"
          :class="{'active': pathPrefix === 'blog'}"
          :title="collapsed? $t(`Blog`) : ''"
        >
          <!-- Normal text + image -->
          <router-link
            class="text-container clickable"
            to="/blog"
          >
            <img :src="'/images/navigation/notebook.svg'">
            <a v-if="!collapsed">{{ $t(`Blog`) }}</a>
          </router-link>
          <!-- Secondary navigation in mobile only: -->
          <transition name="list-grow-disappear">
            <div
              v-if="pathPrefix === 'blog'"
              class="mobile-second-menu"
            >
              <div
                v-for="title in blogTitles"
                :key="title"
                class="nav-text center-wrap"
                :class="{'active second': title === currentRouteQuery.category}"
                @click="closeMenuIfMobile(); updateQueryParamsWithMap({'category': title})"
              >
                {{ title }}
              </div>
            </div>
          </transition>
        </div>

        <!-- Help w/ secondary mobile menu: -->
        <div
          class="nav-text blog"
          :class="{'active': pathPrefix === 'help'}"
          :title="collapsed? $t(`Help`) : ''"
        >
          <!-- Normal text + image  -->
          <router-link
            class="text-container clickable"
            to="/help"
          >
            <img :src="'/images/navigation/documentation.svg'">
            <a v-if="!collapsed">{{ $t(`Help`) }}</a>
          </router-link>
          <!-- Secondary navigation in mobile only -->
          <transition name="list-grow-disappear">
            <div
              v-if="pathPrefix === 'help'"
              class="mobile-second-menu"
            >
              <div
                v-for="title in helpTitles"
                :key="title"
                class="nav-text center-wrap"
                :class="{'active second': title === currentRouteQuery.category}"
                @click="closeMenuIfMobile(); updateQueryParamsWithMap({'category': title})"
              >
                {{ title }}
              </div>
            </div>
          </transition>
        </div>

        <!-- Auxiliary Nav options -->
        <div class="column-container">
          <router-link
            v-for="navItem in auxNavContent.filter(item => hasPermission(item.permission))"
            :key="navItem.pathPrefix"
            class="nav-text clickable"
            :class="{'active': pathPrefix === navItem.pathPrefix, 'blog': navItem.pathPrefix == 'blog'}"
            :title="collapsed? $t(navItem.text) : ''"
            :to="navItem.route"
            @click.native="closeMenuIfMobile()"
          >
            <img :src="'/images/navigation/'+navItem.icon">
            <a v-if="!collapsed">{{ $t(navItem.text) }}</a>
          </router-link>
        </div>

        <!-- Expand/Collapse button -->
        <div
          class="expand-collapse nav-text clickable"
          @click="collapsed = !collapsed"
        >
          <img
            v-if="collapsed"
            src="/images/navigation/expand.svg"
          >
          <img
            v-else
            src="/images/navigation/collapse.svg"
          >
          <a v-if="!collapsed"> {{ $t(`Collapse`) }} </a>
        </div>
      </div>

      <!-- Secondary menus for blog + help in desktop view only -->
      <transition
        name="grow-shrink-second"
        mode="out-in"
      >
        <!-- Blog -->
        <div
          v-if="pathPrefix === 'blog'"
          key="blog"
          class="second-menu"
        >
          <div
            v-for="title in blogTitles"
            :key="title"
            class="nav-text center-wrap clickable"
            :class="{'active second': title === currentRouteQuery.category}"
            @click="updateQueryParamsWithMap({'category': title})"
          >
            <span>{{ title }}</span>
          </div>
        </div>
        <!-- Help -->
        <div
          v-if="pathPrefix === 'help'"
          key="help"
          class="second-menu"
        >
          <div
            v-for="title in helpTitles"
            :key="title"
            class="nav-text center-wrap clickable"
            :class="{'active second': title === currentRouteQuery.category}"
            @click="updateQueryParamsWithMap({'category': title})"
          >
            <span>{{ title }}</span>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

/**
 * Component Responsibilities:
    - Display navigation menu to user on the left side of the page
    - Collapse and expand as controlled by the showMenu prop
        - When true, the menu is shown, when false is is hidden
    - When the screen width is < 768px, automatically hide the component, do the opposite when screen width exceeds it.
    - Hide menu navigation links that the user does not have permission to view
	- The currently selected page will display with bold font and a light rectangle outline
	- If the blog or help option is selected, a secondary menu will display on all form factors to allow
	  user to select a document from that group
	  	- The titles to display in this many are recieved via blogTitles and helpTitles prop
		- When `blog` or `help` are selected from a different page in mobile, the menu WILL NOT auto hide
		  right away, rather it will wait for the user to select a document from the secondary menu
		- The secondary menu will nicely transition in and out in both mobile and desktop
 * Differences by media width:
    - Desktop (>768px):
		- Shows all normal nav links
		- Allow user to "collapse" the menu to just the icons. This is only available in desktop widths
		- Secondary menu for blog and help document list appears to the left side
    - Tablet (425 - 768px)
        - Shows all normal nav links
		- Additional link for user to log out
		- Secondary menu for blog and help document list expand vertically within the menu pane
    - Mobile (<425px)
        - Shows all normal nav links
		- Additional link for user to log out
		- Secondary menu for blog and help document list expand vertically within the menu pane
        - Additional language drop down selector to choose site language
    - As the lines between media widths from desktop -> table/mobile are crossed, the nav will auto hide
    - When this component changes the visibility of the menu internally, it emits a boolean to `nav-show-menu` where
        true indicates it is open, false indicates it has closed
    - When a menu item is clicked on in tablet/mobile mode, the menu will auto close.
*/
export default {
	props: {
		/** Controls whether the menu is visible or not. As the prop is toggled, a smooth
        animation will grow or shrink the component */
		showMenu: {
			type: Boolean,
			default: true
		},

		/** Dictates whether the user is logged in (and can thus access the logout button and menu) */
		authenticated: {
			type: Boolean,
			default: false
		},

		/** A list of strings corresponding to document titles on the blog
     * @type {import('vue').PropOptions<String[]>}
    */
		blogTitles: {
			type: Array,
			default: () => []
		},

		/** A list of string corresponding to document titles on the help page
     * @type {import('vue').PropOptions<String[]>}
    */
		helpTitles: {
			type: Array,
			default: () => []
		}
	},

	data() {
		return {
			/** Internal flag used to transition the menu in or out, will change with the showMenu prop via watcher */
			showMenuToggle: true,
			/** Width of the browser window, user to auto expand/hide the menu */
			windowWidth: 0,
			/** If the language section (in mobile) is expanded (true) or not (false) */
			expandedLang: false,
			/** True once content dependencies have finished loading */
			loaded: false,
			/** Flag to control the collapse (minimize to just icons) of nav bar in desktop mode */
			collapsed: false
		}
	},

	computed: {
		...mapGetters('Permissions/', [
			'getValidPermissionForUser'
		]),

		/** The first directory after the base url, i.e. iotbig.net/analytics/otherstuff => analytics.
		 * Used to highlight active nav link
     * @returns {String} */
		pathPrefix() {
			return this.$route.path.split('/')[1]
		}
	},

	watch: {
		/** When the showMenu prop changes, transition the nav menu in or out */
		showMenu() {
			this.toggleMenu(this.showMenu)
		},

		/** When the window width changes, see if we need to hide or show menu */
		windowWidth() {
			this.toggleMenu(this.windowWidth > 768 && this.authenticated)
		},

		/** When we go from logged out to logged in, toggle the menu based on screen size */
		authenticated(to, from) {
			if (!from && to) {
				// If the screen size is desktop, show menu. Otherwise keep it hidden
				this.toggleMenu(this.windowWidth > 768)
				// Dependencies weren't loaded on create, so load them now:
				this.loadDependencies()
			} else {
				this.toggleMenu(false)
			}
		}
	},

	created() {
		this.langs = Object.keys(this.$i18n.messages)

		if (this.authenticated) this.loadDependencies()

		// First (top) group of nav links, displayed in order top to bottom
		this.mainNavContent = [
			{
				pathPrefix: 'home', // Path prefix to match for when this nav link is selected
				route: '/home', // Route URL to send user to when clicked on
				text: 'translationString.Home', // Text to display for link, will be fed through $t translator
				icon: 'home.svg', // Icon to display in place of text on minimized menu. `images/navigation/` will preface
				permission: '' // Permission name required to show this link, empty string if there is none
			},
			{
				pathPrefix: 'dashboard',
				route: '/dashboard/',
				text: 'translationString.Dashboard',
				icon: 'dashboard.svg',
				permission: ''
			},
			{
				pathPrefix: 'engineer',
				route: '/engineer',
				text: 'translationString.Deep Chart',
				icon: 'deepchart.svg',
				permission: 'Deep Chart Page'
			},
			{
				pathPrefix: 'analytics',
				route: '/analytics',
				text: 'translationString.Device Data',
				icon: 'devicedata.svg',
				permission: 'Device Data Page'
			},
			{
				pathPrefix: 'rules',
				route: '/rules',
				text: 'translationString.Rules',
				icon: 'analytics.svg',
				permission: 'Rules Page'
			},
			{
				pathPrefix: 'reports',
				route: '/reports',
				text: 'translationString.Reports',
				icon: 'search.svg',
				permission: ''
			},
			{
				pathPrefix: 'jobs',
				route: '/jobs',
				text: 'translationString.Jobs',
				icon: 'map.svg',
				permission: ''
			}
		]
		// Second (middle) group of nav links
		this.adminNavContent = [
			{
				pathPrefix: 'admin',
				route: '/admin',
				text: 'translationString.Admin',
				icon: 'admin.svg',
				permission: 'Admin Page'
			},
			{
				pathPrefix: 'my-account',
				route: '/my-account',
				text: 'translationString.My Account',
				icon: 'avatar.svg',
				permission: ''
			}
		]
		// Last (bottom) group of nav links, will also include blog and help
		this.auxNavContent = [
			{
				pathPrefix: 'contact-us',
				route: '/contact-us',
				text: 'translationString.Contact Us',
				icon: 'feedback.svg',
				permission: ''
			}
		]
	},

	mounted() {
		// Listen for window resize
		window.addEventListener('resize', this.setWindowWidth)
		this.setWindowWidth()
	},

	/** Ensure the event listener is destroyed */
	beforeDestroy: function() {
		window.removeEventListener('resize', this.setWindowWidth)
	},

	methods: {
		...mapActions([
			'logout',
			'checkDependencies'
		]),

		/** Check is a permission name is valid for the logged in user
         * @param {String} pagePermissionName   - Name of permission to check for
         * @returns {Boolean} True if the user has access to the permission, false if not
         */
		hasPermission(pagePermissionName) {
			if (pagePermissionName === '') return true
			return this.getValidPermissionForUser({ permissionName: pagePermissionName })
		},

		/** Get the width of the window and set it to this.windowWidth */
		setWindowWidth(event) {
			this.windowWidth = document.documentElement.clientWidth
		},

		/** Toggle the menu visible (open == true) or hidden (open == false).
		 * Emit the visible state to parent
		 * Set collapsed to false
         * @param {Boolean} open    - Toggle the menu visibility
         */
		toggleMenu(open) {
			if (open === this.showMenuToggle) return // we're already in the correct state
			this.showMenuToggle = open
			this.collapsed = false
			this.$emit('nav-show-menu', open)
		},

		/** When a menu selection is clicked on, this function will automatically close the nav menu
         * if the screen size is tablet/mobile */
		closeMenuIfMobile() {
			if (this.windowWidth <= 768) {
				this.toggleMenu(false)
			}
		},

		/** Load necessary dependencies either on created or when logged in */
		loadDependencies() {
			this.checkDependencies()
				.then(() => {
					this.loaded = true
				}).catch(error => {
					console.log('Error loading sidebar dependencies:', error)
				})
		}
	}
}
</script>
<style lang="scss" scoped>
.sidenav {
  position: relative;
	z-index: 100;
	background-color: #1b2a37;
    box-shadow: 2px 0 5px 3px rgba(0, 0, 0, 0.1);
    // border: solid 1px #dddddc;
	height: 100%;
	width: 250px;
    padding-top: 50px;
    @media (max-width: 768px) {
        position: fixed;
		z-index: 10;
		overflow-y: auto;
		padding-bottom: 75px;
	}
	&.collapsed {
		width: 100px;
	}
	@media (max-height: 600px) and (min-width: 769px) {
		overflow-y: auto;
		padding-bottom: 75px;
	}
	transition: width 0.2s;
	display: flex;
	flex-flow: column nowrap;
}
.nav-text {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.38;
    letter-spacing: 0.6px;
    color: #ffffff;
    text-transform: uppercase;
	padding-left: 40px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	height: 38px;
	margin-bottom: 3px;
	//cursor: pointer;
	&:hover {
		@extend .active;
	}
}
img {
	filter: invert(1);
	width: 15px;
}
a {
	margin-left: 10px;
	height: 100%;
    overflow: hidden;
}
.lang-text-options {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.38;
    letter-spacing: 0.6px;
    color: #ffffff;
    text-transform: uppercase;
    margin-left: 65px;
}
.selected-lang {
    font-weight: bold;
}
ul {
    list-style-type: disc;
}
.column-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}
.active {
	width: 102.5%;
    background-color: #4d667d;
    border-radius: 5px;
    z-index: 1;
	//cursor: default;
	&.blog {
		@media (max-width: 768px) {
			display: flex;
			height: fit-content;
			flex-direction: column;
		}
	}
	&.second {
		background-color: #6384a2;
	}
	span {
		// When the secondary nav in desktop is moused over, text wrapping
		// can get weird because of the extra width. Thus, limit the width of the text
		max-width: 105px;
	}
}
.mobile-content {
    @media (min-width: 426px) {
        display: none
    }
}
.tablet-content {
    @media (min-width: 769px) {
        display: none
    }
}
.expand-collapse {
	@media (max-width: 768px) {
		display: none
	}
}
.second-menu{
	background-color: #4d667d;
	padding-top: 50px;
	max-width: 175px;
	@media (max-width: 768px) {
		width: 0px;
		display: none;
	}

}
.mobile-second-menu{
	margin-top: 5px;
	width: 100%;
	@media (min-width: 769px) {
		display: none;
	}
}
.center-wrap {
	padding-right: 30px;
	height: auto;
	line-height: 1.8;
	padding-top: 7px;
	padding-bottom: 4px;
	&:hover {
		@extend .second;
	}
}
.text-container {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	@media (max-width: 768px) {
		align-self: flex-start;
	}
}
.grow-shrink-main{
    // Handles show/hide of sidebar
    &-enter,
    &-leave-to{
        opacity: 0;
        max-width: 0px;
    }
    &-enter-to,
    &-leave{
        opacity: 1;
        max-width: 250px;
    }
    &-enter-active,
    &-leave-active{
        transition: all 0.2s linear;
    }
}
.grow-shrink-second{
    // Handles show/hide of secondary nav in desktop
    &-enter,
    &-leave-to{
        max-width: 0px;
    }
    &-enter-to,
    &-leave{
        opacity: 1;
    }
    &-enter-active,
    &-leave-active{
        transition: all 0.2s linear;
    }
}
.list-grow-shrink {
    // Handles expanding of language selection list
    &-enter,
    &-leave-to{
        opacity: 0;
        max-height: 0px;
    }
    &-enter-to,
    &-leave{
        opacity: 1;
        max-height: 250px;
    }
    &-enter-active,
    &-leave-active{
        transition: all 0.2s linear;
    }
}
.list-grow-disappear {
    // Handles expanding of secondary menu for blog and help
    &-enter,
    &-leave-to{
        opacity: 0;
        max-height: 0px;
    }
    &-enter-to,
    &-leave{
        opacity: 1;
        max-height: 250px;
    }
    &-enter-active{
        transition: all 0.2s linear;
    }
	// leave-active intentionally left blank so item disappears immediately
}
</style>
