import axios from 'axios'
import Vue from 'vue'

/** Devices State
 * @typedef {Object} DeviceState
 * @property {null | Object<string, Device>} 	devices - All Devices for the user, device ID are keys. Null if not initialized.
 * @property {Set<String>} 						queried	- All Machine IDs that devices have been quired for
 */

/** @returns {DeviceState} */
export const getDefaultDeviceState = () => {
	return {
		devices: null,
		queried: new Set()
	}
}

export const storeState = getDefaultDeviceState()
/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {
/** Creates a Device via API
 * @param {import('vuex').ActionContext<DeviceState, any>} _
 * @param {Object} p
 * @param {string} 									p.machineId
 * @param {string} 									p.name
 * @param {string} 									p.make
 * @param {string} 									p.model
 * @param {number} 									p.machinePosition	- Position on machine
 * @param {Date}   									p.createdTime		- Time device is being added
 * @param {Object<string, DeviceCustomMetaData>} 	p.custom_meta_data 	- Meta data to be attached to device during creation
 * @param {string} 									p.customerId		- CustomerID making the device
 *
 * @returns {Promise} Axios promise with api response, including id of new device
 */
	createDevice({ dispatch }, { machineId, name, make, model, machinePosition, createdTime, custom_meta_data, customerId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			machine_id: machineId,
			name: name,
			make: make,
			model: model,
			machine_position: machinePosition,
			Created_at: createdTime,
			custom_meta_data: custom_meta_data,
			customer_id: customerId
		}
		return axios
			.post(
				'/api/v1/devices', body, {
					headers: jwt,
					timeout: 5000
				}
			).then(() => {
				return dispatch('fetchDevices', [customerId]) // regenerate devices for this specific machine
			})
			.catch((error) => {
				console.error('Error creating device', error)
			})
	},

	/** Fetch all devices for a customer group from remote API and load it into store
	 * @param {import('vuex').ActionContext<DeviceState, any>} _
	 * @param {String[]}   customerIDs  Array of customer IDs to get devices for
	 */
	fetchDevices({ commit }, customerIDs) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		/** Create a promise factory to get devices for each printer
		* @param {String} customerID ID of machine to get devices for
		* @returns {Promise} Axios promise object with results of getting device for machines
        */
		function getDeviceForCustomer(customerID) {
			return axios.get(`/api/v1/customers/${customerID}/devices`, {
				headers: jwt,
				timeout: 5000
			})
				.then((response) => {
					// Translate device's parameter names from the API keys to javascript friendly keys
					// These keys are documented in state declaration at the top of the page as well
					const translatedDevices = {}
					for (const device of response.data.devices) {
						translatedDevices[device.id] = {
							id: device.id,
							machineID: device.machine_id,
							machinePosition: device.machine_position,
							make: device.make,
							model: device.model,
							name: device.name,
							customMetaData: device.custom_meta_data,
							customerID: device.customer_id
						}
					}
					commit('_setDevices', translatedDevices)
				})
				.catch((error) => {
					console.error('Error generating device for customer', customerID, ': ', error)
					commit('_setDevices', {})
				}).finally(_ => {
					commit('_setQueried', customerID)
				})
		}
		const getDevicePromises = []
		for (const customerID of customerIDs) {
			getDevicePromises.push(getDeviceForCustomer(customerID))
		}
		return Promise.all(getDevicePromises)
	},

	/** Updates Existing Device
	 *
	 * @param {import('vuex').ActionContext<DeviceState, any>} _
	 * @param {Object} p
	 * @param {String} 								p.deviceId			- The id of the device being updated
	 * @param {Number} 								p.machinePosition	- The new machine position of the device
	 * @param {String} 								p.name				- The new device name
	 * @param {Object<string,DeviceCustomMetaData>} p.custom_meta_data	- Any custom data to be attached to the device
	 * @param {String} 								p.machineId			- The (new) machine ID that device belongs to, empty string if none selected
	 * @param {String} 								p.customerId		- The (new) customer ID that the device belongs to
	 *
	 * @returns {Promise} Axios promise object with updated device
	 */
	updateDevice({ dispatch }, { deviceId, machinePosition, name, custom_meta_data, machineId, customerId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			machine_position: machinePosition,
			name: name,
			custom_meta_data: custom_meta_data,
			machine_id: machineId,
			customer_id: customerId
		}
		return axios
			.put(
				'/api/v1/devices/' + deviceId, body, {
					headers: jwt,
					timeout: 5000
				}
			)
			.then(response => {
				return dispatch('fetchDevices', [customerId])
			})
			.catch((error) => {
				console.error('Error updating device', error)
			})
	},

	/** Deletes an existing device
	 * @param {import('vuex').ActionContext<DeviceState, any>} _
	 * @param {String} deviceId - device ID to delete
	 */
	deleteDevice({ commit }, deviceId) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.delete(`/api/v1/devices/${deviceId}`, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				commit('_removeDevice', deviceId)
			})
			.catch((error) => {
				console.error('Error deleting device: ', error)
			})
	}
}

/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
	/** Sets the state's devices, if device already exists it is overridden
	 * @param {Object<string,Device>} devices - Keys are device IDs, values are all associated values of that device
	 */
	_setDevices(state, devices) {
		if (state.devices === null) {
			Vue.set(state, 'devices', {})
		}
		for (const device of Object.values(devices)) {
			Vue.set(state.devices, device.id, device)
		}
	},
	/** remove a device
	 * @param {String} deviceID - ID of device to remove
	 */
	_removeDevice(state, deviceID) {
		if (state.devices === null) return
		Vue.delete(state.devices, deviceID)
	},
	/** Add a queried id to queried set
	 * @param {String} machineID	ID of machine queried for
	 */
	_setQueried(state, machineID) {
		state.queried.add(machineID)
	},
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultDeviceState())
	}
}

/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {
	/** Returns all devices in state
	 * @returns {Object<string, Device>} - UID of devices are keys
	 */
	getDevices(state) {
		return state.devices
	},
	/** Gets all parameters previously queried for
	 * @returns {Set<String>}
	*/
	getQueried(state) {
		return state.queried
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
