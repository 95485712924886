<template>
  <transition
    appear
    name="fade"
  >
    <div
      class="overlay"
      :style="{width: windowWidth+'px'}"
      @mousedown="e => $emit('mousedown', e)"
    >
      <slot />
    </div>
  </transition>
</template>
<script>
/** Overlay is a wrapper for components allowing them to overlay a parent component, hijacking that elements space */
export default {

	props: {
		/** Vue Ref of the element that this instance over the overlay should overtake
		 * Searches starting at this component, then works its way up the parent tree
		 */
		refName: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			windowWidth: 0
		}
	},

	computed: {
		/** The HTML element we're observing (matching) width for
		*/
		refObj() {
			// Travel up the parent tree looking for the passed in reference
			let parent = this
			while (parent && !(this.refName in parent.$refs)) {
				parent = parent.$parent
			}
			if (parent === undefined) {
				console.error(`Unable to find overlay reference target: ${this.refName}`)
			}
			return parent.$refs[this.refName]
		}
	},

	mounted() {
		this.heightObserver = new ResizeObserver(entries => {
			this.windowWidth = entries[0].borderBoxSize[0].inlineSize // we only care about our content width
		})
		// Bind the observer to the most parent element within this component
		this.heightObserver.observe(this.refObj)
	},

	beforeDestroy() {
		this.heightObserver.unobserve(this.refObj)
	}
}
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: calc(100vh - 65px);
  background-color: rgba($color: #000000, $alpha: 0.2);
  backdrop-filter: blur(2px); //support is iffy on ie and firefox
  z-index: 100;
}

// Fade elements in and out
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave{
  opacity: 1;
}
</style>
