/** Telemetry store handles all things telemetry
 *
 * It has three main interaction routes that components use:
 * generateHistoricalTelemetry() provides a way for components to request historical data from the API
 * SubscribeToDeviceStreaming() & stopStreamingForDevice are the primary methods to stop and start live
 * streaming websocket traffic.
 *
 * Under the hood the store does some book keeping and state management:
 *   - Anytime the visibility of the page is hidden, all websocket traffic is stopped.  It is resumed when the page becomes visible again
 *   - If no data is coming across the websocket eventually the connection will time out and close. If the store state indicates that a device
 *       is still subscribed, it will automatically reopen the connection and resubscribe.
 *       NOTE: This will always cause a `new` websocket message to appear,
 *       as when we connect we receive the most recent telemetry message every time.
 */

import axios from 'axios'
import Vue from 'vue'
import isEqual from 'lodash.isequal'
import { Buffer } from 'buffer'
import { faMapSigns } from '@fortawesome/free-solid-svg-icons'

/** Socket message contents
 * @typedef {Object} SocketMessage
 * @prop {{fail:string[],warn:string[],rules:string[]}} 				analytics 	- List of rules and list of telemetry keys that trigged a warn or fail message
 * @prop {{numeric:Object<string,number>,string:Object<string,string>}} telemetry 	- Numerical and string data under their context keys
 * @prop {number} 							 							ts 			- Timestamp of messages in MS unix
 */

/** Socket message contents
 * @typedef {Object} SocketMessage
 * @prop {{fail:string[],warn:string[],rules:string[]}} 				analytics 	- List of rules and list of telemetry keys that trigged a warn or fail message
 * @prop {{numeric:Object<string,number>,string:Object<string,string>}} telemetry 	- Numerical and string data under their context keys
 * @prop {number} 														ts 			- Timestamp of messages in MS unix
 */

const zlib = require('zlib')

/** Creates a socket object and connection for use in streaming telemetry
 * Parameters are all expected to be store functionalities
 */
function createSocketObject(getters, dispatch, commit, rootGetters) {
	const result = new Promise(function(resolve, reject) {
		const socket = new WebSocket(getters.getSocketURL)

		socket.onmessage = function(data) {
			// If the web page is hidden stop the websocket subscription
			if (document.visibilityState !== 'visible') {
				commit('_setPageHiddenStoppedConnection', true)
				socket.close()
				return
			}

			const newData = JSON.parse(data.data)

			switch (newData.type) {
			case 'telem': {
				// Multiple messages can come across at once for the same device, but only the most recent matters
				// Trimming here stops some reactivity from doubling up
				const updates = {}
				// Sort the telemetry then keep the most recent value for the field and data keys
				const sorted = newData.telem.sort((a, b) => a.ts > b.ts)
				for (const msg of sorted) {
					if (msg.uid in updates) {
						updates[msg.uid].ts = msg.ts
						updates[msg.uid].context = { ...updates[msg.uid].context, ...msg.context }
						updates[msg.uid].telemetry.numeric = { ...updates[msg.uid].telemetry.numeric, ...msg.telemetry.numeric }
						updates[msg.uid].telemetry.string = { ...updates[msg.uid].telemetry.string, ...msg.telemetry.string }
						updates[msg.uid].analytics.fail = updates[msg.uid].analytics.fail.concat(msg.analytics.fail)
						updates[msg.uid].analytics.rules = updates[msg.uid].analytics.rules.concat(msg.analytics.rules)
						updates[msg.uid].analytics.warn = updates[msg.uid].analytics.warn.concat(msg.analytics.warn)
					} else {
						updates[msg.uid] = msg
					}
				}
				// Crunch arrays of values back down
				for (const msg of Object.values(updates)) {
					msg.analytics.fail = Array.from(new Set(msg.analytics.fail))
					msg.analytics.rules = Array.from(new Set(msg.analytics.rules))
					msg.analytics.warn = Array.from(new Set(msg.analytics.warn))
				}
				for (const msg of Object.values(updates)) {
					// Add any custom user telemetry
					augmentTelemetry(msg.telemetry, msg.uid, rootGetters)
					// Set user value translations
					translateTelemetry(msg.telemetry, msg.uid, rootGetters)
					commit('_updateStreamingTelemetry', msg)
				}
				break
			}
			case 'auth':
				if (!newData.auth) {
					// If you are seeing this error, did you send a valid deviceID?
					console.warn('Unable to validate authentication for websocket request')
				}

				// Regardless of auth status any bad device ID's need to be removed from the count
				// If the authorization fails then even valid IDs will end up in the bad ID array
				// This way way don't include them on any reconnection attempts
				for (const invalidUID of newData.invalid) {
					console.warn('Unable to subscribe with deviceID:', invalidUID)
					commit('_decreaseActiveDeviceCount', invalidUID)
				}
				break
			}
		}

		socket.onclose = async function(event) {
			// If the websocket has been closed because of the page being hidden
			// This needs to wait until the page is visible again
			if (getters.getSocketSuspended) {
				while (document.visibilityState !== 'visible') {
					await new Promise(resolve => setTimeout(resolve, 1000))
				}
				commit('_setPageHiddenStoppedConnection', false)
			}

			// It is possible that the websocket connection will time out due to no messages
			// If a component is still using the device we resubscribe
			let reconnected = false
			for (const deviceID of getters.getActiveDevices) {
				reconnected = true
				dispatch('subscribeToDeviceStreaming', { deviceID: deviceID, reconnectRequest: true })
			}

			// No components are waiting so clean up the tracking
			if (!reconnected) {
				// Wipe tracking of subscribed devices
				commit('_wipeActiveDevices')
			}
		}

		socket.onopen = function() {
			resolve()
		}

		socket.onerror = function(err) {
			reject(err)
		}

		commit('_setSocket', socket)
		// Ensure that tests bind the object
		if (process?.env?.JEST_WORKER_ID !== undefined) {
			socket.onopen()
		}
	})
	currentlySubscribing = result
	return result
}

/** Augments a telemetry object with any needed modifications of custom telemetry
*
* @param {{numeric:Object<string,number>,string:Object<string,string>}} telemetry
* @param {string} deviceID
* @param {import('vuex').GetterTree} rootGetters - Access to other store to get telemetry info
*/
function augmentTelemetry(telemetry, deviceID, rootGetters) {
	const allCustomTelemetry = rootGetters['TelemetryMeta/getCustomTelemetry'](deviceID)
	// Because telemetry is split into numeric and string types, combine into a single dictionary for easy processing
	const combinedTelem = Object.assign(telemetry.numeric, telemetry.string)

	// Loop through all functions on the device
	for (const customTelem of Object.values(allCustomTelemetry)) {
		// Error check that expected telemetry exists for function
		if (!(customTelem.telemetryKeys.every(key => key in combinedTelem))) continue
		// Don't override already existing elements
		if (customTelem.telemetryName in telemetry.numeric) continue

		// Add modified telemetry
		telemetry.numeric[customTelem.telemetryName] = customTelem.function(combinedTelem)
	}
}

/** Users are able to have value translations set on telemetry, this modifies the telemetry as needed
* @param {{numeric:Object<string,number>,string:Object<string,string>}} telemetry - Telemetry to be used
* @param {String} deviceID - Device telemetry belongs to
* @param {import('vuex').GetterTree} rootGetters - Access to other store to get telemetry info
*/
export function translateTelemetry(telemetry, deviceID, rootGetters) {
	const translations = rootGetters['TelemetryMeta/getTelemetryTranslations'](deviceID)
	// It is expected that the amount of translated keys will be significantly smaller then actual telemetry coming in
	// So check all translation keys against message telemetry
	for (const k of Object.keys(translations)) {
		// Check for numeric telemetry
		let nValue = telemetry?.numeric?.[k]
		let tValue = translations[k]?.[nValue]
		if (nValue !== undefined && tValue !== undefined) {
			telemetry.numeric[k] = tValue
		}

		// Check for string telemetry
		nValue = telemetry?.string?.[k]
		tValue = translations[k]?.[nValue]
		if (nValue !== undefined && tValue !== undefined) {
			telemetry.string[k] = tValue
		}
	}
}

/** Telemetry store state
* @typedef {Object} TelemetryStore
* @prop {WebSocket|null} 					webSocket 					- Live connection for streaming data
* @prop {Object<string, SocketMessage>} 	streamingTelemetry 			- deviceID then telemetry key, points to most recent observation
* @prop {Object<string,number>} 			activeDevices 				- DeviceID points to a count of the number of active subscriptions for that device. When zero device is automatically unsubscribed
* @prop {boolean} 							pageHiddenStoppedConnection - Boolean indicating if connection was cut to save resources, due to page not being focus. False indicates normal connection
* @prop {string} 							SOCKETURL 					- URL target for websocket connection
*/

/** @returns {TelemetryStore} */
export const getDefaultTelemetryState = () => {
	return {
		webSocket: null,
		streamingTelemetry: {},
		activeDevices: {},
		pageHiddenStoppedConnection: false,
		SOCKETURL: 'wss://' + document.domain + ':' + '/streaming'
	}
}

export const storeState = getDefaultTelemetryState()

// Used to globally lock subscriptions for only generating one socket connection at a time
let currentlySubscribing = null

/** @type {import("vuex").ActionTree<storeState>} */
export const storeActions = {
/** Will fetch historical data from the API
* Returns a promise that will resolve into the data (or error)
*
* @param _
* @param {Object} p
* @param {Number} 			p.startTime	- Unix MS timestamp of the beginning of the wanted time window
* @param {Number} 			p.endTime 	- Unix MS timestamp of the end of the wanted time window
* @param {String} 			p.deviceID 	- Device ID of the wanted device
* @param {Number|boolean} 	p.aggTime 	- Optional requested aggregation window
* @param {Number} 			p.timeout 	- Optional timeout before request automatically fails
*/
	async generateHistoricalTelemetry({ dispatch, rootGetters }, { startTime, endTime, deviceID, aggTime = false, timeout = 10000 }) {
		const resources = []
		// Ensure that custom telemetry for the device has been loaded
		// If it is a reconnect telemetry has already been checked
		resources.push(dispatch('TelemetryMeta/fetchCustomTelemetry', { deviceID }, { root: true }))
		await Promise.all(resources)

		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		let endpoint = `/api/v1/devices/${deviceID}/telemetry/${startTime.toString()}/${endTime.toString()}`
		if (aggTime) {
			// @ts-ignore
			endpoint = endpoint + `/agg/${Math.round(aggTime)}` // Will fail if aggTime has a decimal
		}

		return axios
			.get(endpoint, {
				headers: jwt,
				timeout: timeout
			})
			.then((response) => {
				// Get the data of the requested device
				// eslint-disable-next-line security/detect-object-injection
				const singleDevData = response.data.data[deviceID]
				// historic requests arrive as a compressed list of messages, so decompress first
				// zlip uses a callback function for unzipping, which will work asynchronous to the promise.
				// so return a promise containing the just the unzip and update logic.
				const buffer = Buffer.from(singleDevData.data, 'base64')
				return new Promise((resolve, reject) => {
					zlib.unzip(buffer, (err, buffer) => {
						if (!err) {
							const newDataSet = JSON.parse(buffer.toString())
							// Return the unpacked data
							// Augment data with custom values

							// If the device ID does not exist no point in checking all messages
							if (!isEqual(rootGetters['TelemetryMeta/getCustomTelemetry'](deviceID), {})) {
								for (const row of newDataSet) {
									const oldTelem = row.telemetry
									const newTelem = {}
									for (const customTelem of Object.values(rootGetters['TelemetryMeta/getCustomTelemetry'](deviceID))) {
										// If the custom telemetry already exists don't override it
										if (customTelem.telemetryName in row.telemetry) continue
										// Holds values used by the function for calculations
										const valuesToCheck = {
											max: {},
											min: {},
											sum: {},
											sumsq: {}
										}
										const augmentedValues = {
											max: {},
											min: {},
											sum: {},
											sumsq: {}
										}
										// All elements here are assumed to be sums of some form and must be treated differently
										const aggregatedValues = new Set(['sum', 'sumsq'])

										// Populate the values to check with all the needed telemetry
										// Ensure all telemetry the equation depends on exist
										if (!customTelem.telemetryKeys.every(e => e in oldTelem)) continue

										for (const [aggType, val] of Object.entries(valuesToCheck)) {
											// Gather the needed values for the computation
											let count = 1
											for (const telemKey of customTelem.telemetryKeys) {
												count = oldTelem[telemKey].count
												// Summed elements have to be broken down so the equation is applied correctly
												// We bust the number down to its average for a single observation
												if (aggregatedValues.has(aggType)) {
													val[telemKey] = oldTelem[telemKey][aggType] / count
												} else {
													val[telemKey] = oldTelem[telemKey][aggType]
												}
											}

											// Counts are assumed to always be the same right now
											augmentedValues.count = count
											// Populate new value by its calculation
											if (aggregatedValues.has(aggType)) {
												// The result of the function evaluation should be an approximation of the average
												// Multiply it back up to be the sum
												augmentedValues[aggType] = customTelem.function(val) * count
											} else {
												augmentedValues[aggType] = customTelem.function(val)
											}
										}

										newTelem[customTelem.telemetryName] = augmentedValues
									}

									// Add in telemetry values
									Object.assign(oldTelem, newTelem)
								}
							}
							resolve(
								{
									newDataSet: newDataSet,
									deviceID: deviceID,
									startTS: singleDevData.length === 0 ? startTime : singleDevData.start,
									endTS: singleDevData.length === 0 ? endTime : singleDevData.end,
									length: singleDevData.length,
									totalMsgs: singleDevData.total_msgs,
									aggTime: singleDevData.agg_time_ms,
									reqStart: startTime,
									reqEnd: endTime
								}
							)
						} else {
							console.error('Decompressing problems')
							reject()
						}
					})
				})
			})
			.catch((error) => {
				console.error('Error fetching data:', error)
			})
	},
	/** Will subscribe to websocket as needed and always returns a promise
* that will resolve into a reference to a store object that will be continuously updated with telemetry
*
* @param _
* @param {Object} p
* @param {Number} 	p.deviceID			- Device to connect to
* @param {Boolean} p.reconnectRequest	- True if device was previously connected
*
* @returns {Promise} That when resolved indicates the subscription is done
*/
	async subscribeToDeviceStreaming({ state, getters, dispatch, commit, rootGetters }, { deviceID, reconnectRequest }) {
		if (!reconnectRequest) {
			const resources = []
			// Ensure that custom telemetry for the device has been loaded
			// If it is a reconnect telemetry has already been checked
			resources.push(dispatch('TelemetryMeta/fetchCustomTelemetry', { deviceID }, { root: true }))
			// Telemetry meta info also is needed
			resources.push(dispatch('TelemetryMeta/fetchMetaTelemetry', [deviceID], { root: true }))
			await Promise.all(resources)
		}
		// Internally within the store for reconnection logic at times we don't want to increment device counter
		// External callers of this function should not include reconnectRequest ever
		if (reconnectRequest === undefined) reconnectRequest = false
		// If the websocket does not exist, or is not connected or connecting a new one has to be created
		if (state.webSocket === null || (state.webSocket.readyState !== WebSocket.OPEN && state.webSocket.readyState !== WebSocket.CONNECTING)) {
			return createSocketObject(getters, dispatch, commit, rootGetters).then(() => {
				dispatch('startDeviceWebsocketStream', { deviceID: deviceID, reconnectRequest: reconnectRequest })
			})
		}

		// If a socket is currently being created wait here for it to finish
		await currentlySubscribing

		// Once the socket is open we can subscribe to the device
		return new Promise(function() {
			dispatch('startDeviceWebsocketStream', { deviceID: deviceID, reconnectRequest: reconnectRequest })
		})
	},

	/** Will de-increment the subscription counter of the given device, and stop the telemetry streaming
* as needed
*
* @param _
* @param {Object} p
* @param {string} p.deviceID
*/
	stopStreamingForDevice({ state, commit }, { deviceID }) {
		const previousCount = state.activeDevices[deviceID]
		commit('_decreaseActiveDeviceCount', deviceID)
		// If no one is registered as using the data stream turn it off
		if (previousCount === 1) {
			const msg = {
				start: false,
				uids: [deviceID],
				auth: localStorage.getItem('authToken')
			}
			state.webSocket.send(JSON.stringify(msg))
		} else if (state.activeDevices[deviceID] < 0) {
			console.error('Device count has become out of sync')
		}
	},

	/** Starts a websocket stream up for the device
* @param _
* @param {Object} p
* @param {Number} 	p.deviceID			- Device to connect to
* @param {Boolean} p.reconnectRequest	- True if device was previously connected
*/
	startDeviceWebsocketStream({ state, commit }, { deviceID, reconnectRequest }) {
		// If it is part of a reconnection we don't want to change the counter
		if (!reconnectRequest) {
			commit('_increaseActiveDeviceCount', deviceID)
			// If the device count is not 1 then it should have been subscribed to already
			if (state.activeDevices[deviceID] !== 1) {
				return
			}
		}
		const msg = {
			start: true,
			uids: [deviceID],
			auth: localStorage.getItem('authToken')
		}
		state.webSocket.send(JSON.stringify(msg))
	}

}

/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
/**
* @param {String} deviceID
*/
	_increaseActiveDeviceCount(state, deviceID) {
		if (!(deviceID in state.activeDevices)) {
			Vue.set(state.activeDevices, deviceID, 1)
		} else {
			Vue.set(state.activeDevices, deviceID, state.activeDevices[deviceID] + 1)
		}
	},
	/**
* @param {String} deviceID
*/
	_decreaseActiveDeviceCount(state, deviceID) {
		if (state.activeDevices[deviceID] > 0) {
			Vue.set(state.activeDevices, deviceID, state.activeDevices[deviceID] - 1)
		}
	},
	/**
* @param {{uid:string} & SocketMessage} msg
*/
	_updateStreamingTelemetry(state, msg) {
		const newTelem = {
			analytics: msg.analytics,
			ts: msg.ts,
			// Telemetry is split into numeric and string types, combine them here to make processing easier
			telemetry: Object.assign(msg.telemetry.numeric, msg.telemetry.string)
		}
		// By replacing the entire object we can easily trigger reactivity on each UID without needing to use a deep watch
		Vue.set(state.streamingTelemetry, msg.uid, newTelem)
	},
	/** @param {WebSocket} socket */
	_setSocket(state, socket) {
		Vue.set(state, 'webSocket', socket)
	},
	_wipeActiveDevices(state) {
		Vue.set(state, 'activeDevices', {})
	},
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultTelemetryState())
	},
	/** Simply sets the pageHiddenStoppedConnection variable with the passed in bool
*
* @param {Boolean} bool - New state to be set
*/
	_setPageHiddenStoppedConnection(state, bool) {
		Vue.set(state, 'pageHiddenStoppedConnection', bool)
	}
}

/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {
/** Returns all telemetry objects in the store
* See state documentation for format
*
* @returns {Object<string, SocketMessage>}
*/
	getAllStreamingTelemetry(state) {
		return state.streamingTelemetry
	},

	getStreamingTelemetryForDevice: (state) =>
	/**
* @param {String} deviceID
*
* @returns {SocketMessage | {}}
*/
		(deviceID) => {
			if (deviceID in state.streamingTelemetry) {
				return state.streamingTelemetry[deviceID]
			}

			return {}
		},
	getStreamingAnalyticsForDevice: (state) =>
	/**
* @param {string} deviceID
*
* @returns {SocketMessage["analytics"]}
*/(deviceID) => {
			if (deviceID in state.streamingTelemetry) {
				return state.streamingTelemetry[deviceID].analytics
			}

			return { warn: [], rules: [], fail: [] }
		},
	/** Gets the URL used by the websocket for its connection
*
* @returns {String} Socket URL
*/
	getSocketURL(state) {
		return state.SOCKETURL
	},
	/** Gets the ID of all devices that currently have 1 or more subscription request
*
* @returns {String[]} - Array of device IDs
*/
	getActiveDevices(state) {
		const devices = []
		for (const deviceID in state.activeDevices) {
			if (state.activeDevices[deviceID] !== 0) {
				devices.push(deviceID)
			}
		}
		return devices
	},
	/** Boolean indicating if the store has currently suspended the websocket
* Returns true if the store has suspended the websocket connection due to visibility of the tab
* When this variable goes from true -> false. The normal flow of the websocket is resumed
*
* @returns {Boolean}
*/
	getSocketSuspended(state) {
		return state.pageHiddenStoppedConnection
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
