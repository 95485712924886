/** Extract data from json token
 * @param {JWT} token JWT Token
 * @returns {Object} Parsed JWT payload
 */
function _parseJwt(token) {
	try {
		const base64Url = token.split('.')[1]
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
		var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
		}).join(''))
	} catch (err) {
		console.error('Error parsing JWT', err)
		return {
			UserId: null,
			Role: null,
			CustomerID: null
		}
	}

	return JSON.parse(jsonPayload)
}

export function getActiveUserID() {
	return _parseJwt(localStorage.getItem('authToken')).UserId
}

export function getActiveUserRole() {
	return _parseJwt(localStorage.getItem('authToken')).Role
}

export function getActiveUserCustomerID() {
	return _parseJwt(localStorage.getItem('authToken')).CustomerID
}
