import axios from 'axios'
import Vue from 'vue'

/** Holds context info for devices
 * @typedef {Object} ContextStore
 * @prop {Null|Object<string,Context>} context
 */

/** @returns {ContextStore} */
export const getDefaultContextState = () => {
	return {
		context: null
	}
}

export const storeState = getDefaultContextState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {

	/** Pulls the latest context for a given device ID and persists it to the store
	 *
	 * @param {import('vuex').ActionContext<ContextStore>} _
	 * @param {String} deviceID	- device ID to pull context for
	 *
	 * @returns {Promise} 		- axios promise for the api request
	 */
	fetchLatestContext({ commit }, deviceID) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}

		return axios
			.get(`/context/v1/devices/${deviceID}/latest`, {
				headers: jwt,
				timeout: 5000
			})
			.then((response) => {
				const deviceContext = [] // array to add latest context entities to, one per context key
				for (const context of Object.values(response.data.context)) {
					/** @type {Context} */
					const contextEntity = {
						uid: context.uid,
						start_ts: context.start_ts,
						end_ts: context.end_ts,
						key: context.key,
						value: context.value
					}
					deviceContext.push(contextEntity)
				}
				commit('_setLatestContext', { deviceID, deviceContext })
			})
			.catch(error => {
				console.error('Problem getting device context', error)
			})
	},

	/** Gets a historical record of context for a device in a given time window
	 * Returns the parsed API response via promise, does not persist to store
	 * Start and End parameters must be unix ms Numbers
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object} p
	 * @param {String} p.deviceID 	- Device ID to fetch
	 * @param {Number} p.start		- Unix starting time for the wanted time window
	 * @param {Number} p.end 		- Unix ending time for the wanted time window
	 *
	 * @returns {Promise<Context[]>} - axios promise from api call, parsed into a list of context entities
	 */
	generateHistoricalContext(_, { deviceID, start, end }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}

		return axios
			.get(`/context/v1/devices/${deviceID}/historical/${start}/${end}`, {
				headers: jwt,
				timeout: 5000
			})
			.then((response) => {
				const deviceContext = []
				for (const contextList of Object.values(response.data.context)) {
					for (const context of contextList) {
						/** @type {Context} */
						const contextEntity = {
							uid: context.uid,
							start_ts: context.start_ts,
							end_ts: context.end_ts,
							key: context.key,
							value: context.value
						}
						deviceContext.push(contextEntity)
					}
				}
				return (deviceContext)
			})
			.catch(error => {
				console.warn('Problem getting device context', error)
				return []
			})
	}
}

/** @type {import("vuex").MutationTree<ContextStore>} */
export const storeMutations = {

	/** Add a list of the latest context entities for a device to store
	 *
	 * @param {ContextStore} state
	 * @param {Object} p
	 * @param {String} p.deviceID			- Device being updated
	 * @param {Context[]} p.deviceContext 	- list of context entities to update with
	 */
	_setLatestContext(state, { deviceID, deviceContext }) {
		if (state.context === null) {
			// initialize null state
			Vue.set(state, 'context', {})
		}
		if (!deviceID) return // make sure device ID is good
		if (!(deviceID in state.context)) {
			// Initialize device ID sub object if it doesn't already exist
			Vue.set(state.context, deviceID, {})
		}
		for (const context of deviceContext) {
			// For each context entity, add it to the device's context object
			Vue.set(state.context[deviceID], context.key, context)
		}
	},

	/** Resets the entire state to default values */
	resetState(state) {
		Object.assign(state, getDefaultContextState())
	}
}

export const storeGetters = {

	/** Returns the entire object of latest context
	 *
	 * @return {Null|Object<string,Context>} device IDs as keys, and values as objects that map context keys to context entities
	 */
	getLatestContext(state) {
		return state.context
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
