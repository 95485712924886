
import Vue from 'vue'
import ampButton from '@/components/templates/button.vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'

import ampSelect from '@/components/templates/select.vue'
import ampCheckbox from '@/components/templates/checkbox.vue'
// Remote api calls
import axios from 'axios'
import VueAxios from 'vue-axios'
// Markdown parsing
import VueShowdown from 'vue-showdown'
// Google Analytics
import VueGtag from 'vue-gtag'
// Language translations
import VueI18n from 'vue-i18n'
// Router Hub
import routerHub from '@/mixins/routerHub.js'

import ja from './lang/ja.json'
import it from './lang/it.json'
import fr from './lang/fr.json'

Vue.use(VueShowdown, {
	flavor: 'github',
	options: {
		emoji: true,
		tables: true
	}
})
// Adding router to handle page change events
// Page containing information on the template and creating a custom template
// https://matteo-gabriele.gitbook.io/vue-gtag/auto-tracking

Vue.use(VueGtag, {
	config: {
		id: 'UA-167903076-2'
	}
}, router)
Vue.use(routerHub)
Vue.use(VueAxios, axios)
Vue.use(VueI18n)
Vue.config.productionTip = true
//if (process.env.NODE_ENV === 'production') Vue.config.devtools = false
//else 
Vue.config.devtools = true

Vue.component('AmpButton', ampButton)
Vue.component('AmpSelect', ampSelect)
Vue.component('AmpCheckbox', ampCheckbox)

const locale = 'en'
const i18n = new VueI18n({
	locale,
	messages: {
		en: {}, // See readme for explanation
		ja: ja,
		it: it,
		fr: fr
	},
	silentTranslationWarn: true
})

// Base Vue instance, everything else builds onto this
new Vue({
	i18n,
	router,
	store: store,
	beforeCreate: function() {
		// Trim out translationString prior to translations
		const trans = Vue.prototype.$t.bind(this)
		Vue.prototype.$t = (phrase) => {
			if (typeof phrase === 'string' && phrase.startsWith('translationString')) {
				return trans(phrase.slice(18, phrase.length))
			}
			return trans(phrase)
		}
	},

	render: h => h(App)
}).$mount('#app')
