import axios from 'axios'
import Vue from 'vue'
import { getActiveUserCustomerID } from '@/helpers/jwt.js'

export const getDefaultCustomerState = () => {
	return {
		/** Object to hold user objects
         *      Customer ID are keys
		 * @type {Object<string, Customer>}
        */
		customers: null
	}
}

export const storeState = getDefaultCustomerState()

/** @type {import("vuex").ActionTree<typeof storeState>} */
export const storeActions = {

	/** Creates a new customer entity
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object} p
	 * @param {String} p.name			- Customer group's name
	 * @param {String} p.description	- Description of the customer group
	 * @param {String} p.parentID		- ID of the parent group for new customer group
	 *
	 * @returns {Promise}
	 */
	createCustomer({ dispatch }, { name, description, parentID }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		const body = {
			parent_id: parentID,
			name: name,
			description: description
		}
		return axios
			.post('/api/v1/customers', body, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				return dispatch('fetchCustomers')
			})
			.catch((error) => {
				console.error('Error creating location', error)
			})
	},

	/** Fetch all customers user has access to
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 *
	 * @returns {Promise} Axios promise with status of generating customers
	 */
	fetchCustomers({ rootGetters, commit }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		/** Get active user's customer's nested customer entities via recursive Promise.all
		 * If axios.get 403s, user does not have access to customer group so quietly move on
		 * @param {String} parentID		Customer ID to query child customers for
		 */
		function getChildCustomers(parentID) {
			return axios
				.get(`/api/v1/customers/${parentID}/customers`, {
					headers: jwt,
					timeout: 5000
				})
				.then((response) => {
					commit('_setCustomers', (response.data.customers.length > 0) ? response.data.customers : {})
					if (response.data.customers.length > 0) {
						const getCustomerPromises = []
						for (const customer of response.data.customers) {
							getCustomerPromises.push(getChildCustomers(customer.id))
						}
						return Promise.all(getCustomerPromises)
					}
				})
				.catch((error) => {
					if (error?.response?.status !== 403) {
						commit('_setCustomers', {})
						console.error('Error reading nested customers:', error)
					} else {
						// A 403 is expected and means user doesn't have access to customer group.
						return Promise.resolve()
					}
				})
		}
		/** Get active user's customer info
		 * @param {String} customerID 	Active user's customer id
		 */
		function getCustomers(customerID) {
			return axios
				.get(`/api/v1/customers/${customerID}`, {
					headers: jwt,
					timeout: 5000
				})
				.then((response) => {
					const customer = {}
					customer[response.data.customer.id] = response.data.customer
					commit('_setCustomers', customer)
					if (rootGetters['Permissions/getValidPermissionForUser']({ permissionName: 'Nested Customers Read' })) {
						// Does user have permission to read nested customers?
						return getChildCustomers(response.data.customer.id)
					}
				})
				.catch((error) => {
					console.error("error generating user's customer: ", error)
					commit('_setCustomers', {})
				})
		}
		return getCustomers(getActiveUserCustomerID())
	},

	/** Updates Existing Customer Group
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {Object} p
	 * @param {String} p.customerId		- ID of the customer group being edited
	 * @param {String} p.name			- (New) Name of the customer group
	 * @param {String} p.description	- (New) description of the customer group
	 * @param {String} p.parentId		- (New) parent customer group UUID
	 *
	 * @returns {Promise} Axios promise object with response from updating customer group
	 */
	updateCustomer({ dispatch }, { customerId, name, description, parentId }) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		// Whatever latitude and longitude are set to will be what is returned if the address is incorrect or not found
		const body = {
			name: name,
			description: description,
			parent_id: parentId
		}
		return axios
			.put('/api/v1/customers/' + customerId, body, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				return dispatch('fetchCustomers')
			})
			.catch((error) => {
				console.error('Error updating customer', error)
			})
	},

	/** Deletes an existing customer group
	 *
	 * @param {import('vuex').ActionContext<typeof storeState, any>} _
	 * @param {String} customerId	- id of customer to delete
	 */
	deleteCustomer({ commit }, customerId) {
		const jwt = {
			Authorization: localStorage.getItem('authToken')
		}
		return axios
			.delete(`/api/v1/customers/${customerId}`, {
				headers: jwt,
				timeout: 5000
			}
			)
			.then(() => {
				commit('_removeCustomer', customerId)
			})
			.catch((error) => {
				console.error('Error deleting customer', error)
			})
	}

}
/** @type {import("vuex").MutationTree<typeof storeState>} */
export const storeMutations = {
	/** Sets the state's customers
	 * @param {object} customers - Keys are user IDs, values are user objects
	 */
	_setCustomers(state, customers) {
		if (state.customers === null) {
			Vue.set(state, 'customers', {})
		}
		for (const c of Object.values(customers)) {
			Vue.set(state.customers, c.id, c)
		}
	},
	/** Remove a customer
	 * @param {String} customerID	- customer ID to remove from the store
	 */
	_removeCustomer(state, customerID) {
		if (state.customers === null) return
		Vue.delete(state.customers, customerID)
	},
	/** Resets the entire store to default values */
	resetState(state) {
		Object.assign(state, getDefaultCustomerState())
	}
}
/** @type {import("vuex").GetterTree<typeof storeState>} */
export const storeGetters = {
	/** Gets all customers available to user
	 * @return {Object<string,Customer>} Keys are user ids, values are user objects
	 */
	getCustomers(state) {
		return state.customers
	}
}

export default {
	namespaced: true,
	state: storeState,
	getters: storeGetters,
	actions: storeActions,
	mutations: storeMutations
}
